<template>
  <section class="interface">
    <section class="title">
      <h1><i class="icon-deconnexion"></i>LOGOUT</h1>
    </section>
  </section>
</template>

<script>

export default {
  name: 'Logout',
  data () {
    return {
    }
  },
  created: function () {
    this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/login')
      })
  }
}
</script>
