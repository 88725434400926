import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: {
      code: 0,
      logs: [],
      message: ''
    },
    user: { meta: {} },
    cookies: localStorage.getItem('cookies') || false,
    auth: JSON.parse(localStorage.getItem('auth')) || { key: '', token: '', exp: 0 },
    db: {
      programs: [],
      lots: [],
      requests: [],
      reservations: [],
      prospects: [],
      users: [],
      config: []
    }
  },
  mutations,
  actions,
  getters,
  modules: {}
})
