<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-utilisateur"></i>ÉDITION PROSPECT</h1>
    </section>
    <form @submit.prevent="edit()">
    <section class="grid add">
      <section class="card new">
        <h2>Dénomination</h2>
        <section>
          <p>
            <label for="num">Numéro</label>
            <input type="text" size="4" id="num" v-model="prospect.numero" disabled />
          </p>
        </section>
        <section>
          <p>
            <label for="access">Liste</label>
            <select id="access" v-model="prospect.list">
              <option v-for="(list, l) in settings.prospect.list" :value="l" :key="l">{{ list }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section v-if="prospect.list !== 2 && prospect.list !== 4">
          <p>
            <label for="firstname">Prénom</label>
            <input type="text" id="firstname" v-model="prospect.firstname" />
          </p>
        </section>
        <section>
          <p>
            <label for="lastname">Nom</label>
            <input type="text" id="lastname" v-model="prospect.lastname" />
          </p>
        </section>
        <section v-if="prospect.list === 2">
          <p>
            <label for="firstname">Gérant</label>
            <input type="text" id="firstname" v-model="prospect.firstname" />
          </p>
        </section>
        <section>
          <p>
            <label for="mail">Email</label>
            <input type="text" id="mail" v-model="prospect.email" />
          </p>
        </section>
        <section>
          <p>
            <label for="created">Date de création</label>
            <input type="text" id="created" disabled :value="prospect.created | shortDate" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Coordonnées</h2>
        <section>
          <p>
            <label for="adress">Adresse</label>
            <input type="text" id="adress" v-model="prospect.contact.address" />
          </p>
        </section>
        <section>
          <p>
            <label for="city">Ville</label>
            <input type="text" id="city" v-model="prospect.contact.city" />
          </p>
        </section>
        <section>
          <p>
            <label for="region">Région</label>
            <input type="text" id="region" v-model="prospect.contact.region" />
          </p>
        </section>
        <section>
          <p>
            <label for="country">Pays</label>
            <input type="text" id="country" v-model="prospect.contact.country" />
          </p>
        </section>
        <section>
          <p>
            <label for="zip">Code postal</label>
            <input type="text" id="zip" v-model="prospect.contact.zipcode" />
          </p>
        </section>
        <section>
          <p>
            <label for="phone">Téléphone</label>
            <input type="text" id="phone" v-model="prospect.contact.phone" />
          </p>
        </section>
        <section>
          <p>
            <label for="mobile">Mobile</label>
            <input type="text" id="mobile" v-model="prospect.contact.mobile" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Privées</h2>
        <section>
          <p>
            <label for="birthday">Date de naissance</label>
            <v-date-picker id="birthday" v-model="prospect.personal.birthday"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
        <section>
          <p>
            <label for="gender">Genre</label>
            <select id="gender" v-model="prospect.personal.gender">
              <option value="0">Aucun</option>
              <option value="1">Homme</option>
              <option value="2">Femme</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="job">Profession</label>
            <input type="text" id="job" v-model="prospect.personal.job" />
          </p>
        </section>
        <section>
          <p>
            <label for="situation">Situation</label>
            <select id="situation" v-model="prospect.personal.situation">
              <option value="0">Aucune</option>
              <option value="1">Célibataire</option>
              <option value="2">En couple</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="child">Nbr d'enfants</label>
            <input type="number" id="child" v-model="prospect.personal.children" />
          </p>
        </section>
        <section>
          <p>
            <label for="income">Revenus</label>
            <select id="situation" v-model="prospect.personal.income">
              <option value="0">Aucun</option>
              <option value="1">€</option>
              <option value="2">€€</option>
              <option value="3">€€€</option>
              <option value="3">€€€€</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Programme</h2>
        <section>
          <p>
            <label for="num">Programme)</label>
            <select id="program" v-model="program.numero" @click="setProgram()">
              <option value="">Aucun</option>
              <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.numero }} {{ prog.shortname }}</option>
            </select><i class="icon-select"></i>
          </p>
        </section>
        <hr/>
        <h2>Lot</h2>
        <section>
          <p>
            <label for="type">Type</label>
            <select id="access" v-model="prospect.program.type">
              <option v-for="(lot, lt) in settings.lot.type" :value="lt" :key="lt">{{ lot }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="budget">Budget €</label>
            <input type="number" id="budget" v-model="prospect.program.budget" />
          </p>
        </section>
      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ prospect }}</pre>
      </section>
    </section>
    <section class="card">
      <p>
        <button type="submit">EDITER</button>
      </p>
    </section>
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import settings from '@/settings.js'

export default {
  name: 'ProspectEdit',
  components: {
    Profile
  },
  data () {
    return {
      settings,
      program: {
        numero: '',
        data: {}
      },
      prospect: {}
    }
  },
  computed: {
    programs: function () {
      return this.$store.getters.get_programs()
    },
    prosp: function () {
      return this.$store.getters.get_prospect_by_numero(this.$route.params.id)
    }
  },
  created () {
    this.prospect = this.prosp
    this.prospect.contact.phone = this.$options.filters.phone(this.prospect.contact.phone)
    this.prospect.contact.mobile = this.$options.filters.phone(this.prospect.contact.mobile)
    this.program.numero = this.prospect.program.numero
    this.setProgram()
  },
  methods: {
    setProgram: function () {
      if (Number(this.program.numero)) {
        this.program.data = this.programs.filter(p => p.numero === this.program.numero).shift()
        this.prospect.program.id = this.program.data.id
        this.prospect.program.numero = this.program.data.numero
        this.prospect.program.shortname = this.program.data.shortname
      } else {
        this.prospect.program.id = ''
        this.prospect.program.numero = ''
        this.prospect.program.shortname = ''
        this.program = {
          numero: '',
          data: {}
        }
      }
    },
    edit: function () {
      // phone formated to string (ex:#0298571020)
      this.prospect.contact.phone = '#' + this.prospect.contact.phone.split('.').join('').split(' ').join('').split('#').join('')
      this.prospect.contact.mobile = '#' + this.prospect.contact.mobile.split('.').join('').split(' ').join('').split('#').join('')
      // prepare payload
      const prospect = this.prospect
      this.$store.dispatch('editProspect', prospect)
        .then(() => {
          this.$router.push('/prospects')
        })
        .catch()
    }
  }
}
</script>
