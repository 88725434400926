<template>
  <section class="donut" >
    <svg height="100" width="100" viewBox="0 0 42 42" v-if="lots.length">
    <circle r="15.91549430918952" cx="21" cy="21" stroke="#eee"></circle>
    <text class="svgtotal" fill="var(--grey-dark)" x="50%" y="50%">{{ lots.length }}</text>
    <g>
        <circle stroke="var(--grey)" r="15.91549430918952" cx="21" cy="21" :stroke-dasharray="dasharray(0)" stroke-dashoffset="25" ></circle>
        <text fill="var(--grey)" x="50%" y="50%">{{ quantity[0] }}</text>
    </g>
    <g>
        <circle stroke="var(--yellow)" r="15.91549430918952" cx="21" cy="21" :stroke-dasharray="dasharray(1)" :stroke-dashoffset="dashoffset(1)"></circle>
        <text fill="var(--yellow)" x="50%" y="50%">{{ quantity[1] }}</text>
    </g>
    <g>
        <circle stroke="var(--reserved)" r="15.91549430918952" cx="21" cy="21" :stroke-dasharray="dasharray(2)" :stroke-dashoffset="dashoffset(2)"></circle>
        <text fill="var(--reserved)" x="50%" y="50%">{{ quantity[2] }}</text>
    </g>
    <g>
        <circle stroke="var(--financed)" r="15.91549430918952" cx="21" cy="21" :stroke-dasharray="dasharray(3)" :stroke-dashoffset="dashoffset(3)"></circle>
        <text fill="var(--financed)" x="50%" y="50%">{{ quantity[3] }}</text>
    </g>
    <g>
        <circle stroke="var(--acted)" r="15.91549430918952" cx="21" cy="21" :stroke-dasharray="dasharray(4)" :stroke-dashoffset="dashoffset(4)"></circle>
        <text fill="var(--acted)" x="50%" y="50%">{{ quantity[4] }}</text>
    </g>
    <circle r="16" cx="21" cy="21"></circle>
    </svg>
    <svg height="100" width="100" viewBox="0 0 42 42" v-else></svg>
    <ul>
      <li>{{ quantity[0] }} <i style="color:var(--grey)">⬤</i> Disponible</li>
      <li>{{ quantity[1] }} <i style="color:var(--yellow)">⬤</i> Optionné</li>
      <li>{{ quantity[2] }} <i style="color:var(--reserved)">⬤</i> Réservé</li>
      <li>{{ quantity[3] }} <i style="color:var(--financed)">⬤</i> Financé</li>
      <li>{{ quantity[4] }} <i style="color:var(--acted)">⬤</i> Acté</li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'ProgrammesDonut',
  props: {
    numero: Number
  },
  data () {
    return {
    }
  },
  computed: {
    lots: function () {
      return this.$store.getters.get_lots_by_program_num(this.numero)
    },
    quantity: function () {
      var quantity = []
      for (let i = 0; i < 5; i++) { quantity.push(this.lots.filter(l => Number(l.status) === i).length) }
      return quantity
    },
    percent: function () {
      var percent = []
      this.quantity.forEach(q => { percent.push(Number(((q / this.lots.length) * 100).toFixed(2))) })
      return percent
    }
  },
  methods: {
    dasharray: function (val) {
      var second = 100 - this.percent[val]
      return '' + this.percent[val] + ' ' + second
    },
    dashoffset: function (val) {
      if (val === 1) return 125 - (this.percent[0])
      else if (val === 2) return 125 - (this.percent[0] + this.percent[1])
      else if (val === 3) return 125 - (this.percent[0] + this.percent[1] + this.percent[2])
      else if (val === 4) return 125 - (this.percent[0] + this.percent[1] + this.percent[2] + this.percent[3])
    }
  }
}
</script>
