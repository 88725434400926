<template>
  <section class="grid">

      <section class="card">
        <section class="title">
          <h3><i class="icon-utilisateur"></i>PARTENAIRES</h3>
        </section>

        <div class="new">
          <p>
            <select id="filter" v-model="filter">
              <option :value="0">Période: Toute</option>
              <option :value="1">Depuis 1 semaine</option>
              <option :value="2">Depuis 1 mois</option>
              <option :value="3">Depuis 3 mois</option>
              <option :value="4">Depuis 6 mois</option>
              <option :value="5">Depuis 1 an</option>
            </select>
            <i class="icon-select"></i>
            <select id="filter" v-model="limituser">
              <option :value="5">Limite: 5</option>
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </div>

        <section class="grid add">

          <section class="card">
            <section class="table lastconnected by3">
              <small>Derniers connectés</small>
              <ul>
                <li><label>DATE</label><label>HEURE</label><label>PARTENAIRE</label></li>
              </ul>
              <ul>
                <li v-for="(last, l) in lastsconnected" :key="l">
                  <span class="bold">{{ last.meta.tech.lastconnection | shortDate }}</span>
                  <span class="bold">{{ last.meta.tech.lastconnection | timeOnly }}</span>
                  <span>{{ last.meta.lastname }} {{ last.meta.firstname | initial }}</span>
                </li>
              </ul>
            </section>
          </section>

          <section class="card">
            <section class="table lastconnected">
              <small>Top des réservations</small>
              <ul>
                <li><label>NOMBRE</label><label>PARTENAIRE</label></li>
              </ul>
              <ul>
                <li v-for="(top, t) in bestsellers(0)" :key="t">
                  <span class="bold">{{ top.count }}</span>
                  <span>{{ top.lastname }} {{ top.firstname | initial }} </span>
                </li>
              </ul>
            </section>
          </section>

          <section class="card">
            <section class="table lastconnected">
              <small>Top des ventes (depuis le 1er janvier)</small>
              <ul>
                <li><label>NOMBRE</label><label>PARTENAIRE</label></li>
              </ul>
              <ul>
                <li v-for="(top, t) in bestsellers(3)" :key="t">
                  <span class="bold">{{ top.count }}</span>
                  <span>{{ top.lastname }} {{ top.firstname | initial }} </span>
                </li>
              </ul>
            </section>
          </section>

        </section>
      </section>

    </section>
</template>

<script>
export default {
  name: 'StatisticsPartner',
  data () {
    return {
      filter: 0,
      limituser: 5,
      period: [
        // 100 ans
        100 * 365 * (1000 * 3600 * 24),
        // 1 semaine
        7 * (1000 * 3600 * 24),
        // 1 mois
        30 * (1000 * 3600 * 24),
        // 3 mois
        90 * (1000 * 3600 * 24),
        // 6 mois
        180 * (1000 * 3600 * 24),
        // 1 an
        365 * (1000 * 3600 * 24)
      ]
    }
  },
  computed: {
    reservations: function () {
      return this.$store.getters.get_reservations()
    },
    users: function () {
      return this.$store.getters.get_users()
    },
    lastsconnected: function () {
      var last = this.$store.getters.get_users_last_connected
      var datefiltering = Date.now() - this.period[this.filter]
      return last.filter(l => {
        return l.meta.tech.lastconnection > datefiltering
      }).slice(0, this.limituser)
    }
  },
  methods: {
    bestsellers: function (status) {
      // init var
      const best = []
      // select type of reservation by 'status'
      var reserv = this.reservations.filter(r => r.status > status)
      // if status acted filter by signing date or status date change acted
      // else filter by reservation date
      var januaryFirst = Date.parse(new Date(new Date().getFullYear(), 0, 1))
      if (status === 3) {
        reserv = reserv.filter(r => {
          if (r.notary.signdate) return r.notary.signdate > januaryFirst
          return r.lot.dateacted > januaryFirst
        })
      } else reserv = reserv.filter(r => r.lot.datereserv > januaryFirst)
      var datefiltering = Date.now() - this.period[this.filter]
      // filter period is selected
      if (datefiltering > 0) reserv = reserv.filter(r => r.created > datefiltering)

      this.users.forEach(u => {
        reserv.forEach(r => {
          if (u.username === r.user.username) {
            var index = best.map(b => b.username).indexOf(u.username)
            var payload = {
              username: u.username,
              firstname: u.meta.firstname,
              lastname: u.meta.lastname,
              count: 1
            }
            if (index > -1) {
              payload.count = best[index].count + 1
              best.splice(index, 1, payload)
            } else {
              best.push(payload)
            }
          }
        })
      })
      // sort by 'count' of reservation
      const final = best.slice().sort(function (a, b) {
        return (a.count < b.count) ? 1 : -1
      })
      return final.slice(0, this.limituser)
    }
  }
}
</script>
