<template>
  <aside class="menu" :class="{'drop': drop}">
    <nav>
      <router-link tag="img" to="/" :src="settings.upload + 'logo.png'" alt=""></router-link>
      <nav class="burger" @click="dropMenu()">☰</nav>
      <ul v-if="intern" @click="drop = 0">
        <router-link tag="li" to="/"><i class="icon-dashboard"></i> DASHBOARD</router-link>
        <router-link class="li-dark" tag="li" to="/programmes"><i class="icon-programme"></i> PROGRAMMES</router-link>
        <router-link tag="li" to="/lots"><i class="icon-lot"></i> LOTS</router-link>
        <router-link class="li-dark" tag="li" to="/reservations"><i class="icon-reservation"></i> RÉSERVATIONS</router-link>
        <router-link v-if="admin" tag="li" to="/utilisateurs"><i class="icon-utilisateur"></i> UTILISATEURS</router-link>
        <router-link v-if="admin" class="li-dark" tag="li" to="/prospects"><i class="icon-prospect"></i> PROSPECTS</router-link>
        <router-link v-if="admin" tag="li" to="/mailings"><i class="icon-mailing"></i> MAILINGS</router-link>
        <router-link :class="{'li-dark': admin}" tag="li" to="/statistiques"><i class="icon-statistique"></i> STATISTIQUES</router-link>
        <router-link v-if="superadmin" tag="li" to="/parametres"><i class="icon-parametres">⚙</i> PARAMÈTRES</router-link>
      </ul>
      <ul v-else @click="drop = 0">
        <router-link class="li-dark" tag="li" to="/"><i class="icon-dashboard"></i> ACCUEIL</router-link>
        <router-link tag="li" to="/liste-des-programmes"><i class="icon-programme"></i> PROGRAMMES</router-link>
      </ul>
      <ul class="logout">
        <router-link tag="li" to="/logout"><i class="icon-deconnexion"></i> DÉCONNEXION</router-link>
      </ul>
      <code>Version {{ settings.version }} <i class="textred lock" @click="menuSize()">⇹</i></code>
      <ul class="scrolltop">
        <li @click="scrollToTop()"><i>▲</i> RETOUR EN HAUT</li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import settings from '@/settings.js'

export default {
  name: 'Menu',
  data () {
    return {
      settings,
      drop: 0,
      menu: false
    }
  },
  computed: {
    lvl: function () {
      return this.$store.getters.lvl
    },
    intern: function () {
      return this.$store.getters.lvl > 2
    },
    admin: function () {
      return this.$store.getters.lvl > 3
    },
    superadmin: function () {
      return this.$store.getters.lvl > 4
    }
  },
  created () {
    window.addEventListener('scroll', this.checkScroll, { passive: true })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.checkScroll)
  },
  methods: {
    dropMenu: function () {
      this.drop = (this.drop ? 0 : 1)
    },
    menuSize: function () {
      var main = document.querySelector('main')
      if (this.menu) {
        main.classList.remove('menulock')
        this.menu = false
      } else {
        main.classList.add('menulock')
        this.menu = true
      }
    },
    checkScroll: function () {
      if (window.innerWidth > 1400) {
        var scrolltop = document.getElementsByClassName('scrolltop')[0]
        var windowTop = window.scrollY || document.documentElement.scrollTop
        if (windowTop > 300) scrolltop.classList.add('visible')
        else scrolltop.classList.remove('visible')
      }
    },
    scrollToTop: function () {
      var int = setInterval(function () {
        var windowTop = window.scrollY || document.documentElement.scrollTop
        var speed = windowTop / 1.75
        if (windowTop === 0) clearInterval(int)
        window.scrollTo(windowTop, speed)
      }, 30)
    }
  }
}
</script>
