import Auth from './actions/auth.js'
import Mailing from './actions/mailing.js'
import Program from './actions/program.js'
import Lot from './actions/lot.js'
import Request from './actions/request.js'
import Reservation from './actions/reservation.js'
import User from './actions/user.js'
import Prospect from './actions/prospect.js'
import Upload from './actions/upload.js'
import Config from './actions/config.js'
import Stats from './actions/stats.js'

const Actions = {
  ...Auth,
  ...Mailing,
  ...Program,
  ...Lot,
  ...Request,
  ...Reservation,
  ...User,
  ...Prospect,
  ...Upload,
  ...Config,
  ...Stats
}

export default Actions
