import Programmes from '@/views/Programs/Programs.vue'
import ProgrammesLimited from '@/views/Programs/Programs_Limited.vue'
import ProgrammeDetails from '@/views/Programs/Program_Details.vue'
import ProgrammeNew from '@/views/Programs/Program_New.vue'
import ProgrammeEdit from '@/views/Programs/Program_Edit.vue'
import ProgrammeDel from '@/views/Programs/Program_Del.vue'

const programs = [
  {
    path: '/programmes',
    name: 'Programmes',
    component: Programmes,
    meta: { lvl: 3 }
  },
  {
    path: '/liste-des-programmes',
    name: 'ProgrammesLimited',
    component: ProgrammesLimited,
    meta: { lvl: 1 }
  },
  {
    path: '/programme/details/:id',
    name: 'ProgrammeDetails',
    component: ProgrammeDetails,
    meta: { lvl: 1 }
  },
  {
    path: '/programme/new',
    name: 'ProgrammeNew',
    component: ProgrammeNew,
    meta: { lvl: 4 }
  },
  {
    path: '/programme/edit/:id',
    name: 'ProgrammeEdit',
    component: ProgrammeEdit,
    meta: { lvl: 4 }
  },
  {
    path: '/programme/del/:id',
    name: 'ProgrammeDel',
    component: ProgrammeDel,
    meta: { lvl: 5 }
  }
]

export default programs
