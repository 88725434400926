<template>
  <section class="grid">

      <section class="card">
        <section class="title">
          <h3><i class="icon-programme"></i>PROGRAMMES & LOTS</h3>
        </section>

        <div class="new">
          <p>
            <select id="filter" class="big" v-model="selectprogram">
              <option value="0">Programme: Aucun</option>
              <option v-for="(prog, p) in programs" :value="prog.numero" :key="p">{{ prog.name }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </div>

        <section class="grid add stats" v-if="selectprogram > 0" >

          <section class="card">
            <small>Stats par status</small>
            <Status v-bind:numero="selectprogram" />
          </section>

          <section class="card">
            <small>Stats par types</small>
            <Type v-bind:numero="selectprogram" />
          </section>

        </section>

        <section class="grid" v-if="selectprogram > 0" >

          <section class="card">
            <small>Stats lots</small>
            <Full v-bind:numero="selectprogram" />
          </section>

          <section class="card">
            <small>Stats partenaires</small>
            <small><small>(Les lots annulés ne sont pas comptabilisés dans le total)</small></small>
            <Partner v-bind:numero="selectprogram" />
          </section>

        </section>
        <hr/>

        <div class="new">
          <p>
            <select id="filter" v-model="statusprogram">
                <option value="-1">Status: Tous</option>
                <option v-for="(status, s) in settings.program.status" :value="s" :key="s">{{ status }}</option>
              </select>
            <i class="icon-select"></i>
            <select id="filter" v-model="limitprogram">
              <option :value="5">Limite: 5</option>
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </div>

        <section class="grid add">
          <section class="card">
            <section class="table lastconnected">
              <small>Top des visites (programme)</small>
              <ul>
                <li><label>NOMBRE</label><label>PROGRAMME</label></li>
              </ul>
              <ul>
                <li v-for="(prog, p) in programsclick" :key="p">
                  <span class="bold">{{ prog.stats.click }}</span>
                  <span> {{ prog.name }}</span>
                </li>
              </ul>
            </section>
          </section>

          <section class="card">
            <section class="table by3">
              <small>Top des téléchargement (lots)</small>
              <ul>
                <li><label>NOMBRE</label><label>PROGRAMME</label><label>LOT</label></li>
              </ul>
              <ul>
                <li v-for="(lot, p) in lotsclick" :key="p">
                  <span class="bold">{{ lot.stats.click }}</span>
                  <span>{{ lot.program.shortname }}</span>
                  <span>{{ lot.numero }}</span>
                </li>
              </ul>
            </section>
          </section>
        </section>

      </section>
    </section>
</template>

<script>
import Status from '@/components/StatisticsProgramStatus.vue'
import Type from '@/components/StatisticsProgramType.vue'
import Full from '@/components/StatisticsProgramFull.vue'
import Partner from '@/components/StatisticsProgramPartner.vue'
import settings from '@/settings.js'

export default {
  name: 'StatisticsProgram',
  components: {
    Status,
    Type,
    Full,
    Partner
  },
  data () {
    return {
      settings,
      limitprogram: 5,
      statusprogram: -1,
      selectprogram: 0
    }
  },
  computed: {
    programs: function () {
      var prog = this.$store.getters.get_programs()
      if (this.statusprogram > -1) {
        return prog.filter(p => {
          return p.status === this.statusprogram
        })
      }
      return prog
    },
    programsclick: function () {
      var PROGS = this.programs
      if (this.selectprogram > 0) {
        PROGS = PROGS.filter(p => {
          return p.numero === this.selectprogram
        })
      }
      return PROGS.slice().sort(function (a, b) {
        return (a.stats.click < b.stats.click) ? 1 : -1
      }).slice(0, this.limitprogram)
    },
    lots: function () {
      var lots = this.$store.getters.get_lots()
      if (this.statusprogram > -1) {
        var lotsfilter = []
        this.programs.forEach(p => {
          lots.forEach(l => {
            if (l.program.numero === p.numero) lotsfilter.push(l)
          })
        })
        return lotsfilter
      }
      return lots
    },
    lotsclick: function () {
      var LOTS = this.lots
      if (this.selectprogram > 0) {
        LOTS = LOTS.filter(l => {
          return l.program.numero === this.selectprogram
        })
      }
      return LOTS.slice().sort(function (a, b) {
        return (a.stats.click < b.stats.click) ? 1 : -1
      }).slice(0, this.limitprogram)
    }
  },
  methods: {
  }
}
</script>
