import Reservations from '@/views/Reservations/Reservations.vue'
import ReservationNew from '@/views/Reservations/Reservation_New.vue'
import ReservationEdit from '@/views/Reservations/Reservation_Edit.vue'
import ReservationDel from '@/views/Reservations/Reservation_Del.vue'

const reservations = [
  {
    path: '/reservations',
    name: 'Reservations',
    component: Reservations,
    meta: { lvl: 3 }
  },
  {
    path: '/reservations/expired',
    name: 'ReservationsExpired',
    component: Reservations,
    meta: { lvl: 3 }
  },
  {
    path: '/reservation/new',
    name: 'ReservationNew',
    component: ReservationNew,
    meta: { lvl: 4 }
  },
  {
    path: '/reservation/new/lot/:id',
    name: 'ReservationNewBylot',
    component: ReservationNew,
    meta: { lvl: 4 }
  },
  {
    path: '/reservation/edit/:id',
    name: 'ReservationEdit',
    component: ReservationEdit,
    meta: { lvl: 4 }
  },
  {
    path: '/reservation/del/:id',
    name: 'ReservationDel',
    component: ReservationDel,
    meta: { lvl: 5 }
  }
]

export default reservations
