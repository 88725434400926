<template>
  <section class="interface">
    <section class="title">
      <h1 v-if="messages.code === '200'"><i class="icon-loading">↻</i>CHARGEMENT</h1>
      <h1 v-else><i class="icon-alert"></i>DÉBOGAGE</h1>
    </section>
    <section class="grid">
      <section class="card" v-if="messages.code === '200'">
        <h2>Veuillez patienter</h2>
        <p>
          La base de donnée est en cours de chargement ...
        </p>
      </section>
      <section class="card" v-else>
        <h2>Erreur {{ messages.code }}</h2>
        <p>
          {{ messages.message }}
        </p>
      </section>
    </section>
  </section>
</template>

<script>

export default {
  name: 'Loading',
  props: {
    messages: Object
  },
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>
