<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-error"></i>ACCÈS RESTREINT</h1>
    </section>
    <p>Vous n'avez pas les droits requis pour accéder à cette page</p>
    <button @click="back()">&laquo; revenir en arrière</button>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'Restricted',
  components: {
    Profile
  },
  data () {
    return {
    }
  },
  methods: {
    back: function () {
      this.$router.go(-2)
    }
  }
}
</script>
