<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-error"></i>FERMETURE TEMPORAIRE</h1>
    </section>
    <p>Notre interface partenaire est un indisponible pour des raisons de maintenance et de mises à niveau.</p>
    <p>Veuillez réessayer quelques heures plus tard.</p>
    <button @click="contact()">Contactez-nous</button>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'Closed',
  components: {
    Profile
  },
  data () {
    return {
    }
  },
  methods: {
    contact: function () {
      window.location.href = 'https://www.groupeoceanic.fr/contact'
    }
  }
}
</script>
