<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-account"></i>DÉTAILS UTILISATEUR</h1>
    </section>
    <section class="grid">
      <section class="card">
          <h2>Identité</h2>
          <small class="corner right">
            <router-link tag="i" :to="{ path: '/utilisateur/edit/' + user.username }" class="icon-edit"></router-link>&nbsp;
            <router-link tag="i" :to="{ path: '/utilisateur/del/' + user.username }" class="icon-delete"></router-link>
          </small>
          <section>
            <p>
              <label class="badge">Genre:</label> {{ user.meta.gender | gender }}
            </p>
            <p>
              <label class="badge">Prénom:</label> {{ user.meta.firstname }}
            </p>
            <p>
              <label class="badge">Nom:</label> {{ user.meta.lastname }}
            </p>
            <p>
              <label class="badge">Email:</label> {{ user.meta.email }}
            </p>
            <p>
              <label class="badge">Société:</label> {{ user.meta.compagny }}
            </p>
            <p>
              <label class="badge">Date de naissance:</label> {{ user.meta.birthday |longDate }}
            </p>
            <p>
              <label class="badge">Activé:</label> <em v-if="user.actived" class="badge green">✔</em><em v-else class="badge red">✘</em>
            </p>
            <p>
              <label class="badge">Rôle:</label> {{ user.role | role }}
            </p>
            <p>
              <label class="badge">Total de connexion:</label> {{ user.meta.tech.connectioncount }}
            </p>
            <p>
              <label class="badge">Dernière connexion:</label> {{ user.meta.tech.lastconnection | timeDate }}
            </p>
          </section>
      </section>
      <section class="card">
          <h2>Contact</h2>
          <section>
            <p>
              <label class="badge">Adresse:</label> {{ user.meta.contact.address }}
            </p>
            <p>
              <label class="badge">Ville:</label> {{ user.meta.contact.city }}
            </p>
            <p>
              <label class="badge">Code postal:</label> {{ user.meta.contact.zipcode || 'n/a' }}
            </p>
            <p>
              <label class="badge">Téléphone:</label> {{ user.meta.contact.phone || 'n/a' | phone }}
            </p>
            <p>
              <label class="badge">Mobile:</label> {{ user.meta.contact.mobile || 'n/a' | phone }}
            </p>
          </section>
      </section>
      <section class="card"><small>Data.json</small>
        <pre class="scroll">{{ user }}</pre>
      </section>
    </section>
    <section class="title">
      <h1><i class="icon-reservation"></i>RÉSERVATIONS</h1>
    </section>
    <ReservationsList :reservations="reservations" />
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import ReservationsList from '@/components/ReservationsList.vue'

export default {
  name: 'UtilisateurDetails',
  components: {
    Profile,
    ReservationsList
  },
  computed: {
    user: function () {
      return this.$store.getters.get_user_by_username(this.$route.params.id)
    },
    reservations: function () {
      return this.$store.getters.get_reservations_by_user_username(this.$route.params.id)
    }
  },
  data () {
    return {
    }
  }
}
</script>
