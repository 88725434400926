<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-programme"></i>LISTE DES PROGRAMMES</h1>
    </section>
    <section class="programs filter">
      <ul>
        <li class="filterchoice">
          <select v-model="dir">
            <option value="0">Date: Les plus récents</option>
            <option value="1">Chronologie</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredB">
            <option value="-1">Régions: Toutes</option>
            <option v-for="(region, r) in settings.program.region" :value="r" :key="r">{{ region }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredA">
            <option value="-1">DAT: Toutes</option>
            <option v-for="(dat, d) in dats" :value="dat" :key="d">{{ dat }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
      </ul>
      <ul>
        <li class="filtersearch"><input v-model="search" type="text" placeholder="Recherche"/><i class="icon-search"></i></li>
      </ul>
    </section>

    <section class="programs grid">
      <section class="card" v-for="prog in programSearch" :key="prog.id">
        <ProgrammesCard v-bind:prog="prog"/>
      </section>
    </section>

    <p v-if="programSearch.length === 0" class="card nodata">
      Aucune donnée n’est disponible
    </p>

  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import ProgrammesCard from '@/components/ProgrammesCard.vue'
import settings from '@/settings.js'

export default {
  name: 'Programmes',
  components: {
    Profile,
    ProgrammesCard
  },
  data () {
    return {
      settings,
      sort: 'numero',
      dir: 0,
      search: '',
      filteredA: -1,
      filteredB: -1
    }
  },
  computed: {
    programs: function () {
      return this.$store.getters.get_programs().filter(el => el.status === 1)
    },
    dats: function () {
      var today = Date.now()
      var dats = [this.$options.filters.yearDate(today)]
      for (let i = 1; i < 5; i++) {
        var years = this.$options.filters.yearDate(today + ((365 * i) * (1000 * 3600 * 24)))
        dats.push(years)
      }
      return dats
    },
    sorted: function () {
      var sort = this.sort
      var dir = Number(this.dir)
      return this.programs.slice().sort(function (a, b) {
        if (dir) return (a[sort] > b[sort]) ? 1 : -1
        return (a[sort] < b[sort]) ? 1 : -1
      })
    },
    programRegion: function () {
      var fil = Number(this.filteredB)
      if (fil === -1) return this.sorted
      return this.sorted.filter(el => el.location.region === fil)
    },
    programDat: function () {
      var fil = Number(this.filteredA)
      if (fil === -1) return this.programRegion
      return this.programRegion.filter(el => Number(this.$options.filters.yearDate(el.dat)) === fil)
    },
    programSearch: function () {
      if (this.search) {
        return this.programDat.filter((item) => {
          return this.search.toLowerCase().split(' ').every(v =>
            item.numero.toString().includes(v) ||
            item.name.toLowerCase().includes(v) ||
            item.location.city.toLowerCase().includes(v) ||
            item.location.district.toLowerCase().includes(v)
          )
        })
      }
      return this.programDat
    }
  },
  methods: {
  }
}
</script>
