<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-programme"></i>NOUVEAU PROGRAMME</h1>
    </section>
    <form @submit.prevent="add()">
    <section class="grid add">
      <section class="card new">
        <h2>Dénomination <sup class="textred">*</sup></h2>
        <section>
          <p>
            <label for="num">Numéro (auto)</label>
            <label for="auto">
              <input type="checkbox" id="auto" class="toggle" v-model="auto">
              <small>Désactiver la numérotation automatique</small>
            </label>
            <small v-if="auto" class="info red">Avertissement: Le numéro doit impérativement être unique!<br/>
            Veillez à ne pas faire de doublon avec un autre programme.</small>
            <input v-if="!auto" type="text" size="4" id="num" v-model="program.numero" disabled />
            <input v-if="auto" type="text" size="4" id="num" v-model="program.numero" />
          </p>
        </section>
        <section>
          <p>
            <label for="name">Nom du programme</label>
            <input type="text" id="name" v-model="program.name" @change="shorten()" />
          </p>
        </section>
        <section>
          <p>
            <label for="short">Nom court (10 char)</label>
            <input type="text" id="short" size="10" maxlength="10" v-model="program.shortname" />
          </p>
        </section>
        <section>
          <p>
            <label for="summary">Résumé (175 char)</label>
            <textarea maxlength="175" v-model="program.summary" id="summary"
            rows="5" cols="30"></textarea>
          </p>
        </section>
        <section>
          <p>
            <label for="desc">Description complète (500 char)</label>
            <textarea maxlength="400" v-model="program.description" id="desc"
            rows="16" cols="30"></textarea>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Paramètres</h2>
        <section>
          <p>
            <label for="stat">Statut</label>
            <select id="stat" v-model="program.status">
              <option v-for="(stat, s) in settings.program.status" :value="s" :key="s">{{ stat }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="category">Catégorie</label>
            <select id="category" v-model="program.category">
              <option value="0">Aucune</option>
              <option value="1">Résidence</option>
              <option value="2">Villa</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="law">Ancien Dispositif de loi</label>
            <select id="law" v-model="program.law" disabled>
              <option v-for="(law, l) in config.laws" :value="l" :key="l">{{ law }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section v-for="(laws, li) in program.laws" :value="li" :key="li">
          <p>
            <label :for="'laws'+ li">Dispositif de loi ({{ li + 1 }}) <u v-if="li > 0" class="verysmall icon-delete" @click="removeLaw(li)"></u></label>
            <select :for="'laws'+ li" v-model="program.laws[li]">
              <option v-for="(law, l) in config.laws" :value="l" :key="l">{{ law }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section class="small">
          <button class="verysmall" type="button" @click="addLaw()"><i class="icon-add btn pr1"></i> Ajouter un dispositif</button>
        </section>
        <section>
          <p>
            <label for="exp">Exposition</label>
            <select id="exp" v-model="program.exposure">
              <option v-for="(expo, e) in settings.lot.exposure" :value="e" :key="e">{{ expo }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="launch">Date de lancement</label>
            <v-date-picker id="launch" v-model="program.launch"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
        <section>
          <p>
            <label for="dat">Achèvement des Travaux (dat)</label>
            <v-date-picker id="dat" v-model="program.dat"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
        <section>
          <p>
            <label for="delivery">Date de livraison (année)</label>
            <v-date-picker id="delivery" v-model="program.delivery"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
        <section>
          <p>
            <label for="created">Date de création</label>
            <input type="text" id="created" disabled :value="program.created | shortDate" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Situation</h2>
        <section>
          <p>
            <label for="city">Ville</label>
            <input type="text" id="city" v-model="program.location.city" />
          </p>
        </section>
        <section>
          <p>
            <label for="adress">Adresse</label>
            <input type="text" id="adress" v-model="program.location.address" />
          </p>
        </section>
        <section>
          <p>
            <label for="district">Quartier</label>
            <input type="text" id="district" v-model="program.location.district" />
          </p>
        </section>
        <section>
          <p>
            <select v-model="program.location.region">
              <option value="-1">Aucune</option>
              <option v-for="(region, r) in settings.program.region" :value="r" :key="r">{{ region }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="country">Pays</label>
            <input type="text" id="country" v-model="program.location.country" />
          </p>
        </section>
        <section>
          <p>
            <label for="zip">Code postal</label>
            <input type="text" id="zip" v-model="program.location.zipcode" />
          </p>
        </section>
        <section>
          <p>
            <label for="position">Position lat, lng</label>
            <input type="text" id="position" v-model="program.location.position.lat" />
            <br/><br/>
            <input type="text" id="position" v-model="program.location.position.lng" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Types</h2>
        <section>
          <p>
            <label for="Tmin">Type Min</label>
            <select id="Tmin" v-model="program.lot.type.min">
              <option v-for="(lot, lt) in settings.lot.type" :value="lt" :key="lt">{{ lot }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="Tmax">Type Max</label>
            <select id="Tmax" v-model="program.lot.type.max">
              <option v-for="(lot, lt) in settings.lot.type" :value="lt" :key="lt">{{ lot }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="quantity">Quantité des lots</label>
            <input type="text" id="quantity" v-model="program.lot.quantity" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Surfaces</h2>
        <section>
          <p>
            <label for="Smin">Taille Min</label>
            <input type="text" id="Smin" v-model="program.lot.size.min" step="0.01" min="0" />
          </p>
        </section>
        <section>
          <p>
            <label for="Smax">Taille Max</label>
            <input type="text" id="Smax" v-model="program.lot.size.max" step="0.01" min="0" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Prix</h2>
        <section>
          <p>
            <label for="Pmin">Prix Min</label>
            <input type="text" id="Pmin" v-model="program.lot.price.min" />
          </p>
        </section>
        <section>
          <p>
            <label for="Pmax">Prix Max</label>
            <input type="text" id="Pmax" v-model="program.lot.price.max" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Images</h2>
        <section>
          <p>
            <label for="thumb">Vignette</label>
            <small>400x225 &lt; 200Ko</small>
            <input type="file" id="thumb" ref="thumb" v-on:change="handle('thumb','thumb','img')"/>
          </p>
        </section>
        <section>
          <p>
            <label for="logo">Logo</label>
            <small>128x128 &lt; 100Ko</small>
            <input type="file" id="logo" ref="logo" v-on:change="handle('logo','logo','img')"/>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Galerie d'images</h2>
        <section>
          <p>
            <small>800x450 &lt; 400Ko</small>
            <em class="verysmall">Image 1</em> <input type="file" id="img1" ref="img1" v-on:change="handle('img1','img1','img')"/>
          </p>
          <p>
            <em class="verysmall">Image 2</em> <input type="file" id="img2" ref="img2" v-on:change="handle('img2','img2','img')"/>
          </p>
          <p>
            <em class="verysmall">Image 3</em> <input type="file" id="img3" ref="img3" v-on:change="handle('img3','img3','img')"/>
          </p>
          <p>
            <em class="verysmall">Image 4</em> <input type="file" id="img4" ref="img4" v-on:change="handle('img4','img4','img')"/>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Téléchargement</h2>
        <section>
          <p>
            <label for="booklet">Plaquette commerciale</label>
            <small>.pdf &lt; 5Mo</small>
            <input type="file" id="booklet" ref="booklet" v-on:change="handle('booklet','plaquette','doc')"/>
          </p>
        </section>
        <section>
          <p>
            <label for="grid">Grille de prix</label>
            <small>.csv .xls .xlsx &lt; 5Mo</small>
            <input type="file" id="grid" ref="grid" v-on:change="handle('grid','grille','doc')"/>
          </p>
        </section>
        <section>
          <p>
            <label for="simulator">Simulateur</label>
            <small>.xls .xlsx &lt; 5Mo</small>
            <input type="file" id="simulator" ref="simulator" v-on:change="handle('simulator','simulateur','doc')"/>
          </p>
        </section>
        <section>
          <p>
            <label for="contract">Contrat de partenariat</label>
            <small>.doc .docx &lt; 5Mo</small>
            <input type="file" id="contract" ref="contract" v-on:change="handle('contract','contrat','doc')"/>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Videos</h2>
        <section>
          <p>
            <small>URL webinar (https://url.com/video)</small>
            <input type="text" id="video" size="30" v-model="program.download.video" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Critères</h2>
        <section>
          <small>Diagnostic de performance énergétique</small>
          <p>
            <label for="dpeclasse">Classe énergie</label>
            <select id="dpeclasse" v-model="program.lot.dpe.classe">
              <option v-for="(classe, ce) in settings.program.dpe.classe" :value="ce" :key="ce">{{ classe }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
          <p>
            <label for="dpeges">Gaz à Effet de Serre (GES)</label>
            <select id="dpeges" v-model="program.lot.dpe.ges">
              <option v-for="(ges, ge) in settings.program.dpe.classe" :value="ge" :key="ge">{{ ges }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
          <small>Normes & Certifications</small>
          <p>
            <label for="nfrt">Réglementation thermique</label>
            <select id="nfrt" v-model="program.lot.certification.rt">
              <option v-for="(rt, r) in settings.program.certification.rt" :value="r" :key="r">{{ rt }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
          <p>
            <label for="nfhabit">Norme Française</label>
            <select id="nfhabit" v-model="program.lot.certification.nf">
              <option v-for="(nf, n) in settings.program.certification.nf" :value="n" :key="n">{{ nf }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ program }}</pre>
      </section>
    </section>
    <section v-if="validate()" class="card">
      <p>
        <button type="submit" :disabled="processing">AJOUTER</button>
      </p>
    </section>
    <InvalidForm v-else />
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import settings from '@/settings.js'

export default {
  name: 'ProgrammeNew',
  components: {
    Profile,
    InvalidForm
  },
  data () {
    return {
      settings,
      processing: false,
      uploads: [],
      auto: false,
      program: {
        id: '',
        name: '',
        shortname: '',
        summary: '',
        description: '',
        text: '',
        numero: 0,
        thumbnail: '',
        logo: '',
        images: ['', '', '', ''],
        download: {
          booklet: '',
          grid: '',
          simulator: '',
          contract: '',
          video: ''
        },
        category: 0,
        law: 0,
        laws: [0],
        exposure: 4,
        status: 0,
        lot: {
          type: {
            min: 1,
            max: 1,
            all: []
          },
          quantity: '1',
          size: {
            min: '0',
            max: '0'
          },
          price: {
            min: '0',
            max: '0'
          },
          dpe: {
            classe: 0,
            ges: 0
          },
          certification: {
            rt: 0,
            nf: 0
          }
        },
        dat: 0,
        launch: 0,
        delivery: 0,
        created: Date.now(),
        location: {
          address: '',
          district: '',
          city: '',
          region: -1,
          zipcode: '',
          country: 'France',
          position: {
            lat: '',
            lng: ''
          }
        },
        stats: {
          click: 0
        }
      }
    }
  },
  computed: {
    lastNum: function () {
      return this.$store.getters.get_program_last_numero() + 1
    },
    config: function () {
      return this.$store.getters.get_settings()
    }
  },
  created () {
    this.program.numero = this.lastNum
  },
  methods: {
    add: function () {
      this.processing = true
      const payload = {
        program: this.program,
        uploads: this.uploads
      }
      this.$store.dispatch('addProgramWithUploads', payload)
        .then(() => {
          this.$router.push('/programmes')
        })
        .catch()
    },
    handle: function (input, name, type) {
      var file = this.$refs[input].files[0]
      if (file) {
        var newupload = []
        newupload[0] = file
        newupload[1] = name
        newupload[2] = type
        newupload[3] = input
        this.uploads.push(newupload)
      }
    },
    shorten: function () {
      this.program.shortname = this.program.name.split(' ')[0].slice(0, 8)
    },
    validate: function () {
      if (!Number(this.program.numero)) return false
      if (this.program.name === '') return false
      if (this.program.shortname === '') return false
      return true
    },
    removeLaw: function (value) {
      this.program.laws.splice(value, 1)
    },
    addLaw: function () {
      this.program.laws.push(0)
    }
  }
}
</script>
