<template>
  <section class="users table">
    <ul>
      <li class="stickyLabel">
        <label class="sort reset" @click="reset()">ID</label>
        <label class="sort" :class="classActive('role')" @click="sortby('role')">RÔLE</label>
        <label class="sort" :class="classActive('username')" @click="sortby('username')">NOM</label>
        <label>EMAIL</label>
        <label>SOC</label>
        <label>TEL</label>
        <label class="sort" :class="classActive('meta.tech.connectioncount')" @click="sortby('meta.tech.connectioncount')">NBR</label>
        <label>LAST</label>
        <label class="sort" :class="classActive('actived')" @click="sortby('actived')">STATUT</label>
        <label>ACT</label>
      </li>
    </ul>
    <ul>
      <li class="card td" v-for="(user, l) in sorted" :key="l" v-on:dblclick="router(user.username)">
        <span>{{ user.username }}</span>
        <span class="bold">{{ user.role | role }}</span>
        <span >{{ user.meta.lastname.toUpperCase() || '' }} {{ user.meta.firstname || '' }}</span>
        <span class="small" :title="user.meta.email">{{ user.meta.email || 'n/a'  | shortEmail }}</span>
        <span class="bold">{{ user.meta.compagny || 'n/a' }}</span>
        <span class="small">{{ user.meta.contact.phone || 'n/a' | phone  }}</span>
        <span class="bold">{{ user.meta.tech.connectioncount }}</span>
        <span class="small">{{ user.meta.tech.lastconnection | timeDate }}</span>
        <span><i v-if="user.actived" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span>
          <router-link tag="i" :to="{ path: '/utilisateur/details/'+user.username}" class="icon-see"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/utilisateur/edit/'+user.username}" class="icon-edit"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/utilisateur/del/'+user.username}" class="icon-delete"></router-link>
        </span>
      </li>
    </ul>
    <p v-if="users.length === 0" class="card nodata">Aucune donnée n’est disponible</p>
    <p class="loadmore" v-if="limit < users.length">
      <button @click="more()">Afficher plus</button>
    </p>
  </section>
</template>

<script>
export default {
  name: 'usersList',
  props: {
    users: Array
  },
  data () {
    return {
      dir: true,
      sort: 'created',
      limit: 50
    }
  },
  computed: {
    sorted: function () {
      var dir = this.dir
      var sort = this.sort
      return this.users.slice().sort(function (a, b) {
        if (sort === 'meta.tech.connectioncount') {
          if (dir) return (a.meta.tech.connectioncount > b.meta.tech.connectioncount) ? 1 : -1
          return (a.meta.tech.connectioncount < b.meta.tech.connectioncount) ? 1 : -1
        } else {
          if (dir) return (a[sort] > b[sort]) ? 1 : -1
          return (a[sort] < b[sort]) ? 1 : -1
        }
      }).slice(0, this.limit)
    }
  },
  methods: {
    sortby: function (value) {
      if (this.sort === value) {
        if (this.dir) this.dir = false
        else this.dir = true
      } else {
        this.sort = value
      }
    },
    reset: function () {
      this.sort = 'created'
      this.dir = true
    },
    classActive: function (value) {
      var classe = ''
      if (this.sort === value) classe = 'active'
      if (this.dir === false) classe += ' dir'
      return classe
    },
    router: function (username) {
      this.$router.push('/utilisateur/details/' + username)
    },
    more: function () {
      this.limit += 50
    }
  }
}
</script>
