<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-reservation"></i>NOUVELLE RÉSERVATION</h1>
    </section>
    <form @submit.prevent="add()">
    <section class="grid add">
      <section class="card new">
        <h2>Programme <sup class="textred">*</sup></h2>
        <section>
          <figure><img alt="" :src="image(program.data.thumbnail || '')" /></figure>
          <p>
            <label for="program">Choix du programme (Obligatoire)</label>
            <select id="program" v-model="program.numero" @click="setProgram()">
              <option value="0">Aucun</option>
              <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Lot <sup class="textred">*</sup></h2>
        <section>
          <p>
            <label for="lot">Choix du lot (Obligatoire) </label>
            <select id="lot" v-model="lot.uid" @click="setLot()">
              <option value="">Aucun</option>
              <option v-for="l in lots" :value="l.uid" :key="l.id">{{ l.spec.building }} {{ l.numero }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
          <div v-if="reservation.lot.numero">
            <p>
              <span class="badge">Infos:</span> {{ lot.data.spec.type | type }} ({{ lot.data.spec.floor | floor }}) {{ lot.data.spec.size || '0'  }} m² (Bat: {{ lot.data.spec.building }})
            </p>
            <p>
              <span class="badge">Prix:</span> {{ lot.data.spec.price.toLocaleString()  || '0' }} €
            </p>
            <p>
              <span class="badge">Statut:</span> <span class="badge" :class="status(lot.data.status)">{{ lot.data.status | lot_status }}</span>
            </p>
            <p>
              <label for="stat">Statut</label>
              <select id="stat" v-model="reservation.status">
                <option v-for="(reserv, r) in settings.reservation.status" :value="r" :key="r">{{ reserv }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
            <p>
              <label for="law">Dispositif de loi</label>
              <select id="law" v-model="reservation.lot.law">
                <option v-for="(law, l) in settings.lot.law" :value="l" :key="l">{{ law }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
            <p>
              <label for="law">Durée de défiscalisation</label>
              <select id="law" v-model="reservation.lot.lawyears">
                <option v-for="(years, y) in settings.lot.lawyears" :value="y" :key="y">{{ years }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
            <p>
              <label for="extra">Option supplémentaire (ex: parking)</label>
              <input type="text" id="extra" v-model="reservation.lot.extra" />
            </p>
            <p>
              <label for="actedprice">Prix Acté</label>
              <input type="number" step="0.01" id="actedprice" v-model="reservation.lot.actedprice" />
            </p>
          </div>
        </section>
      </section>
      <section class="card new">
        <h2>Paramètres</h2>
         <section>
          <p>
            <label for="num">Numéro (auto)</label>
            <input type="text" id="num" v-model="reservation.numero" disabled />
          </p>
        </section>
        <section>
          <p>
            <label for="created">Date de création</label>
            <input type="text" id="created" disabled :value="reservation.created | shortDate" />
          </p>
        </section>
        <hr/>
        <section>
          <p>
            <label for="history">
              <input type="checkbox" class="toggle" id="history" v-model="history" />
              📅 Historique des dates
            </label>
          </p>
        </section>
        <section v-if="history">
          <p>
            <small class="info orange">Les dates sont renseignées de façon automatique à chaque changement de statut, mais vous pouvez les modifier manuellement ci-dessous.</small>
            <label for="dateoption">Date d'option</label>
            <v-date-picker id="dateoption" v-model="reservation.lot.dateoption"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
          <p>
            <label for="datereserv">Date de réservation</label>
            <v-date-picker id="datereserv" v-model="reservation.lot.datereserv"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
          <p>
            <label for="datefinanced">Date de financement</label>
            <v-date-picker id="datefinanced" v-model="reservation.lot.datefinanced"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
          <p>
            <label for="dateacted">Date de l'acte</label>
            <v-date-picker id="dateacted" v-model="reservation.lot.dateacted"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
          <p>
            <label for="datedatecancel">Date d'annulation</label>
            <v-date-picker id="datecancel" v-model="reservation.lot.datecancel"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Partenaire <sup class="textred">*</sup></h2>

        <findUser @send="setUser" />

      </section>
      <section class="card new">
        <h2>Client <sup class="textred">*</sup></h2>

        <findProspect :filter="1" :type="'prospect'" @send="setProspect" />

        <section v-if="reservation.prospect.numero">

          <hr/>

          <label class="small" for="associate">
            <input type="checkbox" class="toggle" id="associate" v-model="reservation.prospect.associate" />
            Ajout d'un conjoint, associé
          </label>

          <findProspect v-if="reservation.prospect.associate" :filter="1" :prospect="reservation.prospect.numero2"
           :type="'prospect'" @send="setAssociate" />

        </section>

        <hr/>

        <p>
          <label for="endorsement1">Avenant 1
            <input type="checkbox" class="toggle" id="endorsement1" v-model="reservation.prospect.endorsement1" />
          </label>
        </p>
        <p>
          <label for="endorsement2">Avenant 2
            <input type="checkbox" class="toggle" id="endorsement2" v-model="reservation.prospect.endorsement2" />
          </label>
        </p>
        <p>
          <label for="deposit">Caution (1000 €)
            <input type="checkbox" class="toggle" id="deposit" v-model="reservation.prospect.deposit" />
          </label>
        </p>
        <p>
          <label for="letter" @change="todaySRU()">Lettre SRU
            <input type="checkbox" class="toggle" id="letter" v-model="reservation.prospect.letter" />
          </label>
        </p>
        <p v-if="reservation.prospect.letter">
          <label class="small" for="datesru">Date d'envoi SRU</label>
          <v-date-picker id="datesru" v-model="reservation.prospect.datesru"
          :masks="{ input: 'DD/MM/YYYY' }"
          :model-config="{ type: 'number' }">
            <template #default="{ inputValue, inputEvents }">
              <input :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </p>
        <p>
          <label for="acknowledgment">Accusé de reception
            <input type="checkbox" class="toggle" id="acknowledgment" v-model="reservation.prospect.acknowledgment" />
          </label>
        </p>
      </section>
      <section class="card new">
        <h2>Notaire</h2>

        <findProspect :filter="3" :type="'notary'" @send="setProspect" />

        <section>
          <p>
            <label for="involvement">Implication</label>
            <select id="involvement" v-model="reservation.notary.involvement">
              <option :value="2">aucune</option>
              <option :value="0">participation</option>
              <option :value="1">procuration</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <hr/>
        <section>
          <p>
            <label for="send">Dossier envoyé
              <input type="checkbox" class="toggle" id="send" v-model="reservation.notary.send" />
            </label>
          </p>
          <p>
            <label for="sendoffer">Offre de prêt envoyé
              <input type="checkbox" class="toggle" id="sendoffer" v-model="reservation.notary.sendoffer" />
            </label>
          </p>
          <p>
            <label for="cash">Paiement cash
              <input type="checkbox" class="toggle" id="cash" v-model="reservation.notary.cash" />
            </label>
          </p>
          <p>
            <label for="not" @change="todayNOTIF()">Notification
              <input type="checkbox" class="toggle" id="not" v-model="reservation.notary.notif" />
            </label>
          </p>
          <p v-if="reservation.notary.notif">
            <label class="small" for="notary.notifdate">Date notification</label>
            <v-date-picker id="notary.notifdate" v-model="reservation.notary.notifdate"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
          <p>
            <label for="notary.sign">Acte de vente
              <input type="checkbox" class="toggle" id="notary.sign" v-model="reservation.notary.sign" />
            </label>
          </p>
          <p v-if="reservation.notary.sign">
            <label class="small" for="notary.signdate">Date de l'acte</label>
            <v-date-picker id="notary.signdate" v-model="reservation.notary.signdate"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Banque</h2>

        <findProspect :filter="4" :type="'bank'" @send="setProspect" />

      </section>
      <section class="card new">
        <h2>Banquier</h2>
        <p>
          <label for="conselor.firstname">Nom / Prénom</label>
          <input type="text" id="conselor.firstname" v-model="reservation.bank.counselor.firstname" />
        </p>
        <p>
          <label for="conselor.email">Email</label>
          <input type="text" id="conselor.email" v-model="reservation.bank.counselor.email" />
        </p>
        <p>
          <label for="conselor.phone">Téléphone</label>
          <input type="text" id="conselor.phone" v-model="reservation.bank.counselor.phone" />
        </p>
      </section>
      <section class="card new">
        <h2>Emprunt</h2>
        <p>
          <label for="loan.agreement">Accord de prêt
            <input type="checkbox" class="toggle" id="loan.agreement" v-model="reservation.bank.loan.agreement" />
          </label>
        </p>
        <p v-if="reservation.bank.loan.agreement">
          <label class="small" for="loan.agreementdate">Date accord</label>
          <v-date-picker id="loan.agreementdate" v-model="reservation.bank.loan.agreementdate"
          :masks="{ input: 'DD/MM/YYYY' }"
          :model-config="{ type: 'number' }">
            <template #default="{ inputValue, inputEvents }">
              <input :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </p>
        <p>
          <label for="loan.offer">Offre de prêt
            <input type="checkbox" class="toggle" id="loan.offer" v-model="reservation.bank.loan.offer" />
          </label>
        </p>
        <p v-if="reservation.bank.loan.offer">
          <label class="small" for="loan.offerdate">Date offre</label>
          <v-date-picker id="loan.offerdate" v-model="reservation.bank.loan.offerdate"
          :masks="{ input: 'DD/MM/YYYY' }"
          :model-config="{ type: 'number' }">
            <template #default="{ inputValue, inputEvents }">
              <input :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </p>
        <p>
          <label for="loan.amount">Montant</label>
          <input type="number" id="loan.amount" v-model="reservation.bank.loan.amount" />
        </p>
        <p>
          <label for="loan.contribution">Apport</label>
          <input type="number" id="loan.contribution" v-model="reservation.bank.loan.contribution" />
        </p>
        <p>
          <label for="loan.duration">Durée (années)</label>
          <input type="number" id="loan.duration" v-model="reservation.bank.loan.duration" />
        </p>
        <p>
          <label for="loan.rate">Taux %</label>
          <input type="number" id="loan.rate" step="0.01" v-model="reservation.bank.loan.rate" />
        </p>
        <p>
          <label for="loan.guarantee">Garantie</label>
          <input type="text" id="loan.guarantee" v-model="reservation.bank.loan.guarantee" />
        </p>
      </section>
      <section class="card new">
        <h2>Remarques</h2>
        <section>
          <p>
            <label for="notarycost">Frais de notaire offert</label>
            <select id="notarycost" v-model="reservation.remarks.notarycost">
              <option :value="0">non</option>
              <option :value="1">oui</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="managementcost">Frais de gestion offert</label>
            <select id="managementcost" v-model="reservation.remarks.managementcost">
              <option :value="0">non</option>
              <option :value="1">oui</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="remarktext">Texte</label>
            <textarea maxlength="400" v-model="reservation.remarks.text" id="remarktext"
            rows="10" cols="30"></textarea>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Api</h2>
        <section>
          <p>
            <label for="apisign">Docusign URL</label>
            <input type="text" id="apisign" v-model="reservation.api.sign" />
          </p>
        </section>
        <section>
          <p>
            <label for="ar24">Ar24 URL</label>
            <input type="text" id="ar24" v-model="reservation.api.ar24" />
          </p>
        </section>
      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ reservation }}</pre>
      </section>
    </section>
    <section v-if="validate()" class="card">
      <p>
        <button type="submit" :disabled="processing">AJOUTER</button>
      </p>
    </section>
    <InvalidForm v-else />
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import FindProspect from '@/components/FindProspect.vue'
import FindUser from '@/components/FindUser.vue'
import { image } from '@/helpers.js'
import settings from '@/settings.js'

export default {
  name: 'ReservationNew',
  components: {
    Profile,
    InvalidForm,
    FindProspect,
    FindUser
  },
  data () {
    return {
      settings,
      processing: false,
      history: false,
      program: {
        numero: 0,
        data: {}
      },
      lot: {
        uid: '',
        data: {}
      },
      reservation: {
        id: '',
        numero: 0,
        program: {
          id: '',
          numero: 0,
          shortname: ''
        },
        lot: {
          id: '',
          uid: '',
          numero: 0,
          actedprice: 0,
          extra: '',
          lastmodified: Date.now(),
          dateoption: 0,
          datereserv: 0,
          datefinanced: 0,
          dateacted: 0,
          datecancel: 0,
          law: 0,
          lawyears: 0
        },
        user: {
          id: '',
          username: '',
          firstname: '',
          lastname: ''
        },
        prospect: {
          id: '',
          numero: 0,
          numero2: 0,
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          endorsement1: 0,
          endorsement2: 0,
          deposit: 0,
          letter: 0,
          datesru: 0,
          acknowledgment: 0,
          associate: 0
        },
        notary: {
          id: '',
          numero: 0,
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          involvement: 2,
          send: 0,
          sendoffer: 0,
          cash: 0,
          notif: 0,
          notifdate: 0,
          sign: 0,
          signdate: 0
        },
        bank: {
          id: '',
          numero: 0,
          lastname: '',
          email: '',
          phone: '',
          counselor: {
            firstname: '',
            email: '',
            phone: ''
          },
          loan: {
            agreement: 0,
            agreementdate: 0,
            offer: 0,
            offerdate: 0,
            amount: 0,
            contribution: 0,
            duration: 0,
            rate: 0,
            guarantee: ''
          }
        },
        remarks: {
          notarycost: 0,
          managementcost: 0,
          text: ''
        },
        status: 1,
        created: Date.now(),
        api: {
          docusign: '',
          ar24: ''
        }
      }
    }
  },
  computed: {
    programs: function () {
      return this.$store.getters.get_programs()
    },
    lots: function () {
      const lots = this.$store.getters.get_lots_by_program_num(this.program.numero)
      return lots.filter(l => l.status === 0)
    },
    lastNum: function () {
      return this.$store.getters.get_reservation_last_numero() + 1
    },
    // prospects
    prospects: function () {
      return this.$store.getters.get_prospects()
    },
    users: function () {
      return this.$store.getters.get_users()
    }
  },
  created () {
    if (this.$route.params.id) {
      const selectedLot = this.$store.getters.get_lot_by_uid(this.$route.params.id)
      this.program.numero = selectedLot.program.numero
      this.setProgram()
      this.lot.uid = selectedLot.uid
      this.setLot()
    }
    this.reservation.numero = this.lastNum
    this.reservation.created = Date.now()
  },
  methods: {
    add: function () {
      this.setStatusDate()
      this.processing = true
      var reservation = this.reservation
      // get the last id
      this.$store.dispatch('fetchReservations')
        .then(() => {
          this.$store.dispatch('addReservation', reservation)
            .then(() => {
              this.$store.dispatch('editXml', reservation.program.id)
                .then(() => {
                  this.$router.push('/reservations')
                })
                .catch()
            })
            .catch()
        })
        .catch((err) => { this.messages = err.status })
    },
    setProgram: function () {
      // reset lot data
      this.reservation.lot.numero = ''
      this.lot = {
        uid: '',
        data: {}
      }
      // get the num from select
      if (Number(this.program.numero)) {
        this.program.data = this.programs.find(p => p.numero === this.program.numero)
        this.reservation.program.id = this.program.data.id
        this.reservation.program.numero = this.program.data.numero
        this.reservation.program.shortname = this.program.data.shortname
      } else {
        this.reservation.program.id = ''
        this.reservation.program.numero = 0
        this.reservation.program.shortname = ''
        this.program = {
          numero: 0,
          data: {}
        }
      }
    },
    setLot: function () {
      if (this.lot.uid !== '') {
        this.lot.data = this.lots.find(l => l.uid.toString() === this.lot.uid.toString())
        this.reservation.lot.id = this.lot.data.id
        this.reservation.lot.uid = this.lot.data.uid
        this.reservation.lot.numero = this.lot.data.numero
        this.reservation.lot.law = this.lot.data.law
        this.reservation.lot.lawyears = this.lot.data.lawyears
        this.reservation.lot.actedprice = this.lot.data.spec.price
      } else {
        this.reservation.lot.id = ''
        this.reservation.lot.uid = ''
        this.reservation.lot.numero = 0
        this.reservation.lot.law = 0
        this.reservation.lot.lawyears = 0
        this.reservation.lot.actedprice = 0
        this.lot = {
          uid: '',
          data: {}
        }
      }
    },
    setUser: function (user) {
      if (user) {
        var data = this.users.find(u => u.username === user)
        this.reservation.user.username = user
        this.reservation.user.id = data.id
        this.reservation.user.firstname = data.meta.firstname
        this.reservation.user.lastname = data.meta.lastname
      } else {
        this.reservation.user.id = ''
        this.reservation.user.username = ''
        this.reservation.user.firstname = ''
        this.reservation.user.lastname = ''
      }
    },
    setProspect: function (numero, type) {
      if (numero > 0) {
        var data = this.prospects.find(p => Number(p.numero) === numero)
        this.reservation[type].id = data.id
        this.reservation[type].numero = numero
        this.reservation[type].firstname = data.firstname
        this.reservation[type].lastname = data.lastname
        this.reservation[type].email = data.email
        this.reservation[type].phone = data.contact.phone
      } else {
        this.reservation[type].id = ''
        this.reservation[type].numero = 0
        this.reservation[type].firstname = ''
        this.reservation[type].lastname = ''
        this.reservation[type].email = ''
        this.reservation[type].phone = ''
      }
    },
    setAssociate: function (numero) {
      this.reservation.prospect.numero2 = 0
      if (numero > 0) {
        this.reservation.prospect.numero2 = numero
      }
    },
    setStatusDate: function () {
      var date = Date.now()
      switch (this.reservation.status) {
        case 0:
          this.reservation.lot.datecancel = date
          break
        case 1:
          this.reservation.lot.dateoption = date
          break
        case 2:
          if (this.reservation.lot.datereserv === 0) this.reservation.lot.datereserv = date
          break
        case 3:
          this.reservation.lot.datefinanced = date
          break
        case 4:
          this.reservation.lot.dateacted = date
          break
      }
    },
    status: function (value) {
      return 'st' + value
    },
    todaySRU: function () {
      if (this.reservation.prospect.letter) {
        if (!this.reservation.prospect.datesru) this.reservation.prospect.datesru = Date.now()
      } else this.reservation.prospect.datesru = 0
    },
    todayNOTIF: function () {
      if (this.reservation.notary.notif) {
        if (!this.reservation.prospect.notifdate) this.reservation.notary.notifdate = Date.now()
      } else this.reservation.notary.notifdate = 0
    },
    validate: function () {
      if (!this.reservation.lot.numero) return false
      if (this.reservation.user.username === '') return false
      if (!this.reservation.prospect.numero) return false
      return true
    },
    image
  }
}
</script>
