<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-statistique"></i>STATISTIQUES</h1>
    </section>

    <StatisticsPartner />

    <StatisticsProgram />

  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import StatisticsPartner from '@/components/StatisticsPartner.vue'
import StatisticsProgram from '@/components/StatisticsProgram.vue'

export default {
  name: 'Statistics',
  components: {
    Profile,
    StatisticsPartner,
    StatisticsProgram
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
