import Prospects from '@/views/Prospects/Prospects.vue'
import ProspectDetails from '@/views/Prospects/Prospect_Details.vue'
import ProspectNew from '@/views/Prospects/Prospect_New.vue'
import ProspectEdit from '@/views/Prospects/Prospect_Edit.vue'
import ProspectDel from '@/views/Prospects/Prospect_Del.vue'

const prospects = [
  {
    path: '/prospects',
    name: 'Prospects',
    component: Prospects,
    meta: { lvl: 4 }
  },
  {
    path: '/prospect/details/:id',
    name: 'ProspectDetails',
    component: ProspectDetails,
    meta: { lvl: 4 }
  },
  {
    path: '/prospect/new',
    name: 'ProspectNew',
    component: ProspectNew,
    meta: { lvl: 4 }
  },
  {
    path: '/prospect/edit/:id',
    name: 'ProspectEdit',
    component: ProspectEdit,
    meta: { lvl: 4 }
  },
  {
    path: '/prospect/del/:id',
    name: 'ProspectDel',
    component: ProspectDel,
    meta: { lvl: 5 }
  }
]

export default prospects
