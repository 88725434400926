import DemandeNew from '@/views/Requests/Request_New.vue'
import DemandeEdit from '@/views/Requests/Request_Edit.vue'
import DemandeDel from '@/views/Requests/Request_Del.vue'

const demandes = [
  {
    path: '/demande/new',
    name: 'DemandeNew',
    component: DemandeNew,
    meta: { lvl: 4 }
  },
  {
    path: '/demande/new/lot/:id',
    name: 'DemandeNewBylot',
    component: DemandeNew,
    meta: { lvl: 4 }
  },
  {
    path: '/demande/edit/:id',
    name: 'DemandeEdit',
    component: DemandeEdit,
    meta: { lvl: 4 }
  },
  {
    path: '/demande/del/:id',
    name: 'DemandeDel',
    component: DemandeDel,
    meta: { lvl: 5 }
  }
]

export default demandes
