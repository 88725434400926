<template>
  <ul class="statusColors" v-if="lots.length">
    <li>{{ quantity[0] }} <i style="color:var(--grey)">⬤</i> Disponible</li>
    <li>{{ quantity[1] }} <i style="color:var(--yellow)">⬤</i> Optionné</li>
    <li>{{ quantity[2] }} <i style="color:var(--reserved)">⬤</i> Réservé</li>
    <li>{{ quantity[3] }} <i style="color:var(--financed)">⬤</i> Financé</li>
    <li>{{ quantity[4] }} <i style="color:var(--acted)">⬤</i> Acté</li>
    <li>-</li>
    <li>{{ lots.length }} <i style="color:var(--blue-light)">⬤</i> Total</li>
  </ul>
</template>

<script>
export default {
  name: 'LotsStatusBar',
  props: {
    numero: Number
  },
  data () {
    return {
    }
  },
  computed: {
    lots: function () {
      return this.$store.getters.get_lots_by_program_num(this.numero)
    },
    quantity: function () {
      var quantity = []
      for (let i = 0; i < 5; i++) { quantity.push(this.lots.filter(l => Number(l.status) === i).length) }
      return quantity
    }
  }
}
</script>
