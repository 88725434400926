<template>
  <section class="container">
    <section class="city">{{ prog.location.city || 'Ville: n/a' | uppercase}}</section>
    <span v-if="lvl > 2" class="status">{{ prog.status | prog_status }}</span>
    <router-link tag="section" :to="{ path: '/programme/details/'+prog.numero }" class="image">
      <img alt="" :src="image(prog.thumbnail)" />
    </router-link>
    <span class="law"><em v-for="(law, l) in prog.laws" :key="l" ><i v-if="l > 0">,</i> {{ config.laws[law] }}</em> <i v-if="prog.laws[0] === 0">dispositif</i></span>
    <section class="inside">
      <small v-if="lvl > 3">
        <router-link tag="i" :to="{ path: '/programme/details/'+prog.numero }" class="icon-see"></router-link>&nbsp;
        <router-link tag="i" :to="{ path: '/programme/edit/'+prog.numero }" class="icon-edit"></router-link>&nbsp;
        <router-link tag="i" :to="{ path: '/programme/del/'+prog.numero }" class="icon-delete"></router-link>
      </small>
      <span class="title">{{ prog.name }}</span>
      <span class="location"><i class="icon-location"></i> À {{ prog.location.city || 'Ville: n/a ' }}, {{ prog.location.district }}</span>
      <ul>
        <li>DU<span>{{ prog.lot.type.min | type }} au {{ prog.lot.type.max | type }}</span></li>
        <li>DE<span>{{ parseInt(prog.lot.size.min) }}m² à {{ parseInt(prog.lot.size.max) }}m²</span></li>
        <li>À PARTIR DE<span>{{ prog.lot.price.min.toLocaleString() }} €</span></li>
      </ul>
      <p>{{ prog.summary }}</p>
      <Bar v-if="lvl > 2" v-bind:numero="prog.numero" />
      <router-link tag="button" :to="{ path: '/programme/details/'+prog.numero }">EN SAVOIR PLUS</router-link>
    </section>
  </section>
</template>

<script>
import Bar from '@/components/ProgrammesBar.vue'
import { image } from '@/helpers.js'

export default {
  name: 'ProgrammesCard',
  components: {
    Bar
  },
  props: {
    prog: Object
  },
  data () {
    return {
    }
  },
  computed: {
    lvl: function () {
      return this.$store.getters.lvl
    },
    config: function () {
      return this.$store.getters.get_settings()
    }
  },
  methods: {
    image
  }
}
</script>
