<template>
  <section class="table by8">
    <ul>
      <li>
        <label>TYPE</label>
        <label><i style="color:var(--grey)">⬤</i> {{ quantityStatus[0] }}</label>
        <label><i style="color:var(--yellow)">⬤</i> {{ quantityStatus[1] }}</label>
        <label><i style="color:var(--reserved)">⬤</i> {{ quantityStatus[2] }}</label>
        <label><i style="color:var(--financed)">⬤</i> {{ quantityStatus[3] }}</label>
        <label><i style="color:var(--acted)">⬤</i> {{ quantityStatus[4] }}</label>
        <label>PRIX</label>
        <label>P.C</label>
      </li>
    </ul>
    <ul>
      <li v-for="(t) in types" :value="t" :key="t">
        <span v-if="quantity[t-1]"><i class="badge">{{ t-1 | type }}</i></span>
        <span v-if="quantity[t-1]">{{ StatsNumb(t-1, 0) }}<small>{{ StatsPrice(t-1, 0)}}</small></span>
        <span v-if="quantity[t-1]">{{ StatsNumb(t-1, 1) }}<small>{{ StatsPrice(t-1, 1)}}</small></span>
        <span v-if="quantity[t-1]">{{ StatsNumb(t-1, 2) }}<small>{{ StatsPrice(t-1, 2)}}</small></span>
        <span v-if="quantity[t-1]">{{ StatsNumb(t-1, 3) }}<small>{{ StatsPrice(t-1, 3)}}</small></span>
        <span v-if="quantity[t-1]">{{ StatsNumb(t-1, 4) }}<small>{{ StatsPrice(t-1, 4)}}</small></span>
        <span v-if="quantity[t-1]">{{price[t-1].toLocaleString()}} €</span>
        <span v-if="quantity[t-1]">{{ percentPrice[t-1] }} %</span>
      </li>
      <li>
        <hr/>
        <hr/>
        <hr/>
        <hr/>
        <hr/>
        <hr/>
        <hr/>
        <hr/>
      </li>
      <li>
        <span><i class="badge">TOTAL</i></span>
        <span>{{ StatsTotal(0)}} <small>{{ StatsTotalPercent(0) }} </small></span>
        <span>{{ StatsTotal(1)}} <small>{{ StatsTotalPercent(1) }}</small></span>
        <span>{{ StatsTotal(2)}} <small>{{ StatsTotalPercent(2) }}</small></span>
        <span>{{ StatsTotal(3)}} <small>{{ StatsTotalPercent(3) }}</small></span>
        <span>{{ StatsTotal(4)}} <small>{{ StatsTotalPercent(4) }}</small></span>
        <span>{{ StatsTotal(-1)}}</span>
        <span>100 %</span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'StatisticsProgramFull',
  props: {
    numero: Number
  },
  data () {
    return {
      types: 13
    }
  },
  computed: {
    lots: function () {
      return this.$store.getters.get_lots_by_program_num(this.numero)
    },
    total: function () {
      var total = 0
      this.lots.forEach(l => {
        total = total + l.spec.price
      })
      return total
    },
    quantity: function () {
      var quantity = []
      for (let i = 0; i < this.types; i++) {
        quantity.push(this.lots.filter(l => Number(l.spec.type) === i).length)
      }
      return quantity
    },
    quantityStatus: function () {
      var quantity = []
      for (let i = 0; i < 5; i++) {
        quantity.push(this.lots.filter(l => Number(l.status) === i).length)
      }
      return quantity
    },
    lotsbytype: function () {
      var lotsbytype = []
      for (let i = 0; i < this.types; i++) {
        lotsbytype.push(this.lots.filter(l => Number(l.spec.type) === i))
      }
      return lotsbytype
    },
    price: function () {
      var price = []
      this.lotsbytype.forEach(q => {
        if (q.length > 0) {
          var calc = 0
          q.forEach(l => {
            calc = calc + l.spec.price
          })
          price.push(calc)
        } else price.push(0)
      })
      return price
    },
    percent: function () {
      var percent = []
      this.quantity.forEach(q => {
        percent.push(Number(((q / this.lots.length) * 100).toFixed(2)))
      })
      if (percent.length < 1) return ''
      return percent
    },
    percentPrice: function () {
      var percentPrice = []
      this.price.forEach(p => {
        percentPrice.push(Number(((p / this.total) * 100).toFixed(2)))
      })
      return percentPrice
    }
  },
  methods: {
    StatsNumb: function (type, status) {
      var lots = this.lotsbytype[type].filter(l => Number(l.status) === status)
      return lots.length
    },
    StatsPrice: function (type, status) {
      var lots = this.lotsbytype[type].filter(l => Number(l.status) === status)
      var calc = 0
      lots.forEach(l => {
        calc = calc + l.spec.price
      })
      if (calc) return calc.toLocaleString() + ' €'
      return ''
    },
    StatsTotal: function (status) {
      var lots = this.lots
      if (status > -1) lots = this.lots.filter(l => Number(l.status) === status)
      var calc = 0
      lots.forEach(l => {
        calc = calc + l.spec.price
      })
      if (calc) return calc.toLocaleString() + ' €'
      return '0'
    },
    StatsTotalPercent: function (status) {
      var lots = this.lots.filter(l => Number(l.status) === status)
      var totalStatus = 0
      lots.forEach(l => {
        totalStatus = totalStatus + l.spec.price
      })
      var percent = 0
      percent = Number(((totalStatus / this.total) * 100).toFixed(2))
      if (percent) return percent + ' %'
      return ''
    }
  }
}
</script>
