<template>
  <section class="grid">
    <section class="card">
      <h2>{{program.name}}</h2>
      <table class="mailing" cellpadding="0" cellspacing="0">
        <tr align="center">
          <td class="bold">BAT</td>
          <td class="bold">NIV</td>
          <td class="bold">N°</td>
          <td class="bold">TYP</td>
          <td class="bold">SURF</td>
          <td class="bold">BAL</td>
          <td class="bold">VAR</td>
          <td class="bold">TER</td>
          <td class="bold">TOT</td>
          <td class="bold">JAR</td>
          <td class="bold">PRIX</td>
          <td class="bold">LOYER</td>
          <td class="bold">STAT</td>
        </tr>
        <tr align="center" v-for="(lot, l) in lots" :key="l" >
          <td>{{ lot.spec.building }}</td>
          <td>{{ lot.spec.floor | floor }}</td>
          <td>{{ lot.numero }}</td>
          <td>{{ lot.spec.type | type }}</td>
          <td>{{ lot.spec.size | square }}</td>
          <td>{{ lot.spec.balcony | square }}</td>
          <td>{{ lot.spec.varangue | square }}</td>
          <td>{{ lot.spec.terrace | square }}</td>
          <td>{{ lot.spec.total | square }}</td>
          <td>{{ lot.spec.garden | square }}</td>
          <td>{{ lot.spec.price.toLocaleString() }}€</td>
          <td>{{ lot.spec.loan.toLocaleString() }}€</td>
          <td class="status st0 " :class="{'st1' : lot.status > 0 }">
            {{ status(lot.status) }}
          </td>
        </tr>
      </table>
    </section>
  </section>
</template>

<script>
export default {
  name: 'MailingsTable',
  props: {
    program: Object
  },
  data () {
    return {
    }
  },
  computed: {
    lots: function () {
      const lots = this.$store.getters.get_lots_by_program_num(this.program.numero)
      return lots.filter(l => l.status < 2).slice().sort(function (a, b) {
        return (a.uid > b.uid) ? 1 : -1
      })
    }
  },
  methods: {
    status: function (value) {
      if (value > 0) return 'Optionné'
      return 'Disponible'
    }
  }
}
</script>
