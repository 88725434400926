<template>
  <section class="reservations table">
    <ul>
      <li class="stickyLabel">
        <label class="sort reset" @click="reset()" title="Programme">PROG</label>
        <label class="sort" :class="classActive('uid')" @click="sortby('uid', 'lot')" title="Numéro du lot">LOT</label>
        <label title="Dispositif de défiscalisation">LOI</label>
        <label title="Partenaire">PART</label>
        <label title="Client">CLI</label>
        <label title="Avenant #1">AV 1</label>
        <label title="Avenant #2">AV 2</label>
        <label title="Caution">CAUT</label>
        <label title="Lettre SRU">SRU</label>
        <label title="Accusé de réception">AR</label>
        <label title="Notaire">NOT</label>
        <label title="Dossier">DOSS</label>
        <label title="Payement cash">CASH</label>
        <label title="Notification">NOTIF</label>
        <label title="Banque">BANQ</label>
        <label title="Accord de pret">ADP</label>
        <label title="Offre du pret">ODP</label>
        <label title="Acte de vente">ADV</label>
        <label title="Garantie">GAR</label>
        <label title="Status">STAT</label>
        <label>ACT</label>
      </li>
    </ul>
    <ul>
      <li class="card td" v-for="(reserv, r) in sorted" :key="r" v-on:dblclick="router(reserv.numero)" :class="color(reserv.status)">
        <span class="bold">
          <router-link :to="{ path: '/programme/details/' + reserv.program.numero }">
          {{ short(reserv.program.numero) || reserv.program.numero | capitalize }}
          </router-link>
        </span>
        <span>
          <router-link :to="{ path: '/lot/details/'+reserv.lot.uid }">
            {{ lot(reserv.lot.uid).spec.building || '' }}{{ reserv.lot.numero }}
          </router-link>
        </span>
        <span class="bold">
          {{ config.laws[reserv.lot.law] }}
          <sup class="small" v-if="reserv.lot.lawyears"> {{ reserv.lot.lawyears | lawyears }}</sup>
        </span>
        <span :title="user(reserv.user.username).lastname + ' ' + user(reserv.user.username).firstname">
          <router-link :to="{ path: '/utilisateur/details/'+reserv.user.username }">
            {{ user(reserv.user.username).lastname || 'n/a' }}
          </router-link>
        </span>
        <span :title="prospect(reserv.prospect.numero).lastname+' '+prospect(reserv.prospect.numero).firstname">
          <router-link :to="{ path: '/prospect/details/'+reserv.prospect.numero }">
            {{ prospect(reserv.prospect.numero).lastname || 'n/a'  }}
          </router-link>
          <sup :title="prospect(reserv.prospect.numero2).lastname+' '+prospect(reserv.prospect.numero2).firstname" v-if="reserv.prospect.numero2">
            <router-link :to="{ path: '/prospect/details/'+reserv.prospect.numero2 }">²</router-link>
          </sup>
        </span>
        <span class="badge" ><i v-if="reserv.prospect.endorsement1" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span class="badge" ><i v-if="reserv.prospect.endorsement2" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span class="badge" ><i v-if="reserv.prospect.deposit" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span class="badge" ><i v-if="reserv.prospect.letter" :title="date(reserv.prospect.datesru)" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span class="badge" ><i v-if="reserv.prospect.acknowledgment" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span v-if="reserv.notary.numero" :title="prospect(reserv.notary.numero).lastname+' '+prospect(reserv.notary.numero).firstname" >
          <router-link :to="{ path: '/prospect/details/'+reserv.notary.numero }">
            {{ prospect(reserv.notary.numero).lastname || 'n/a' }}
          </router-link>
        </span>
        <span v-else>{{ reserv.notary.lastname || 'n/a' }}</span>
        <span class="badge" ><i v-if="reserv.notary.send" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span class="badge" ><i v-if="reserv.notary.cash" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span class="badge" ><i v-if="reserv.notary.notif" class="round green" :title="date(reserv.notary.notifdate)">✔</i><i v-else class="round red">✘</i></span>
        <span v-if="reserv.bank.numero">
          <router-link :to="{ path: '/prospect/details/'+reserv.bank.numero }">
            {{ reserv.bank.lastname || 'n/a' }} <div class="verysmall" v-if="prospect(reserv.bank.numero).contact.city !== ''">{{ prospect(reserv.bank.numero).contact.city }}</div>
          </router-link>
        </span>
        <span v-else>{{ reserv.bank.lastname || 'n/a' }}</span>
        <span class="badge" ><i v-if="reserv.bank.loan.agreement" :title="date(reserv.bank.loan.agreementdate)" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span class="badge" ><i v-if="reserv.bank.loan.offer" :title="date(reserv.bank.loan.offerdate)" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span class="badge" ><i v-if="reserv.notary.sign" :title="date(reserv.bank.loan.signdate)" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span> {{ reserv.bank.loan.guarantee }}</span>
        <span class="badge" :class="status(reserv.status)">
          {{ reserv.status | reservation_status }}
          <sup class="" :class="{'expired' : dayLeft(reserv.created) < 0, 'expired' : dayLeft(reserv.created) === 'exp'}" v-if="reserv.status === 1">{{ dayLeft(reserv.created) }}</sup>
        </span>
        <span v-if="lvl > 3">
          <router-link tag="i" :to="{ path: '/reservation/edit/' + reserv.numero }" class="icon-edit"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/reservation/del/' + reserv.numero }" class="icon-delete"></router-link>
        </span>
      </li>
    </ul>
    <p v-if="reservations.length === 0" class="card nodata">Aucune donnée n’est disponible</p>
    <p class="loadmore" v-if="limit < reservations.length">
      <button @click="more()">Afficher plus</button>
    </p>
  </section>
</template>

<script>

export default {
  name: 'reservationsList',
  props: {
    reservations: Array
  },
  data () {
    return {
      dir: true,
      nested: 'lot',
      sort: 'uid',
      limit: 50
    }
  },
  computed: {
    sorted: function () {
      var dir = this.dir
      var sort = this.sort
      var nest = this.nested
      return this.reservations.slice().sort(function (a, b) {
        if (nest === '') {
          if (dir) return (a[sort] > b[sort]) ? 1 : -1
          return (a[sort] < b[sort]) ? 1 : -1
        } else {
          if (dir) return (a[nest][sort] > b[nest][sort]) ? 1 : -1
          return (a[nest][sort] < b[nest][sort]) ? 1 : -1
        }
      }).slice(0, this.limit)
    },
    programs: function () {
      return this.$store.getters.get_programs()
    },
    lots: function () {
      return this.$store.getters.get_lots()
    },
    users: function () {
      return this.$store.getters.get_users()
    },
    prospects: function () {
      return this.$store.getters.get_prospects()
    },
    meta: function () {
      return this.$store.getters.meta
    },
    lvl: function () {
      return this.$store.getters.lvl
    },
    config: function () {
      return this.$store.getters.get_settings()
    }
  },
  methods: {
    short: function (value) {
      var program = this.programs.find(p => Number(p.numero) === Number(value))
      return program.shortname
    },
    status: function (value) {
      return 'st' + value
    },
    color: function (value) {
      if (this.meta.menu.linecolor) {
        return 'cl' + value
      }
    },
    sortby: function (value, nest = '') {
      if (this.sort === value) {
        if (this.dir) this.dir = false
        else this.dir = true
      } else {
        this.sort = value
        this.nested = nest
      }
    },
    reset: function () {
      this.sort = 'created'
      this.dir = true
    },
    classActive: function (value) {
      var classe = ''
      if (this.sort === value) classe = 'active'
      if (this.dir === false) classe += ' dir'
      return classe
    },
    router: function (numero) {
      if (this.lvl > 3) this.$router.push('/reservation/edit/' + numero)
    },
    dayLeft: function (date) {
      const today = Date.now()
      const diff = today - date
      var days = 7 - (diff / (1000 * 3600 * 24))
      if (days < -15) return 'exp'
      return parseFloat(days).toFixed(0)
    },
    more: function () {
      this.limit += 50
    },
    lot: function (uid) {
      var lot = this.lots.find(el => el.uid === uid)
      if (lot) return lot
      return {
        spec: {
          building: 'n/a',
          type: 0,
          size: 'n/a',
          price: 'n/a'
        }
      }
    },
    date: function (value) {
      if (value === 0) return 'n/a'
      var shortDate = new Date(value)
      var options = { year: '2-digit', month: 'numeric', day: 'numeric' }
      return shortDate.toLocaleDateString('fr-FR', options)
    },
    user: function (user) {
      var u = this.users.find(el => el.username === user)
      if (u) return u.meta
      // deleted from user database
      return {
        lastname: 'n/a',
        firstname: ''
      }
    },
    prospect: function (prosp) {
      var p = this.prospects.find(el => el.numero === prosp)
      if (p) return p
      return {
        lastname: '',
        firstname: ''
      }
    }
  }
}
</script>
