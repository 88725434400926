<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-error"></i>ERREUR 404</h1>
    </section>
    <p>Page Not Found</p>
    <button @click="back()">&laquo; revenir en arrière</button>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'Error404',
  components: {
    Profile
  },
  data () {
    return {
    }
  },
  methods: {
    back: function () {
      this.$router.go(-1)
    }
  }
}
</script>
