<template>
  <section class="dashboard grid">

    <section class="card total">
      <small>Programmes en commercialisations</small>
      <p>
        <em>{{ programs_count }}</em>
        <span class="blue"><i class="icon-programme"></i></span>
      </p>
    </section>

    <section class="card total">
      <small>Lots disponibles à la vente</small>
      <p>
        <em>{{ lots_count }}</em>
        <span class="grey"><i class="icon-lot"></i></span>
      </p>
    </section>

    <section class="card total">
      <small>Options cette semaine</small>
      <p>
        <em>{{ reservs_count }}</em>
        <span class="yellow"><i class="icon-reserv"></i></span>
      </p>
    </section>

    <!--
    <section class="card total">
      <small>Nombre total de prospects</small>
      <p>
        <em>{{ prospects_count }}</em>
        <span class="yellow"><i class="icon-prospect"></i></span>
      </p>
    </section>
    -->

  </section>
</template>

<script>
export default {
  name: 'DashboardStatsTraffic',
  data () {
    return {
    }
  },
  computed: {
    programs_count: function () {
      var progs = this.$store.getters.get_programs().filter(p => p.status === 1)
      return Object.keys(progs).length
    },
    lots_count: function () {
      var progs = this.$store.getters.get_programs().filter(p => p.status === 1)
      var lots = this.$store.getters.get_lots().filter(l => l.status === 0).filter((l) => {
        return progs.some((p) => {
          return p.numero === l.program.numero
        })
      })
      return Object.keys(lots).length
    },
    reservs_count: function () {
      // find the last monday of the week
      var d = new Date()
      d.setHours(0, 0, 0, 0)
      var prevMonday = d.setDate(d.getDate() - (d.getDay() + 6) % 7)
      // filter by date (this week) and program in commercialization
      var progs = this.$store.getters.get_programs().filter(p => p.status === 1)
      var reservs = this.$store.getters.get_reservations().filter(p => p.created > prevMonday).filter((l) => {
        return progs.some((p) => {
          return p.numero === l.program.numero
        })
      })
      return Object.keys(reservs).length
    },
    prospects_count: function () {
      var prospects = this.$store.getters.get_prospects()
      return Object.keys(prospects).length
    }
  }
}
</script>
