<template>
  <section class="interface">
      <Profile/>
      <section class="title">
        <h1><i class="icon-dashboard"></i>DASHBOARD</h1>
      </section>
      <section class="content welcome">
        <em class="welcome">Bienvenue à vous <i v-if="meta.firstname">{{ meta.firstname }} {{ meta.lastname }} </i></em>
        <time :datetime="today"><i class="icon-calendar"></i> {{ today | longDate }}</time>
      </section>
    <div v-if="isAdmin">

      <DasboardStatsTotal />

      <DasboardStatsTraffic />

      <section class="title">
        <h1><i class="icon-alert"></i> ALERTES</h1>
      </section>

      <ReservationsList :reservations="expired.slice(0, 5)" />

      <p class="loadmore" v-if="expired.length > 5">
        <button @click="goto()">Afficher plus</button>
      </p>

    </div>

    <DasboardPartner v-else />

  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import DasboardStatsTotal from '@/components/DasboardStatsTotal.vue'
import DasboardStatsTraffic from '@/components/DasboardStatsTraffic.vue'
import DasboardPartner from '@/components/DasboardPartner.vue'
import ReservationsList from '@/components/ReservationsList.vue'

export default {
  name: 'Dashboard',
  components: {
    Profile,
    DasboardStatsTotal,
    DasboardStatsTraffic,
    DasboardPartner,
    ReservationsList
  },
  data () {
    return {
    }
  },
  computed: {
    meta: function () {
      return this.$store.getters.meta
    },
    today: function () {
      return Date.now()
    },
    expired: function () {
      return this.$store.getters.get_reservations_by_expired()
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin
    }
  },
  created () {
  },
  methods: {
    goto: function () {
      this.$router.push('/reservations/expired')
    }
  }
}
</script>
