<template>
  <section>
    <span v-if="username !== ''">
      <em class="badge">
        <router-link :to="{ path: '/utilisateur/details/'+ username }">
          {{ data.lastname || username }} {{ data.firstname || '' }}
        </router-link>
      </em>
      <u class="icon-delete" v-on:click="username = '', choose()"></u>
    </span>
    <section v-else>
      <ul class="filter">
        <li class="filtersearch">
          <input v-model="search" type="text" placeholder="Recherche"/><i class="icon-search"></i>
        </li>
      </ul>
      <small class="normal verysmall">Total: {{ users.length }} - Résultat: {{ userSearch.length }}</small>
      <ul class="scroll finder">
        <li :class="{'selected': username === u.username}" v-for="u in userSearch.slice(0, this.limit)" :key="'U' + u.id"
        v-on:click="change(u), choose()">
          {{ u.meta.lastname || u.username }} {{ u.meta.firstname }}
        </li>
        <li class="more" v-if="limit < userSearch.length" v-on:click="more()">+ Afficher plus</li>
      </ul>
      <small>
        <router-link to="/utilisateur/new">Ajouter nouveau</router-link>
      </small>
    </section>
  </section>
</template>

<script>

export default {
  name: 'findUser',
  props: {
    user: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      search: '',
      username: this.user,
      data: {
        firstname: '',
        lastname: ''
      },
      limit: 10
    }
  },
  computed: {
    users: function () {
      return this.$store.getters.get_users()
    },
    userSearch: function () {
      if (this.search) {
        return this.users.filter((item) => {
          return this.search.toLowerCase().split(' ').every(v =>
            item.username.toLowerCase().includes(v) ||
            item.meta.firstname.toLowerCase().includes(v) ||
            item.meta.lastname.toLowerCase().includes(v) ||
            item.meta.email.toLowerCase().includes(v)
          )
        })
      }
      return this.users
    }
  },
  created () {
    // set the user firstname & lastname
    if (this.username !== '') {
      var data = this.users.find(u => u.username === this.username)
      this.data.firstname = data.meta.firstname
      this.data.lastname = data.meta.lastname
    }
  },
  methods: {
    change: function (user) {
      this.username = user.username
      this.data.firstname = user.meta.firstname
      this.data.lastname = user.meta.lastname
    },
    choose: function (event) {
      this.$emit('send', this.username)
    },
    more: function () {
      this.limit += 10
    }
  }
}
</script>
