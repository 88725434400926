<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-account"></i>MON PROFIL</h1>
    </section>
    <section class="grid add">
      <section class="card new">
          <h2>Identité</h2>
          <section>
            <p>
              <span class="badge">Genre:</span> {{ meta.gender | gender }}
            </p>
            <p>
              <span class="badge">Nom:</span> {{ meta.lastname || 'n/a' }}
            </p>
            <p>
              <span class="badge">Prénom:</span> {{ meta.firstname || 'n/a' }}
            </p>
            <p>
              <span class="badge">Email:</span> {{ meta.email || 'n/a' }}
            </p>
            <p>
              <span class="badge">Date de naissance:</span> {{ meta.birthday | longDate }}
            </p>
          </section>
      </section>
      <section class="card new">
          <h2>Contact</h2>
          <section>
            <p>
              <span class="badge">Adresse:</span> {{ meta.contact.address || 'n/a' }}
            </p>
            <p>
              <span class="badge">Ville:</span> {{ meta.contact.city || 'n/a' }}
            </p>
            <p>
              <span class="badge">Code postal:</span> {{ meta.contact.zipcode || 'n/a' }}
            </p>
            <p>
              <span class="badge">Téléphone:</span> {{ meta.contact.phone || 'n/a' | phone }}
            </p>
            <p>
              <span class="badge">Mobile:</span> {{ meta.contact.mobile || 'n/a' | phone }}
            </p>
          </section>
      </section>
      <section class="card new">
          <h2>Préférences</h2>
          <section>
            <p>
              <span class="badge">Palette de couleur:</span> <i v-if="meta.menu.color" class="round green">✔</i><i v-else class="round red">✘</i>
            </p>
            <p>
              <span class="badge">Remplissage couleur ligne:</span> <i v-if="meta.menu.linecolor" class="round green">✔</i><i v-else class="round red">✘</i>
            </p>
            <p>
              <span class="badge">Affichage compact:</span> <i v-if="meta.menu.display" class="round green">✔</i><i v-else class="round red">✘</i>
            </p>
            <p>
              <span class="badge">Menu icône:</span> <i v-if="meta.menu.side" class="round green">✔</i><i v-else class="round red">✘</i>
            </p>
            <p>
              <router-link tag="button" to="/profile/edit">MODIFIER MES INFORMATIONS</router-link>
            </p>
          </section>
      </section>
    </section>
    <!--
    <section class="grid">
      <section class="card">
        <p>{{ uuid }}</p>
      </section>
    </section>
    -->
    <section class="card">
      <h2>Mes réservations</h2>
      <section class="filter">
        <ul>
          <li class="filterchoice">
          <select v-model="filtered">
            <option value="-1">Status: Tous</option>
            <option v-for="(status, s) in settings.reservation.status" :value="s" :key="s">{{ status }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        </ul>
        <ul></ul>
      </section>
      <br/>
      <AccountReservationsList :reservations="reservStatus" />
    </section>
    <!--
    <section class="card"><small>Data.json</small>
      <pre class="scroll">
        {{ meta }}
        {{ logs }}
        {{ ip }}
      </pre>
    </section>
    -->
  </section>
</template>

<script>
import AccountReservationsList from '@/components/AccountReservationsList.vue'
import Profile from '@/components/Profile.vue'
import settings from '@/settings.js'

export default {
  name: 'Profil',
  components: {
    AccountReservationsList,
    Profile
  },
  data () {
    return {
      settings,
      filtered: -1,
      ip: ''
    }
  },
  computed: {
    meta: function () {
      return this.$store.getters.meta
    },
    me: function () {
      return this.$store.getters.me
    },
    logs: function () {
      return this.$store.getters.status.logs
    },
    reservations: function () {
      return this.$store.getters.get_reservations().filter(el => el.user.username === this.me)
    },
    reservStatus: function () {
      var fil = Number(this.filtered)
      // all status
      if (fil === -1) return this.reservations
      // filtered status
      return this.reservations.filter(el => el.status === fil)
    },
    uuid: function () {
      return this.$store.getters.get_uuid()
    }
  },
  created () {
    /*
    fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
        this.ip = ip
      })
    */
  }
}
</script>
