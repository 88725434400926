import Lots from '@/views/Lots/Lots.vue'
import LotDetails from '@/views/Lots/Lot_Details.vue'
import LotNew from '@/views/Lots/Lot_New.vue'
import LotImport from '@/views/Lots/Lot_Import.vue'
import LotEdit from '@/views/Lots/Lot_Edit.vue'
import LotDel from '@/views/Lots/Lot_Del.vue'

const lots = [
  {
    path: '/lots',
    name: 'Lots',
    component: Lots,
    meta: { lvl: 3 }
  },
  {
    path: '/lot/details/:id',
    name: 'LotDetails',
    component: LotDetails,
    meta: { lvl: 3 }
  },
  {
    path: '/lot/new',
    name: 'LotNew',
    component: LotNew,
    meta: { lvl: 4 }
  },
  {
    path: '/lot/import',
    name: 'LotImport',
    component: LotImport,
    meta: { lvl: 4 }
  },
  {
    path: '/lot/new/program/:id',
    name: 'lotNewByProgram',
    component: LotNew,
    meta: { lvl: 4 }
  },
  {
    path: '/lot/duplicate/:id',
    name: 'LotDuplicate',
    component: LotNew,
    meta: { lvl: 4 }
  },
  {
    path: '/lot/edit/:id',
    name: 'LotEdit',
    component: LotEdit,
    meta: { lvl: 4 }
  },
  {
    path: '/lot/del/:id',
    name: 'LotDel',
    component: LotDel,
    meta: { lvl: 5 }
  }
]

export default lots
