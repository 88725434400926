<template>
  <section class="interface" v-if="show">
    <Profile/>
    <section class="title">
      <h1><i class="icon-utilisateur"></i>SUPPRESSION PROSPECT</h1>
    </section>
    <form @submit.prevent="del()">
    <section class="grid">
      <section class="card">
        <h2>Descriptions</h2>
        <section>
          <p>
            <label class="badge">Nom:</label> {{ prospect.firstname }} {{ prospect.lastname }}
          </p>
          <p>
            <label class="badge">Email:</label> {{ prospect.email }}
          </p>
          <p>
            <label class="badge">liste:</label> {{ prospect.list | prospect_list }}
          </p>
          <p>
            <label class="badge">Ville:</label> {{ prospect.contact.city }}
          </p>
          <p>
            <label class="badge">Tel:</label> {{ prospect.contact.phone | phone }}
          </p>
        </section>
      </section>
      <section class="card">
        <p>
          <span class="badge red">Avertissement</span>
        </p>
        <p>
          <small>La suppression entraîne la destruction totale et définitive du prospect.</small>
        </p>
      </section>
      <section class="card">
        <p>
          <button type="submit">SUPPRIMER</button>
        </p>
      </section>
      <section class="card"><small>Data.json</small>
        <pre class="scroll">{{ prospect }}</pre>
      </section>
    </section>
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'prospectDel',
  components: {
    Profile
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    prospect: function () {
      return this.$store.getters.get_prospect_by_numero(this.$route.params.id)
    }
  },
  methods: {
    del: function () {
      var prospect = this.prospect
      this.show = false
      this.$store.dispatch('removeProspect', prospect)
        .then(() => {
          this.$router.push('/prospects')
        })
        .catch()
    }
  }
}
</script>
