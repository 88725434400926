import settings from '@/settings.js'
import Vue from 'vue'

Vue.filter('longDate', function (value) {
  if (value === 0 || value === undefined || value === '') return 'n/a'
  var longDate = new Date(value)
  var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
  return longDate.toLocaleDateString('fr-FR', options)
})

Vue.filter('shortDate', function (value) {
  if (value === 0 || value === undefined || value === '') return 'n/a'
  var shortDate = new Date(value)
  var options = { year: '2-digit', month: 'numeric', day: 'numeric' }
  return shortDate.toLocaleDateString('fr-FR', options)
})

Vue.filter('timeDate', function (value) {
  if (value === 0 || value === undefined || value === '') return 'n/a'
  var shortDate = new Date(value)
  var options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
  return shortDate.toLocaleDateString('fr-FR', options)
})

Vue.filter('timeOnly', function (value) {
  if (value === 0 || value === undefined || value === '') return 'n/a'
  var shortDate = new Date(value)
  var options = { hour: 'numeric', minute: 'numeric' }
  return shortDate.toLocaleTimeString('fr-FR', options)
})

Vue.filter('yearDate', function (value) {
  var yearDate = new Date(value)
  var options = { year: 'numeric' }
  return yearDate.toLocaleDateString('fr-FR', options)
})

Vue.filter('uppercase', function (value) {
  return value.toString().toUpperCase()
})

Vue.filter('capitalize', function (value) {
  const lower = value.toLowerCase()
  return value.toString().charAt(0).toUpperCase() + lower.slice(1)
})

// universal config filter
Vue.filter('switch', function (value, model) {
  return model[value]
})

// old settings filters
Vue.filter('prog_status', function (value) {
  var stat = settings.program.status
  return stat[value]
})

Vue.filter('prog_status_short', function (value) {
  var stat = settings.program.status
  return stat[value].slice(0, 6)
})

Vue.filter('lot_status', function (value) {
  var stat = settings.lot.status
  return stat[value]
})

/*
Vue.filter('law', function (value) {
  var law = settings.lot.law
  return law[value]
})
*/

Vue.filter('lawyears', function (value) {
  var years = settings.lot.lawyears
  return years[value]
})

Vue.filter('type', function (value) {
  var typ = settings.lot.type
  return typ[value]
})

Vue.filter('story', function (value) {
  var st = settings.lot.st
  return st[value]
})

Vue.filter('floor', function (value) {
  if (value === 0) return 'Rdc'
  else if (value === 1) return '1er'
  else return '' + value + 'ème'
})

Vue.filter('square', function (value) {
  if (value === 0) return 'non'
  else return '' + Number(value).toFixed(2) + 'm²'
})

Vue.filter('option', function (value) {
  if (value === '' || value === undefined || value.length === 0) return 'non'
  else return '' + value
})

Vue.filter('exposure', function (value) {
  var exp = settings.lot.exposure
  return exp[value]
})

Vue.filter('exp', function (value) {
  var exp = settings.lot.exp
  return exp[value]
})

Vue.filter('role', function (value) {
  var role = settings.user.role
  return role[value]
})

Vue.filter('gender', function (value) {
  var gender = settings.user.gender
  return gender[value]
})

Vue.filter('request_status', function (value) {
  var stat = settings.request.status
  return stat[value]
})

Vue.filter('reservation_status', function (value) {
  var stat = settings.reservation.status
  return stat[value]
})

Vue.filter('phone', function (value) {
  // cleaning the value from aditional useless char
  value = value.toString().split('#').join('').split('.').join('').split(' ').join('').split('+').join('')
  if (value !== 'n/a') {
    var numero = ''
    for (var i = 0; i < value.length; i += 2) {
      numero += value.substr(i, 2) + ' '
    }
    return numero
  }
  return value
})

Vue.filter('initial', function (value) {
  return value.split(' ').map((n) => n[0]).join('.')
})

Vue.filter('prospect_list', function (value) {
  var list = settings.prospect.list
  return list[value]
})

Vue.filter('shortEmail', function (email) {
  if (email.length > 15) {
    var name = email.substring(0, email.lastIndexOf('@'))
    var domain = email.substring(email.lastIndexOf('@') + 1)
    if (name.length > 6) name = name.slice(0, 4) + '..'
    if (domain.length > 10) domain = '..' + domain.slice(-8)
    return name + '@' + domain
  }
  return email
})
