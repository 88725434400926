<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-programme"></i>DÉTAILS PROGRAMME</h1>
    </section>
    <section class="grid program">
      <section class="card grid">
          <section class="gallery">
            <section class="city">{{ program.location.city | uppercase }}</section>
            <span class="status">{{ program.status | prog_status }}</span>
            <section class="images">
              <img alt="" v-for="(img, i) in program.images" :class="{'active': gallery === i}" :key="i" :src="image(img)" />
              <ul>
                <li @click="gallery = 0" :class="{'active': gallery === 0}">*</li>
                <li @click="gallery = 1" :class="{'active': gallery === 1}">*</li>
                <li @click="gallery = 2" :class="{'active': gallery === 2}">*</li>
                <li @click="gallery = 3" :class="{'active': gallery === 3}">*</li>
              </ul>
            </section>
          </section>
          <section class="infos">
            <small v-if="isAdmin">
              <a class="icotext" :href="this.settings.root + '/xml/' + program.id"><i class="">&lsaquo;/&rsaquo;</i>Xml</a>&nbsp;
              <u class="icotext" @click="formatCSV()"><i class="">🗋</i>Export</u>&nbsp;
              <router-link v-if="lvl > 3" class="icotext" :to="{ path: '/mailing/'+program.numero}"><i class="icon-mailing"></i>Mailing</router-link>&nbsp;
              <router-link v-if="lvl > 3" tag="i" :to="{ path: '/programme/edit/'+program.numero}" class="icon-edit"></router-link>&nbsp;
              <router-link v-if="lvl > 3" tag="i" :to="{ path: '/programme/del/'+program.numero}" class="icon-delete"></router-link>
            </small>
            <span class="title">{{ program.name }}</span>
            <span class="location"><i class="icon-location"></i> À {{ program.location.city }}, {{ program.location.district }}</span>
            <p>{{ program.description }}</p>
            <p v-if="program.download.video !== ''">
              🎞️ <a title="Visionner la video webinar" target="_blank" :href="program.download.video">Présentation vidéo de la résidence</a>
            </p>
            <section class="inside">
              <ul class="badged">
                <li><p class="yellow"><span><em v-for="(law, l) in program.laws" :key="l" ><i v-if="l > 0">,</i> {{ config.laws[law] }}</em></span> <i v-if="program.laws[0] === 0">dispositif</i></p></li>
                <li><p class="blue">Du <span>{{ program.lot.type.min | type }} au {{ program.lot.type.max | type }}</span></p></li>
                <li><p class="">Livraison <span>{{ program.delivery | yearDate }}</span></p></li>
                <li><p class="grey">De <span>{{ program.lot.size.min }}m² à {{ program.lot.size.max }}m²</span></p></li>
                <li><p class="yellow">À partir de <span>{{ program.lot.price.min.toLocaleString() }} €</span></p></li>
                <li><p class="blue">DAT <span>{{ program.dat | yearDate }}</span></p></li>
              </ul>
              <section>
                <ul class="download">
                  <li v-if="program.download.booklet !== ''">
                    <a href="javascript:void(0);" @click="down(program.download.booklet)">
                      <i class="bold">🕮</i><span>Plaquette</span>
                    </a>
                  </li>
                  <li v-if="program.download.grid !== ''">
                    <a href="javascript:void(0);" @click="down(program.download.grid)">
                      <i class="bold">€</i><span>Grille de prix</span>
                    </a>
                  </li>
                  <li v-if="program.location.position.lat !== '' && program.location.position.lng !== ''">
                    <a :href="'https://www.google.fr/maps/dir/'
                     + program.location.position.lat + ','
                     + program.location.position.lng + '/'" target="_blank">
                      <i class="icon-location"></i><span>Plans</span>
                    </a>
                  </li>
                  <li v-if="program.download.simulator !== ''">
                    <a href="javascript:void(0);" @click="down(program.download.simulator)">
                      <i class="bold">🗎</i><span>Simulateur</span>
                    </a>
                  </li>
                  <li v-if="program.download.contract !== ''">
                    <a href="javascript:void(0);" @click="down(program.download.contract)">
                      <i class="bold">🗎</i><span>Mandat Partenaire</span>
                    </a>
                  </li>
                </ul>
                <Donut v-if="isAdmin" class="stats" v-bind:numero="program.numero" />
                <p v-else class="program-logo">
                  <img v-if="program.logo !== ''" alt="logo" :src="this.settings.upload + program.logo" />
                </p>
              </section>
            </section>
            <section class="certification">
              <span v-if="program.lot.dpe.classe > 0">
                <i>Classe énergie</i>
                <img v-if="program.lot.dpe.classe === 1" alt="classe A" :src="this.settings.upload + 'program/classe.a.png'" />
                <img v-if="program.lot.dpe.classe === 2" alt="classe B" :src="this.settings.upload + 'program/classe.b.png'" />
                <img v-if="program.lot.dpe.classe === 3" alt="classe C" :src="this.settings.upload + 'program/classe.c.png'" />
                <img v-if="program.lot.dpe.classe === 4" alt="classe D" :src="this.settings.upload + 'program/classe.d.png'" />
                <img v-if="program.lot.dpe.classe === 5" alt="classe E" :src="this.settings.upload + 'program/classe.e.png'" />
                <img v-if="program.lot.dpe.classe === 6" alt="classe F" :src="this.settings.upload + 'program/classe.f.png'" />
                <img v-if="program.lot.dpe.classe === 7" alt="classe G" :src="this.settings.upload + 'program/classe.g.png'" />
              </span>
              <span v-if="program.lot.dpe.ges > 0">
                <i>G.E.S <u class="verysmall">(Gaz à Effet de Serre)</u></i>
                <img v-if="program.lot.dpe.ges === 1" alt="ges A" :src="this.settings.upload + 'program/ges.a.png'" />
                <img v-if="program.lot.dpe.ges === 2" alt="ges B" :src="this.settings.upload + 'program/ges.b.png'" />
                <img v-if="program.lot.dpe.ges === 3" alt="ges C" :src="this.settings.upload + 'program/ges.c.png'" />
                <img v-if="program.lot.dpe.ges === 4" alt="ges D" :src="this.settings.upload + 'program/ges.d.png'" />
                <img v-if="program.lot.dpe.ges === 5" alt="ges E" :src="this.settings.upload + 'program/ges.e.png'" />
                <img v-if="program.lot.dpe.ges === 6" alt="ges F" :src="this.settings.upload + 'program/ges.f.png'" />
                <img v-if="program.lot.dpe.ges === 7" alt="ges G" :src="this.settings.upload + 'program/ges.g.png'" />
              </span>
              <span v-if="program.lot.certification.nf > 0">
                <img v-if="program.lot.certification.nf === 1" alt="nf" :src="this.settings.upload + 'program/nf.png'" />
                <img v-if="program.lot.certification.nf === 2" alt="nfhqe" :src="this.settings.upload + 'program/nfhqe.png'" />
              </span>
              <span v-if="program.lot.certification.rt > 0">
                <img v-if="program.lot.certification.rt === 1" alt="rtaadom" :src="this.settings.upload + 'program/rtaa.png'" />
                <img v-if="program.lot.certification.rt === 2" alt="rt2012" :src="this.settings.upload + 'program/rt12.png'" />
                <img v-if="program.lot.certification.rt === 3" alt="re2020" :src="this.settings.upload + 'program/re20.png'" />
              </span>
            </section>
          </section>
      </section>
    </section>
    <section class="title">
      <h1><i class="icon-lot"></i>LES LOTS</h1>
    </section>
    <section class="lots filter">
      <ul>
        <li class="filterchoice">
          <select v-model="filteredA">
            <option value="-1">Statut: Tous</option>
            <option value="0">Disponible</option>
            <option v-if="isAdmin" value="1">Optionné</option>
            <option v-if="isAdmin" value="2">Réservé</option>
            <option v-if="isAdmin" value="3">Financé</option>
            <option v-if="isAdmin" value="4">Acté</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredB">
            <option value="-1">Type: Tous</option>
            <option v-for="(type, t) in settings.lot.type" :value="t" :key="t">{{ type }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredC">
            <option value="-1">Prix: Tous</option>
            <option v-for="(range, r) in settings.lot.ranges" :value="r" :key="r">{{ range.text }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredD">
            <option value="-1">Defisc: Toutes</option>
            <option v-for="(def, d) in settings.lot.defisc" :value="d" :key="d">{{ def.text }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
      </ul>
      <ul>
        <router-link v-if="lvl > 3" tag="li" :to="{ path: '/lot/new/program/' + program.numero }" class="filteradd"><i class="icon-add"></i> Ajouter un lot</router-link>
      </ul>
    </section>

    <LotsList v-if="isAdmin" :lots="lotDefisc" />

    <LotsListLimited v-else-if="!closed" :lots="lotDefisc" />

  </section>
</template>

<script>
import settings from '@/settings.js'
import Profile from '@/components/Profile.vue'
import Donut from '@/components/ProgrammesDonut.vue'
import LotsList from '@/components/LotsList.vue'
import LotsListLimited from '@/components/LotsListLimited.vue'
import { image } from '@/helpers.js'

export default {
  name: 'ProgrammeDetail',
  components: {
    Profile,
    Donut,
    LotsList,
    LotsListLimited
  },
  data () {
    return {
      settings,
      gallery: 0,
      filteredA: -1,
      filteredB: -1,
      filteredC: -1,
      filteredD: -1
    }
  },
  computed: {
    program: function () {
      return this.$store.getters.get_program_by_numero(this.$route.params.id)
    },
    lots: function () {
      return this.$store.getters.get_lots_by_program_num(this.$route.params.id)
    },
    lotStatus: function () {
      var fil = Number(this.filteredA)
      if (fil === -1) return this.lots
      return this.lots.filter(el => el.status === fil)
    },
    lotType: function () {
      var fil = Number(this.filteredB)
      if (fil === -1) return this.lotStatus
      return this.lotStatus.filter(el => el.spec.type === fil)
    },
    lotRange: function () {
      var fil = Number(this.filteredC)
      var range = this.settings.lot.ranges
      if (fil === -1) return this.lotType
      return this.lotType.filter(
        el => el.spec.price <= range[fil].max && el.spec.price >= range[fil].min)
    },
    lotDefisc: function () {
      var fil = Number(this.filteredD)
      var range = this.settings.lot.defisc
      if (fil === -1) return this.lotRange
      return this.lotRange.filter(
        el => el.spec.defisc <= range[fil].max && el.spec.price >= range[fil].min)
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin
    },
    lvl: function () {
      return this.$store.getters.lvl
    },
    config: function () {
      return this.$store.getters.get_settings()
    },
    closed: function () {
      if (this.program.status === 1) return false
      return true
    }
  },
  created () {
    // if program doesnt exist
    if (this.program === undefined) this.$router.push('/liste-des-programmes')
    // update stats when partner click on program
    if (!this.isAdmin) {
      // if program status is 'closed'
      if (this.closed) this.$router.push('/liste-des-programmes')
      var id = this.program.id
      this.$store.dispatch('editStatProgram', id)
        .then(() => {})
        .catch()
    }
  },
  methods: {
    image,
    down: function (url) {
      var href = this.settings.upload + url
      window.open(href, '_blank')
    },
    formatCSV: function () {
      var data = []
      this.lots.forEach(l => {
        // init var
        var associate = false
        var row = {
          programme: this.program.shortname + '(' + this.program.numero + ')',
          date_com: this.$options.filters.shortDate(this.program.launch),
          lot: l.spec.building + '' + l.numero,
          status: '',
          partenaire_nom: '',
          client_genre: '',
          client_nom: '',
          client_prenom: '',
          client_email: '',
          client_adresse: '',
          client_ville: '',
          client_cp: '',
          client_tel: '',
          client_mobile: '',
          prix: l.spec.price,
          prix_final: '',
          frais_not: l.spec.notaryfees,
          date_option: '',
          date_resa: '',
          date_finance: '',
          date_acte: '',
          date_annule: '',
          loi: this.config.laws[l.law],
          date_liv: this.$options.filters.shortDate(this.program.delivery),
          type: this.$options.filters.type(l.spec.type),
          etage: l.spec.floor,
          surf_hab: l.spec.size,
          var: l.spec.varangue,
          surf_total: '',
          surf_defis: l.spec.total,
          ville: this.program.location.city
        }
        var reserv = this.$store.getters.get_reservations_by_lot_uid(l.uid.toString())
        // .filter(r => r.status > 0)
        if (reserv.length > 0) {
          for (var i = 0; i < reserv.length; i++) {
            row.status = this.$options.filters.reservation_status(reserv[i].status)
            var prospect = this.$store.getters.get_prospect_by_numero(reserv[i].prospect.numero)
            var user = this.$store.getters.get_user_by_username(reserv[i].user.username)
            var partnerName = reserv[i].user.username
            if (user) partnerName = user.meta.lastname
            row.partenaire_nom = partnerName
            row.client_genre = this.$options.filters.gender(prospect.personal.gender)
            row.client_nom = prospect.lastname
            row.client_prenom = prospect.firstname
            row.client_email = prospect.email
            row.client_adresse = prospect.contact.address
            row.client_ville = prospect.contact.city
            row.client_cp = prospect.contact.zipcode
            row.client_tel = this.$options.filters.phone(prospect.contact.phone)
            row.client_mobile = this.$options.filters.phone(prospect.contact.mobile)
            row.date_option = this.$options.filters.shortDate(reserv[i].lot.dateoption)
            row.date_resa = this.$options.filters.shortDate(reserv[i].lot.datereserv)
            row.date_finance = this.$options.filters.shortDate(reserv[i].lot.datefinanced)
            row.date_acte = this.$options.filters.shortDate(reserv[i].lot.dateacted)
            row.date_annule = this.$options.filters.shortDate(reserv[i].lot.datecancel)
            row.prix_final = reserv[i].lot.actedprice
            // if associate
            if (reserv[i].prospect.numero2) {
              associate = true
              var prospect2 = this.$store.getters.get_prospect_by_numero(reserv[i].prospect.numero2)
              var row2 = {
                programme: '',
                date_com: '',
                lot: '',
                status: '',
                partenaire_nom: '',
                client_genre: this.$options.filters.gender(prospect2.personal.gender),
                client_nom: prospect2.lastname,
                client_prenom: prospect2.firstname,
                client_email: prospect2.email,
                client_adresse: prospect2.contact.address,
                client_ville: prospect2.contact.city,
                client_cp: prospect2.contact.zipcode,
                client_tel: this.$options.filters.phone(prospect2.contact.phone),
                client_mobile: this.$options.filters.phone(prospect2.contact.mobile),
                prix: l.spec.price,
                prix_final: '',
                frais_not: '',
                date_option: '',
                date_resa: '',
                date_finance: '',
                date_acte: '',
                date_annule: '',
                loi: '',
                date_liv: '',
                type: '',
                etage: '',
                surf_hab: '',
                var: '',
                surf_total: '',
                surf_defis: '',
                ville: ''
              }
            }
          }
        }
        data.push(row)
        if (associate) data.push(row2)
      })
      this.createFile(data)
    },
    createFile: function (data) {
      // If data is not an object then JSON.parse will parse the JSON string in an Object
      var arrData = typeof data !== 'object' ? JSON.parse(data) : data
      var CSV = 'sep=,' + '\r\n'
      var label = ''
      for (var indexLabel in arrData[0]) {
        label += indexLabel + ','
      }
      label = label.slice(0, -1)
      CSV += label + '\r\n'
      for (var i = 0; i < arrData.length; i++) {
        var row = ''
        for (var index in arrData[i]) {
          row += '"' + arrData[i][index] + '",'
        }
        row.slice(0, row.length - 1)
        CSV += row + '\r\n'
      }
      // Initialize file format you want csv or xls
      const a = document.createElement('a')
      const today = this.$options.filters.shortDate(Date.now())
      a.href = 'data:text/csvcharset=utf-8,' + escape(CSV)
      a.style = 'visibility:hidden'
      a.download = 'Export-Clients-' + this.program.shortname + '-' + today + '.csv'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>
