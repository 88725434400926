<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-alert"></i>NOUVELLE DEMANDE</h1>
    </section>
    <form @submit.prevent="add()">
    <section class="grid add">
      <section class="card new">
        <h2>Programme <sup class="textred">*</sup></h2>
        <section>
          <figure><img alt="" :src="image(program.data.thumbnail || '')" /></figure>
          <p>
            <label for="program">Choix du programme (Obligatoire)</label>
            <select id="program" v-model="program.numero" @click="setProgram()">
              <option value="0">Aucun</option>
              <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Lot <sup class="textred">*</sup></h2>
        <section>
          <p>
            <label for="lot">Choix du lot (Obligatoire)</label>
            <select id="lot" v-model="lot.uid" @click="setLot()">
              <option value="">Aucun</option>
              <option v-for="l in lots" :value="l.uid" :key="l.id">{{ l.spec.building }} {{ l.numero }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
          <div v-if="request.lot.numero">
            <p>
              <span class="badge">Infos:</span> {{ lot.data.spec.type | type }} ({{ lot.data.spec.floor | floor }}) {{ lot.data.spec.size || '0'  }} m²
            </p>
            <p>
              <span class="badge">Prix:</span> {{ lot.data.spec.price.toLocaleString()  || '0' }} €
            </p>
            <p>
              <span class="badge">Statut:</span> <span class="badge" :class="status(lot.data.status)">{{ lot.data.status | lot_status }}</span>
            </p>
            <p>
              <label for="stat">Statut</label>
              <select id="stat" v-model="request.status" disabled>
                <option v-for="(reserv, r) in settings.request.status" :value="r" :key="r">{{ reserv }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
            <p>
              <label for="law">Dispositif de loi</label>
              <select id="law" v-model="request.lot.law">
                <option v-for="(law, l) in settings.lot.law" :value="l" :key="l">{{ law }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
            <p>
              <label for="law">Année de défiscalisation</label>
              <select id="law" v-model="request.lot.lawyears">
                <option v-for="(years, y) in settings.lot.lawyears" :value="y" :key="y">{{ years }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
          </div>
        </section>
      </section>
      <section class="card new">
        <h2>Paramètres</h2>
         <section>
          <p>
            <label for="num">Numéro (auto)</label>
            <input type="text" id="num" v-model="request.numero" disabled />
          </p>
        </section>
        <section>
          <p>
            <label for="created">Date de création</label>
            <input type="text" id="created" disabled :value="request.created | shortDate" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Partenaire <sup class="textred">*</sup></h2>

        <findUser :user="request.user.username" @send="setUser" />

      </section>
      <section class="card new">
        <h2>Client</h2>

        <findProspect :prospect="request.prospect.numero" :filter="0" :type="'prospect'" @send="setProspect" />

      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ request }}</pre>
      </section>
    </section>
    <section v-if="validate()" class="card">
      <p>
        <button type="submit" :disabled="processing">AJOUTER</button>
      </p>
    </section>
    <InvalidForm v-else />
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import FindProspect from '@/components/FindProspect.vue'
import FindUser from '@/components/FindUser.vue'
import { image } from '@/helpers.js'
import settings from '@/settings.js'

export default {
  name: 'ReservationNew',
  components: {
    Profile,
    InvalidForm,
    FindProspect,
    FindUser
  },
  data () {
    return {
      settings,
      processing: false,
      program: {
        numero: 0,
        data: {}
      },
      lot: {
        uid: '',
        data: {}
      },
      request: {
        id: '',
        numero: 0,
        program: {
          id: '',
          numero: 0,
          shortname: ''
        },
        lot: {
          id: '',
          uid: '',
          numero: 0,
          price: 0,
          law: 0,
          lawyears: 0
        },
        user: {
          id: '',
          username: '',
          firstname: '',
          lastname: ''
        },
        prospect: {
          id: '',
          numero: 0,
          firstname: '',
          lastname: '',
          email: '',
          phone: ''
        },
        status: 0,
        created: Date.now()
      }
    }
  },
  computed: {
    programs: function () {
      return this.$store.getters.get_programs()
    },
    lots: function () {
      const lots = this.$store.getters.get_lots_by_program_num(this.program.numero)
      return lots
    },
    lastNum: function () {
      return this.$store.getters.get_request_last_numero() + 1
    },
    // prospects
    prospects: function () {
      return this.$store.getters.get_prospects()
    },
    users: function () {
      return this.$store.getters.get_users()
    }
  },
  created () {
    if (this.$route.params.id) {
      const selectedLot = this.$store.getters.get_lot_by_uid(this.$route.params.id)
      this.program.numero = selectedLot.program.numero
      this.setProgram()
      this.lot.uid = selectedLot.uid
      this.setLot()
    }
    this.request.numero = this.lastNum
    this.request.created = Date.now()
  },
  methods: {
    add: function () {
      this.processing = true
      var request = this.request
      this.$store.dispatch('addRequest', request)
        .then(() => {
          this.$store.commit('reservations_tab', 2)
          this.$router.push('/reservations')
        })
        .catch()
    },
    setProgram: function () {
      // reset lot data
      this.request.lot.id = ''
      this.request.lot.numero = 0
      this.lot = {
        uid: '',
        data: {}
      }
      // get the num from select
      if (Number(this.program.numero)) {
        this.program.data = this.programs.find(p => p.numero === this.program.numero)
        this.request.program.id = this.program.data.id
        this.request.program.numero = this.program.data.numero
        this.request.program.shortname = this.program.data.shortname
      } else {
        this.request.program.id = ''
        this.request.program.numero = 0
        this.request.program.shortname = ''
        this.program = {
          numero: 0,
          data: {}
        }
      }
    },
    setLot: function () {
      if (this.lot.uid !== '') {
        this.lot.data = this.lots.find(l => l.uid.toString() === this.lot.uid.toString())
        this.request.lot.id = this.lot.data.id
        this.request.lot.uid = this.lot.data.uid
        this.request.lot.numero = this.lot.data.numero
        this.request.lot.price = this.lot.data.spec.price
        // this.request.lot.law = this.lot.data.law
        // this.request.lot.lawyears = this.lot.data.lawyears
      } else {
        this.request.lot.id = ''
        this.request.lot.uid = ''
        this.request.lot.numero = 0
        this.request.lot.price = 0
        this.request.lot.law = 0
        this.request.lot.lawyears = 0
        this.lot = {
          uid: '',
          data: {}
        }
      }
    },
    setUser: function (user) {
      if (user !== '') {
        var data = this.users.find(u => u.username === user)
        this.request.user.username = user
        this.request.user.id = data.id
        this.request.user.firstname = data.meta.firstname
        this.request.user.lastname = data.meta.lastname
      } else {
        this.request.user.id = ''
        this.request.user.username = ''
        this.request.user.firstname = ''
        this.request.user.lastname = ''
      }
    },
    setProspect: function (numero, type) {
      if (numero > 0) {
        var data = this.prospects.find(p => Number(p.numero) === numero)
        this.request[type].id = data.id
        this.request[type].numero = numero
        this.request[type].firstname = data.firstname
        this.request[type].lastname = data.lastname
        this.request[type].email = data.email
        this.request[type].phone = data.contact.phone
      } else {
        this.request[type].id = ''
        this.request[type].numero = 0
        this.request[type].firstname = ''
        this.request[type].lastname = ''
        this.request[type].email = ''
        this.request[type].phone = ''
      }
    },
    validate: function () {
      if (!this.request.lot.numero) return false
      if (this.request.user.username === '') return false
      return true
    },
    status: function (value) {
      return 'st' + value
    },
    image
  }
}
</script>
