
import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  fetchReservations ({ commit, state }) {
    return new Promise((resolve, reject) => {
      // call the api
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'fetchReservations',
          params: ''
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('set_reservations', resp.data.data)
            resolve(resp)
          } else {
            // error
            var mess = 'Fetching `Reservation` failed!'
            commit('status_error', mess)
            reject(resp.data)
          }
        })
    })
  },
  fetchReservationsLimited ({ commit, state }) {
    return new Promise((resolve, reject) => {
      // call the api
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'fetchReservationsLimited',
          params: {
            user: state.auth.key
          }
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('set_reservations', resp.data.data)
            resolve(resp)
          } else {
            // error
            var mess = 'Fetching `Reservation` failed!'
            commit('status_error', mess)
            reject(resp.data)
          }
        })
    })
  },
  addReservation ({ commit, state }, reservation) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // set lot parameters
      reservation.id = this.getters.get_uuid()
      reservation.created = Date.now()
      reservation.numero = this.getters.get_reservation_last_numero() + 1

      // edit lot status
      var lot = this.getters.get_lot_by_uid(reservation.lot.uid)
      lot.status = reservation.status
      lot.reservation.numero = reservation.numero
      lot.reservation.date = reservation.created
      lot.law = reservation.lot.law
      lot.lawyears = reservation.lot.lawyears

      this.dispatch('editLot', lot)
        .then(() => {
          // lot has been edited
        })
        .catch()

      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'createReservation',
          params: {
            id: reservation.id,
            numero: reservation.numero,
            program: JSON.stringify(reservation.program),
            lot: JSON.stringify(reservation.lot),
            user: JSON.stringify(reservation.user),
            prospect: JSON.stringify(reservation.prospect),
            notary: JSON.stringify(reservation.notary),
            bank: JSON.stringify(reservation.bank),
            remarks: JSON.stringify(reservation.remarks),
            status: reservation.status,
            created: reservation.created,
            api: JSON.stringify(reservation.api)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('create_reservation', reservation)
            var mess = 'Reservation add to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to add reservation in database!'
            commit('status_error', err)
          }
        })
    })
  },
  editReservation ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')

      var reservation = payload.reservation
      var cancel = payload.cancel

      if (!cancel) {
        // edit lot status
        var lot = this.getters.get_lot_by_uid(reservation.lot.uid)
        lot.status = reservation.status
        lot.law = reservation.lot.law
        lot.lawyears = reservation.lot.lawyears
        lot.reservation.numero = reservation.numero
        lot.reservation.date = reservation.lot.datereserv
        lot.reservation.actedprice = reservation.lot.actedprice
        // reset if reservation is canceled
        if (lot.status === 0) {
          lot.law = 0
          lot.lawyears = 0
          lot.reservation = {
            numero: '',
            actedprice: 0,
            date: 0
          }
        }
        this.dispatch('editLot', lot)
          .then(() => {
            // lot has been edited
          })
          .catch()
      }

      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'updateReservation',
          params: {
            id: reservation.id,
            numero: reservation.numero,
            program: JSON.stringify(reservation.program),
            lot: JSON.stringify(reservation.lot),
            user: JSON.stringify(reservation.user),
            prospect: JSON.stringify(reservation.prospect),
            notary: JSON.stringify(reservation.notary),
            bank: JSON.stringify(reservation.bank),
            remarks: JSON.stringify(reservation.remarks),
            status: reservation.status,
            created: reservation.created,
            api: JSON.stringify(reservation.api)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('update_reservation', reservation)
            var mess = 'Reservation edited to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to edit reservation in database!'
            commit('status_error', err)
          }
        })
    })
  },
  removeReservation ({ commit, state }, reservation) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      var lot = this.getters.get_lot_by_uid(reservation.lot.uid)
      // reset if reservation not already canceled
      if (lot.status > 0) {
        // edit lot status
        lot.status = 0
        lot.law = 0
        lot.lawyears = 0
        lot.reservation = {
          numero: '',
          actedprice: 0,
          date: 0
        }
        this.dispatch('editLot', lot)
          .then(() => {
            // lot has been edited
          })
          .catch()
      }

      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'deleteReservation',
          params: {
            id: reservation.id
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('delete_reservation', reservation)
            var mess = 'Reservation removed from database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to delete reservation in database!'
            commit('status_error', err)
          }
        })
    })
  }
}

export default Actions
