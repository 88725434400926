<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-utilisateur"></i>ÉDITION UTILISATEUR</h1>
    </section>
    <form @submit.prevent="edit()">
    <section class="grid add">
      <section class="card new">
        <h2>Connexion <sup class="textred">*</sup></h2>
        <section>
          <p>
            <label for="username">Identifant utilisateur</label>
            <input type="text" id="username" v-model="user.username" disabled />
          </p>
        </section>
        <section>
          <p>
            <small v-if="editpass" class="info orange">Info: Le mot de passe sera chiffré à l'enregistrement!</small>
            <label>Mot de passe</label>
            <label for="pass">
              <input type="checkbox" class="toggle" id="pass" v-model="editpass" @click="pass = pwdRandom()" />
              <small>Éditer le mot de passe </small>
            </label>
            <span v-if="editpass">
              <input type="text" id="password" v-model="pass" />
              &nbsp;<em @click="pass = pwdRandom()" class="badge st0">↻</em>
            </span>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Paramètres</h2>
        <section>
          <p>
            <label for="stat">Rôle</label>
            <select id="stat" v-model="user.role">
              <option v-for="(user, u) in settings.user.role" :value="u" :key="u">{{ user }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="access">Activé</label>
            <select id="access" v-model="user.actived">
              <option :value="0">non</option>
              <option :value="1">oui</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="created">Date de création</label>
            <input type="text" id="created" disabled :value="user.created | shortDate" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Informations</h2>
        <section>
          <p>
            <label for="gender">Genre</label>
            <select id="gender" v-model="user.meta.gender">
              <option value="0">Aucun</option>
              <option value="1">Homme</option>
              <option value="2">Femme</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="firstname">Prénom</label>
            <input type="text" id="firstname" v-model="user.meta.firstname" />
          </p>
        </section>
        <section>
          <p>
            <label for="lastname">Nom</label>
            <input type="text" id="lastname" v-model="user.meta.lastname" />
          </p>
          </section>
        <section>
          <p>
            <label for="mail">Email</label>
            <input type="text" id="mail" v-model="user.meta.email" />
          </p>
        </section>
        <section>
          <p>
            <label for="compagny">Société</label>
            <input type="text" id="compagny" v-model="user.meta.compagny" />
          </p>
        </section>
        <section>
          <p>
            <label for="birthday">Date de naissance</label>
            <v-date-picker id="birthday" v-model="user.meta.birthday"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Coordonnées</h2>
        <section>
          <p>
            <label for="adress">Adresse</label>
            <input type="text" id="adress" v-model="user.meta.contact.address" />
          </p>
        </section>
        <section>
          <p>
            <label for="city">Ville</label>
            <input type="text" id="city" v-model="user.meta.contact.city" />
          </p>
        </section>
        <section>
          <p>
            <label for="zip">Code postal</label>
            <input type="text" id="zip" v-model="user.meta.contact.zipcode" />
          </p>
        </section>
        <section>
          <p>
            <label for="phone">Téléphone</label>
            <input type="text" id="phone" v-model="user.meta.contact.phone" />
          </p>
        </section>
        <section>
          <p>
            <label for="mobile">Mobile</label>
            <input type="text" id="mobile" v-model="user.meta.contact.mobile" />
          </p>
        </section>
      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ user }}</pre>
      </section>
    </section>
    <section class="card" v-if="validate()">
      <label for="sendmail" v-if="editpass">
        <input type="checkbox" class="toggle" id="sendmail" v-model="mail" />
        <small>Transférer les identifiants de connexion par mail</small>
      </label>
      <p>
        <button type="submit">EDITER</button>
      </p>
    </section>
    <InvalidForm v-else />
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import settings from '@/settings.js'

export default {
  name: 'UtilisateurEdit',
  components: {
    Profile,
    InvalidForm
  },
  data () {
    return {
      settings,
      mail: 0,
      editpass: 0,
      pass: '',
      user: {}
    }
  },
  computed: {
    use: function () {
      return this.$store.getters.get_user_by_username(this.$route.params.id)
    }
  },
  created () {
    this.user = this.use
    this.user.meta.contact.phone = this.$options.filters.phone(this.user.meta.contact.phone)
    this.user.meta.contact.mobile = this.$options.filters.phone(this.user.meta.contact.mobile)
  },
  methods: {
    edit: function () {
      if (this.editpass) {
        this.user.password = this.pass
      }
      // phone formated to string (ex:#0298571020)
      this.user.meta.contact.phone = '#' + this.user.meta.contact.phone.split('.').join('').split(' ').join('').split('#').join('')
      this.user.meta.contact.mobile = '#' + this.user.meta.contact.mobile.split('.').join('').split(' ').join('').split('#').join('')
      // prepare payload
      const payload = {
        user: this.user,
        pass: this.editpass,
        mail: this.mail
      }
      this.$store.dispatch('editUser', payload)
        .then(() => {
          this.$router.push('/utilisateurs')
        })
        .catch()
    },
    pwdRandom: function () {
      const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      return Array(10).fill(chars).map(x => { return x[Math.floor(Math.random() * x.length)] }).join('')
    },
    validate: function () {
      if (this.user.password === '') return false
      if (this.editpass && this.pass === '') return false
      return true
    }
  }
}
</script>
