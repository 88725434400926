<template>
  <section class="messages" :class="{
    'standby' : code === 0,
    'loading' : code === 1,
    'success' : code === 2,
    'errors' : code === 3,
  }" >
    <span>{{ message }}</span>
  </section>
</template>

<script>

export default {
  name: 'Errors',
  data () {
    return {
    }
  },
  computed: {
    code: function () {
      return this.$store.getters.status.code
    },
    message: function () {
      return this.$store.getters.status.message
    }
  },
  watch: {
    code (newCode, oldCode) {
      if (newCode >= 2) {
        setTimeout(() => {
          this.$store.commit('status_standby')
        }, 2000)
      }
    }
  }
}
</script>
