<template>
  <section class="profile">
    <ul>
      <li v-if="hover" class="bulle">
        <span>{{ count }}</span>&nbsp;
        <i v-if="isAdmin">Option(s) à date d'expiration</i>
        <i v-else> Option(s) à valider</i>
      </li>
      <li class="alert"
        @click="goto"
        @mouseleave="hover = false"
        @mouseover="hover = true"
        :class="{'active': count > 0}">
        <i class="icon-alert"></i>
        <svg width="10" height="10"><circle cx="4" cy="4" r="4" fill="transparent"/></svg>
      </li>
      <router-link tag="li" to="/profile" class="account"><i class="icon-account"></i></router-link>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'Profile',
  data () {
    return {
      hover: false
    }
  },
  computed: {
    expired: function () {
      return this.$store.getters.get_reservations_by_expired()
    },
    options: function () {
      return this.$store.getters.get_reservations().filter(r => r.user.username === this.me).filter(r => r.status === 1)
    },
    me: function () {
      return this.$store.getters.me
    },
    count: function () {
      if (this.isAdmin) return Object.keys(this.expired).length
      return Object.keys(this.options).length
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin
    }
  },
  methods: {
    goto: function () {
      if (this.count > 0) {
        // admin
        if (this.isAdmin) {
          this.$store.commit('reservations_tab', 1)
          if (this.$router.currentRoute.name !== 'ReservationsExpired') this.$router.push('/reservations/expired')
        } else {
          // partner
          if (this.$router.currentRoute.name !== 'MonCompte') this.$router.push('/profile')
        }
      }
    }
  }
}
</script>
