import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  askForLogin ({ commit }, ask) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: '',
          token: '',
          function: 'signup',
          params: {
            name: ask.name,
            city: ask.city,
            email: ask.email,
            tel: ask.tel
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            var mess = 'Login request has been sent'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Login request failed!'
            commit('status_error', err)
          }
        })
    })
  },
  mailing ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      var url = 'mailPublic'
      if (payload.list === 'private') {
        url = 'mailPrivate'
      }
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: url,
          params: {
            program: payload.program,
            comment: payload.comment,
            email: payload.email
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            var mess = 'Mailing has been sent'
            commit('status_success', mess)
            resolve(true)
          } else {
            // error
            var err = 'Mailing request failed!'
            commit('status_error', err)
            resolve(false)
          }
        })
    })
  }
}

export default Actions
