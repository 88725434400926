import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  // increment++ stats program click (server side)
  editStatProgram ({ state }, payload) {
    return new Promise((resolve, reject) => {
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'updateStatProgram',
          params: {
            id: payload
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            resolve()
          }
        })
    })
  },
  // increment++ stats lot download click (server side)
  editStatLot ({ state }, payload) {
    return new Promise((resolve, reject) => {
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'updateStatLot',
          params: {
            id: payload
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            resolve()
          }
        })
    })
  }
}

export default Actions
