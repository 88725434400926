<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-mailing"></i>MAILINGS</h1>
    </section>
    <form @submit.prevent="mail()">
    <section class="grid add">
      <section class="card new">
        <h2>Programme <sup class="textred">*</sup></h2>
        <section>
          <figure><img alt="" :src="image(program.data.thumbnail || '')" /></figure>
          <p>
            <label for="program">Programme (Obligatoire)</label>
            <select id="program" v-model="program.numero" @click="setProgram()">
              <option value="">Aucun</option>
              <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Liste de diffusion <sup class="textred">*</sup></h2>
        <section>
          <span class="listselect" :class="{'none': list === ''}">
            <i v-if="list === ''" class="icon-account"></i>
            <i v-if="list === 'private'" class="icon-utilisateur"></i>
            <i v-if="list === 'public'" class="icon-prospect"></i>
          </span>
          <p>
            <label for="list">Liste (Obligatoire)</label>
            <select id="list" v-model="list" @change="resetCom()">
              <option value="">Aucune</option>
              <option value="private">Interne ({{ emails.private.length }})</option>
              <option value="public">CGP ({{ emails.public.length }})</option>
            </select>
            <i class="icon-select"></i>
          </p>

          <p>
            <label for="single">Envois unique
              <input type="checkbox" class="toggle" id="single" v-model="single" />
            </label>
          </p>
          <p v-if="single">
            <small>Adresse email</small>
            <input type="text" id="delay" v-model="solo" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Paramètres</h2>
        <section>
          <p>
            <label for="delayl">Delais d'envois</label>
            <small>Intervalle en millisecondes</small>
            <input type="text" id="delay" v-model="ms" />
          </p>
          <p>
            <label for="cc">CC Administrateur
              <input type="checkbox" class="toggle" id="cc" v-model="cc" />
            </label>
          </p>
          <p v-if="cc">
            <small>Adresse email</small>
            <input type="text" id="delay" v-model="admin" disabled />
          </p>
        </section>
      </section>
    </section>
    <section>
    </section>
    <section class="grid" v-if="list === 'private'">
      <section class="card new">
        <section>
          <h2>Commentaires</h2>
          <p>
            <label for="comment">Text (400 char)</label>
            <textarea maxlength="400" v-model="comment" id="comment"
            rows="10" cols="30"></textarea>
          </p>
        </section>
      </section>
    </section>

    <MailingsTable v-if="program.numero && list === 'private'" :program="program.data"></MailingsTable>

    <MailingsTablePartner v-if="program.numero && list === 'public'" :program="program.data"></MailingsTablePartner>

    <section v-if="validate()" class="card">
      <small v-if="message.start">
        envoyé: {{ message.send }} / restant: {{ message.remaining }} <br/>
        email: {{ message.email }} <i v-if="message.status" class="round green">✔</i><i v-else class="round red">✘</i>
      </small>
      <p>
        <button type="submit">ENVOYER</button>
      </p>
    </section>

    <InvalidForm v-else />

    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import MailingsTable from '@/components/MailingsTable.vue'
import MailingsTablePartner from '@/components/MailingsTablePartner.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import { image } from '@/helpers.js'

export default {
  name: 'Mailings',
  components: {
    Profile,
    MailingsTable,
    MailingsTablePartner,
    InvalidForm
  },
  data () {
    return {
      message: {
        start: false,
        finish: false,
        email: '',
        status: false,
        send: 0,
        remaining: 0
      },
      ms: 250,
      list: '',
      cc: false,
      single: false,
      solo: '',
      comment: '',
      program: {
        numero: '',
        data: {}
      }
    }
  },
  computed: {
    programs: function () {
      return this.$store.getters.get_programs()
    },
    users: function () {
      return this.$store.getters.get_users()
    },
    admin: function () {
      return this.$store.getters.get_admin_email()
    },
    emails: function () {
      // check valid email & user actived
      var list = this.users.filter(u => u.meta.email !== '' && u.actived === 1)
      // PUBLIC : 1: utilisateur - 2: conseiller - 3: interne - 4: admin - 3: superadmin
      var publique = list
      // INTERN: 3: interne - 4: admin - 3: superadmin
      var prive = list.filter(u => u.role === 3 || u.role === 4 || u.role === 5)
      return {
        private: prive,
        public: publique
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      this.program.numero = Number(this.$route.params.id)
      this.setProgram()
    }
  },
  methods: {
    setProgram: function () {
      if (Number(this.program.numero)) {
        this.program.data = this.programs.find(p => p.numero === this.program.numero)
      } else {
        this.program = {
          numero: '',
          data: {}
        }
      }
    },
    // prepare list of email
    mail: function () {
      var list = this.emails[this.list]
      // single mail (erase list)
      if (this.single) {
        list = []
        list.push(
          {
            meta: {
              email: this.solo
            }
          }
        )
      }
      // copy admin
      if (this.cc) {
        list.push(
          {
            meta: {
              email: this.admin
            }
          }
        )
      }
      this.message.remaining = list.length
      this.message.start = true
      this.send(list)
        .then(() => {
          // message has been sent
          this.finish = true
        })
        .catch()
    },
    // loop until list is finish
    send: async function (list) {
      var payload = {
        list: this.list,
        program: this.program.numero,
        comment: this.comment,
        email: ''
      }
      for (var user of list) {
        payload.email = user.meta.email
        await this.sender(payload)
        await this.delay(this.ms)
      }
    },
    // delay between each loop
    delay: function (t) {
      return new Promise(resolve => setTimeout(resolve, t))
    },
    // send email
    sender: function (payload) {
      this.$store.dispatch('mailing', payload)
        .then((s) => {
          this.message.email = payload.email
          this.message.send++
          this.message.remaining--
          this.message.status = s
        })
        .catch()
    },
    validate: function () {
      if (!Number(this.program.numero)) return false
      if (this.list === '') return false
      return true
    },
    resetCom: function () {
      this.comment = ''
    },
    image
  }
}
</script>
