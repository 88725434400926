
import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  fetchPrograms ({ commit, state }) {
    return new Promise((resolve, reject) => {
      // call the api
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'fetchPrograms',
          params: ''
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('set_programs', resp.data.data)
            resolve(resp)
          } else {
            // error
            var mess = 'Fetching `Programs` failed!'
            commit('status_error', mess)
            reject(resp.data)
          }
        })
    })
  },
  fetchProgramsLimited ({ commit, state }) {
    return new Promise((resolve, reject) => {
      // call the api
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'fetchProgramsLimited',
          params: ''
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('set_programs', resp.data.data)
            resolve(resp)
          } else {
            // error
            var mess = 'Fetching `Programs` failed!'
            commit('status_error', mess)
            reject(resp.data)
          }
        })
    })
  },
  addProgramWithUploads ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      var handlePayload = {
        numero: payload.program.numero,
        uploads: payload.uploads
      }
      if (payload.uploads.length > 0) {
        // manager uploads
        this.dispatch('uploadProgram', handlePayload)
          .then((result) => {
            result.forEach(function (element, key) {
              var name = element[0]
              var filename = element[1]
              var path = payload.program.numero + '/' + filename
              switch (name) {
                case 'thumb':
                  payload.program.thumbnail = path
                  break
                case 'logo':
                  payload.program.logo = path
                  break
                case 'img1':
                  payload.program.images[0] = path
                  break
                case 'img2':
                  payload.program.images[1] = path
                  break
                case 'img3':
                  payload.program.images[2] = path
                  break
                case 'img4':
                  payload.program.images[3] = path
                  break
                case 'booklet':
                  payload.program.download.booklet = path
                  break
                case 'grid':
                  payload.program.download.grid = path
                  break
                case 'simulator':
                  payload.program.download.simulator = path
                  break
                case 'contract':
                  payload.program.download.contract = path
                  break
              }
            })
            this.dispatch('addProgram', payload.program)
              .then(() => {
                resolve()
              })
              .catch()
          })
          .catch()
      } else {
        this.dispatch('addProgram', payload.program)
          .then(() => {
            resolve()
          })
          .catch()
      }
    })
  },
  addProgram ({ commit, state }, program) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // set program parameters
      program.id = this.getters.get_uuid()
      program.created = Date.now()
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'createProgram',
          params: {
            id: program.id,
            name: program.name,
            shortname: program.shortname,
            summary: program.summary,
            description: program.description,
            numero: program.numero,
            thumbnail: program.thumbnail,
            logo: program.logo,
            images: JSON.stringify(program.images),
            download: JSON.stringify(program.download),
            category: program.category,
            law: program.law,
            laws: JSON.stringify(program.laws),
            exposure: program.exposure,
            status: program.status,
            lot: JSON.stringify(program.lot),
            dat: program.dat,
            launch: program.launch,
            delivery: program.delivery,
            created: program.created,
            location: JSON.stringify(program.location),
            stats: JSON.stringify(program.stats)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('create_program', program)
            var mess = 'Program added to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to add program in database!'
            commit('status_error', err)
          }
        })
    })
  },
  editProgramWithUploads ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      var handlePayload = {
        numero: payload.program.numero,
        uploads: payload.uploads
      }
      if (payload.uploads.length > 0) {
        // manager uploads
        this.dispatch('uploadProgram', handlePayload)
          .then((result) => {
            result.forEach(function (element, key) {
              var name = element[0]
              var filename = element[1]
              var path = payload.program.numero + '/' + filename
              switch (name) {
                case 'thumb':
                  payload.program.thumbnail = path
                  break
                case 'logo':
                  payload.program.logo = path
                  break
                case 'img1':
                  payload.program.images[0] = path
                  break
                case 'img2':
                  payload.program.images[1] = path
                  break
                case 'img3':
                  payload.program.images[2] = path
                  break
                case 'img4':
                  payload.program.images[3] = path
                  break
                case 'booklet':
                  payload.program.download.booklet = path
                  break
                case 'grid':
                  payload.program.download.grid = path
                  break
                case 'simulator':
                  payload.program.download.simulator = path
                  break
                case 'contract':
                  payload.program.download.contract = path
                  break
              }
            })
            this.dispatch('editProgram', payload.program)
              .then(() => {
                resolve()
              })
              .catch()
          })
          .catch()
      } else {
        this.dispatch('editProgram', payload.program)
          .then(() => {
            resolve()
          })
          .catch()
      }
    })
  },
  editProgram ({ commit, state }, program) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'updateProgram',
          params: {
            id: program.id,
            name: program.name,
            shortname: program.shortname,
            summary: program.summary,
            description: program.description,
            numero: program.numero,
            thumbnail: program.thumbnail,
            logo: program.logo,
            images: JSON.stringify(program.images),
            download: JSON.stringify(program.download),
            category: program.category,
            law: program.law,
            laws: JSON.stringify(program.laws),
            exposure: program.exposure,
            status: program.status,
            lot: JSON.stringify(program.lot),
            dat: program.dat,
            launch: program.launch,
            delivery: program.delivery,
            created: program.created,
            location: JSON.stringify(program.location),
            stats: JSON.stringify(program.stats)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('update_program', program)
            var mess = 'Program edited to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to edit program in database!'
            commit('status_error', err)
          }
        })
    })
  },
  removeProgram ({ commit, state }, program) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'deleteProgram',
          params: {
            id: program.id
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('delete_program', program)
            var mess = 'Program removed from database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to delete program in database!'
            commit('status_error', err)
          }
        })
    })
  }
}

export default Actions
