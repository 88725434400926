import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  fetchUsers ({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'fetchUsers',
          params: {
            id: state.auth.key
          }
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('status_standby')
            commit('set_users', resp.data.data)
            resolve(resp)
          } else {
            // error
            var mess = 'Fetching `Users` failed!'
            commit('status_error', mess)
            reject(resp.data)
          }
        })
    })
  },
  getUser ({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'getUser',
          params: {
            id: state.auth.key
          }
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('status_standby')
            commit('set_users', resp.data.data)
            resolve(resp)
          } else {
            // error
            var mess = 'Fetching `Users` failed!'
            commit('status_error', mess)
            reject(resp.data)
          }
        })
    })
  },
  fetchMyProfile ({ commit, state }) {
    return new Promise((resolve, reject) => {
      // set parameters
      const id = state.auth.key
      const user = this.getters.get_user_by_id(id)
      if (user) {
        commit('set_user', user)
        resolve()
      } else {
        // error
        var err = 'Cant find user in database!'
        commit('status_error', err)
      }
    })
  },
  updateMyProfile ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      // set parameters
      const myID = state.user.id
      var meta = payload.meta
      // check if update meta after login
      if (payload.login) {
        meta = state.user.meta
        meta.tech.connectioncount++
        meta.tech.lastconnection = Date.now()
      }
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'updateMeta',
          params: {
            id: myID,
            meta: JSON.stringify(meta)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            resolve()
          } else {
            // error
            var err = 'Failed to edit user profile in database!'
            commit('status_error', err)
          }
        })
    })
  },
  addUser ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // set user parameters
      var user = payload.user
      const mail = payload.mail
      user.id = this.getters.get_uuid()
      user.created = Date.now()
      // send user password by mail
      if (mail) {
        axios({
          method: 'post',
          url: settings.host,
          params: {
            key: state.auth.key,
            token: state.auth.token,
            function: 'mailUser',
            params: {
              username: user.username,
              password: user.password,
              firstname: user.meta.firstname,
              lastname: user.meta.lastname,
              email: user.meta.email,
              compagny: user.meta.compagny,
              phone: user.meta.contact.phone,
              city: user.meta.contact.city,
              address: user.meta.contact.address,
              zipcode: user.meta.contact.zipcode
            }
          },
          data: ''
        })
          .then(resp => {
            // check header status code
            if (resp.data.status.success) {
              // success
              var mess = 'Mail send to user!'
              commit('status_success', mess)
            } else {
              // error
              var err = 'Failed to send mail to user!'
              commit('status_error', err)
            }
          })
      }
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'createUser',
          params: {
            id: user.id,
            username: user.username,
            password: user.password,
            actived: user.actived,
            token: '',
            role: user.role,
            created: user.created,
            meta: JSON.stringify(user.meta)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('create_user', user)
            var mess = 'User added to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to add user in database!'
            commit('status_error', err)
          }
        })
    })
  },
  editUser ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // set parameters
      var user = payload.user
      const pass = payload.pass
      const mail = payload.mail
      // check for password modification
      // and set the right function name
      var functionName = 'updateUser'
      if (pass) functionName = 'updateUser2'
      // send user password by mail
      if (mail & pass) {
        axios({
          method: 'post',
          url: settings.host,
          params: {
            key: state.auth.key,
            token: state.auth.token,
            function: 'mailUser',
            params: {
              username: user.username,
              password: user.password,
              firstname: user.meta.firstname,
              lastname: user.meta.lastname,
              email: user.meta.email,
              compagny: user.meta.compagny,
              phone: user.meta.contact.phone,
              city: user.meta.contact.city,
              address: user.meta.contact.address,
              zipcode: user.meta.contact.zipcode
            }
          },
          data: ''
        })
          .then(resp => {
            // check header status code
            if (resp.data.status.success) {
              // success
              var mess = 'Mail send to user!'
              commit('status_success', mess)
            } else {
              // error
              var err = 'Failed to send mail to user!'
              commit('status_error', err)
            }
          })
      }
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: functionName,
          params: {
            id: user.id,
            username: user.username,
            password: user.password,
            actived: user.actived,
            token: '',
            role: user.role,
            created: user.created,
            meta: JSON.stringify(user.meta)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('update_user', user)
            var mess = 'User edited to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to edit user in database!'
            commit('status_error', err)
          }
        })
    })
  },
  removeUser ({ commit, state }, user) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'deleteUser',
          params: {
            id: user.id
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('delete_user', user)
            var mess = 'User removed from database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to delete user in database!'
            commit('status_error', err)
          }
        })
    })
  }
}

export default Actions
