<template>
  <section class="interface" v-if="show">
    <Profile/>
    <section class="title">
      <h1><i class="icon-utilisateur"></i>SUPPRESSION UTILISATEUR</h1>
    </section>
    <form @submit.prevent="del()">
    <section class="grid">
      <section class="card">
        <h2>Descriptions</h2>
        <section>
          <p>
            <label class="badge">utilisateur:</label> {{ user.username }}
          </p>
          <p>
            <label class="badge">Nom:</label> {{ user.meta.firstname }} {{ user.meta.lastname }}
          </p>
          <p>
            <label class="badge">Rôle:</label> {{ user.role | role }}
          </p>
          <p>
            <label class="badge">Email:</label> {{ user.meta.email || 'n/a' }}
          </p>
        </section>
      </section>
      <section class="card">
        <p>
          <span class="badge red">Avertissement</span>
        </p>
        <p>
          <small>La suppression entraîne la destruction totale et définitive de l'utilisateur</small>
        </p>
      </section>
      <section class="card">
        <p>
          <button type="submit">SUPPRIMER</button>
        </p>
      </section>
      <section class="card"><small>Data.json</small>
        <pre class="scroll">{{ user }}</pre>
      </section>
    </section>
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'UtilisateurDel',
  components: {
    Profile
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.get_user_by_username(this.$route.params.id)
    }
  },
  methods: {
    del: function () {
      var user = this.user
      this.show = false
      this.$store.dispatch('removeUser', user)
        .then(() => {
          this.$router.push('/utilisateurs')
        })
        .catch()
    }
  }
}
</script>
