<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-account"></i>MODIFIER MON PROFIL</h1>
    </section>
    <form @submit.prevent="edit()">
    <section class="grid add">
      <section class="card new">
        <h2>Informations</h2>
        <section>
          <p>
            <label for="gender">Genre</label>
            <select id="gender" v-model="account.gender">
              <option value="0">Aucun</option>
              <option value="1">Homme</option>
              <option value="2">Femme</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="firstname">Prénom</label>
            <input type="text" id="firstname" v-model="account.firstname" />
          </p>
        </section>
        <section>
          <p>
            <label for="lastname">Nom</label>
            <input type="text" id="lastname" v-model="account.lastname" />
          </p>
          </section>
        <section>
          <p>
            <label for="mail">Email</label>
            <input type="text" id="mail" v-model="account.email" />
          </p>
        </section>
        <section>
          <p>
            <label for="compagny">Société</label>
            <input type="text" id="compagny" v-model="account.compagny" />
          </p>
        </section>
        <section>
          <p>
            <label for="birthday">Date de naissance</label>
            <v-date-picker id="birthday" v-model="account.birthday"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Coordonnées</h2>
        <section>
          <p>
            <label for="adress">Adresse</label>
            <input type="text" id="adress" v-model="account.contact.address" />
          </p>
        </section>
        <section>
          <p>
            <label for="city">Ville</label>
            <input type="text" id="city" v-model="account.contact.city" />
          </p>
        </section>
        <section>
          <p>
            <label for="zip">Code postal</label>
            <input type="text" id="zip" v-model="account.contact.zipcode" />
          </p>
        </section>
        <section>
          <p>
            <label for="phone">Téléphone</label>
            <input type="text" id="phone" v-model="account.contact.phone" />
          </p>
        </section>
        <section>
          <p>
            <label for="mobile">Mobile</label>
            <input type="text" id="mobile" v-model="account.contact.mobile" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Préférences</h2>
        <ul class="statusColors">
          <li><i style="color:var(--grey)">⬤</i> Disponible</li>
          <li><i style="color:var(--yellow)">⬤</i> Optionné</li>
          <li><i style="color:var(--reserved)">⬤</i> Réservé</li>
          <li><i style="color:var(--financed)">⬤</i> Financé</li>
          <li><i style="color:var(--acted)">⬤</i> Acté</li>
        </ul>
        <ul class="column">
          <li>
            <label for="color">
              <input type="checkbox" class="toggle" id="color" v-model="account.menu.color" /> Palette de couleur
            </label>
          </li>
          <li>
            <label for="linecolor">
              <input type="checkbox" class="toggle" id="linecolor" v-model="account.menu.linecolor" /> Remplissage couleur ligne
            </label>
          </li>
          <li>
            <label for="display">
              <input type="checkbox" class="toggle" id="display" v-model="account.menu.display" /> Affichage compact
            </label>
          </li>
          <li>
            <label for="side">
              <input type="checkbox" class="toggle" id="side" v-model="account.menu.side" /> Menu icône
            </label>
          </li>
        </ul>
      </section>
      <!--
      <section class="card"><small>Data.json</small>
        <pre class="scroll">
          {{account}}
        </pre>
      </section>
      -->
    </section>
    <section class="card">
      <p>
        <button type="submit">MODIFIER</button>
      </p>
    </section>
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'ProfilEdit',
  components: {
    Profile
  },
  computed: {
    meta: function () {
      return this.$store.getters.meta
    }
  },
  data () {
    return {
      account: {},
      phone: '',
      mobile: ''
    }
  },
  created () {
    this.account = JSON.parse(JSON.stringify(this.meta))
    this.account.contact.phone = this.$options.filters.phone(this.account.contact.phone)
    this.account.contact.mobile = this.$options.filters.phone(this.account.contact.mobile)
  },
  methods: {
    edit: function () {
      // phone formated to string (ex:#0298571020)
      this.account.contact.phone = '#' + this.account.contact.phone.split('.').join('').split(' ').join('').split('#').join('')
      this.account.contact.mobile = '#' + this.account.contact.mobile.split('.').join('').split(' ').join('').split('#').join('')
      // set update from edit profile
      const payload = {
        login: false,
        meta: this.account
      }
      this.$store.dispatch('updateMyProfile', payload)
        .then(() => {
          this.$store.dispatch('getUser')
            .then(() => {
              this.$store.dispatch('fetchMyProfile')
                .then(() => {
                  this.$router.push('/')
                })
                .catch()
            })
            .catch((err) => { this.messages = err.status })
        })
        .catch()
    }
  }
}
</script>
