const Getters = {
  // Random UUID
  get_uuid: state => () => {
    var a = ''
    for (var b = a; a++ < 36; b += a * 51 & 52 ? (a ^ 15 ? 8 ^ Math.random() * (a ^ 20 ? 16 : 4) : 4).toString(16) : '-') {}
    return b
  },
  // Auth
  isLoggedIn: state => !!state.auth.token,
  cookies: state => !!state.cookies,
  meta: state => {
    return state.user.meta
  },
  me: state => {
    return state.user.username
  },
  lvl: state => {
    return state.user.role
  },
  isAdmin: state => {
    if (state.user.role > 2) return true
    return false
  },
  // Loading
  status: state => state.status,
  // Users
  get_users: state => () => {
    return state.db.users
  },
  get_user_by_username: (state, getters) => name => {
    const USERS = getters.get_users()
    return USERS.find(u => u.username === name)
  },
  get_user_by_id: (state, getters) => uid => {
    const USERS = getters.get_users()
    return USERS.find(u => u.id === uid)
  },
  get_users_last_connected: (state, getters) => {
    const USERS = getters.get_users()
    return USERS.slice().sort(function (a, b) {
      return (a.meta.tech.lastconnection < b.meta.tech.lastconnection) ? 1 : -1
    })
  },
  // Programs
  get_programs: state => () => {
    // order by numero DSC 49,48,47,etc..
    return state.db.programs.slice().sort(function (a, b) {
      return (a.numero < b.numero) ? 1 : -1
    })
  },
  get_program_by_numero: (state, getters) => num => {
    const PROGS = getters.get_programs()
    return PROGS.find(p => p.numero === Number(num))
  },
  get_program_last_numero: (state, getters) => () => {
    const PROGS = getters.get_programs()
    var numero = 0
    for (var i = 0; i < PROGS.length; i++) {
      if (Number(PROGS[i].numero) > numero) numero = Number(PROGS[i].numero)
    }
    return numero
  },
  // Lots
  get_lots: state => () => {
    return state.db.lots
  },
  get_lot_by_uid: (state, getters) => uid => {
    const LOTS = getters.get_lots()
    return LOTS.find(l => l.uid.toString() === uid.toString())
  },
  get_lots_by_program_num: (state, getters) => num => {
    const LOTS = getters.get_lots()
    return LOTS.filter(l => l.program.numero === Number(num))
  },
  get_last_numero_by_program_num: (state, getters) => num => {
    const LOTS = getters.get_lots()
    const PROG_LOTS = LOTS.filter(l => l.program.numero === num)
    var numero = 0
    for (var i = 0; i < PROG_LOTS.length; i++) {
      if (Number(PROG_LOTS[i].numero) > numero) numero = Number(PROG_LOTS[i].numero)
    }
    return numero
  },
  /*
  get_lots_by_expired: (state, getters) => () => {
    const LOTS = getters.get_lots()
    const today = Date.now()
    const exp = 7 * (1000 * 3600 * 24)
    return LOTS.filter(l => l.status === 1 && l.reservation.date < today - exp)
  },
  */
  // reservations
  get_reservations: state => () => {
    return state.db.reservations
  },
  get_reservation_by_numero: (state, getters) => num => {
    const RESERV = getters.get_reservations()
    return RESERV.find(r => r.numero === Number(num))
  },
  get_reservation_last_numero: (state, getters) => () => {
    const RESERV = getters.get_reservations()
    var numero = 0
    for (var i = 0; i < RESERV.length; i++) {
      if (Number(RESERV[i].numero) > numero) numero = Number(RESERV[i].numero)
    }
    return numero
  },
  get_reservations_by_lot_uid: (state, getters) => uid => {
    const RESERV = getters.get_reservations()
    return RESERV.filter(r => r.lot.uid.toString() === uid)
  },
  get_reservations_by_prospect_num: (state, getters) => num => {
    const RESERV = getters.get_reservations()
    const prospect = RESERV.filter(r => r.prospect.numero === Number(num))
    const notary = RESERV.filter(r => r.notary.numero === Number(num))
    const bank = RESERV.filter(r => r.bank.numero === Number(num))
    return prospect.concat(notary).concat(bank)
  },
  get_reservations_by_user_username: (state, getters) => name => {
    const RESERV = getters.get_reservations()
    return RESERV.filter(r => r.user.username === name)
  },
  get_reservation_by_program_num: (state, getters) => num => {
    const RESERV = getters.get_reservations()
    return RESERV.filter(r => r.program.numero === Number(num))
  },
  get_reservations_by_expired: (state, getters) => () => {
    const RESERV = getters.get_reservations()
    const today = Date.now()
    const exp = 7 * (1000 * 3600 * 24)
    return RESERV.filter(r => r.status === 1 && r.created < today - exp)
  },
  // requests
  get_requests: state => () => {
    return state.db.requests
  },
  get_request_by_numero: (state, getters) => num => {
    const REQ = getters.get_requests()
    return REQ.find(r => r.numero === Number(num))
  },
  get_requests_by_standby: (state, getters) => () => {
    const REQ = getters.get_requests()
    return REQ.filter(r => r.status === 0)
  },
  get_requests_standby_by_uid: (state, getters) => uid => {
    const REQ = getters.get_requests()
    return REQ.filter(r => r.lot.uid.toString() === uid && r.status === 0)
  },
  get_request_last_numero: (state, getters) => () => {
    const REQ = getters.get_requests()
    var numero = 0
    for (var i = 0; i < REQ.length; i++) {
      if (Number(REQ[i].numero) > numero) numero = Number(REQ[i].numero)
    }
    return numero
  },
  // Prospects
  get_prospects: state => () => {
    return state.db.prospects
  },
  get_prospect_by_numero: (state, getters) => num => {
    const PROSPS = getters.get_prospects()
    return PROSPS.find(p => p.numero === Number(num))
  },
  get_prospect_last_numero: (state, getters) => () => {
    const PROSPS = getters.get_prospects()
    var numero = 0
    for (var i = 0; i < PROSPS.length; i++) {
      if (Number(PROSPS[i].numero) > numero) numero = Number(PROSPS[i].numero)
    }
    return numero
  },
  // Config
  get_config: state => () => {
    return state.db.config[0]
  },
  get_settings: state => () => {
    return state.db.config[0].settings
  },
  get_admin_email: state => () => {
    return state.db.config[0].settings.email
  },
  get_closed: state => () => {
    return state.db.config[0].settings.closed
  }
}

export default Getters
