<template>
  <section class="dashboard grid">

    <section class="card table by3">
      <small>Stats des réservations (depuis le 1er janvier)</small>
      <ul>
        <li>
          <span><i style="color: var(--grey);">⬤</i></span>
          <span class="bold">{{ reservs_count[0] }}</span>
          <span>Annulé</span>
        </li>
        <li>
          <span><i style="color: var(--yellow);">⬤</i></span>
          <span class="bold">{{ reservs_count[1] }}</span>
          <span>Optionné</span>
        </li>
        <li>
          <span><i style="color: var(--reserved);">⬤</i></span>
          <span class="bold">{{ reservs_count[2] }}</span>
          <span>Réservé</span>
        </li>
        <li>
          <span><i style="color: var(--financed);">⬤</i></span>
          <span class="bold">{{ reservs_count[3] }}</span>
          <span>Financé</span>
        </li>
        <li>
          <span><i style="color: var(--acted);">⬤</i></span>
          <span class="bold">{{ reservs_count[4] }}</span>
          <span>Acté</span>
        </li>
        <li>
          <span><i style="color: var(--st0);">⬤</i></span>
          <span class="bold">{{ reservs_count[5] }}</span>
          <span>n/a</span>
        </li>
      </ul>
    </section>

    <section class="card table by3">
      <small>Derniers partenaires connectés</small>
      <ul>
        <li><label>DATE</label><label>HEURE</label><label>PARTENAIRE</label></li>
      </ul>
      <ul>
        <li v-for="(last, l) in lasts" :key="l">
          <span class="bold">{{ last.meta.tech.lastconnection | shortDate }}</span>
          <span class="bold">{{ last.meta.tech.lastconnection | timeOnly }}</span>
          <span>{{ last.meta.lastname  }} {{ last.meta.firstname | initial }}</span>
        </li>
      </ul>

    </section>

    <section class="card table">
      <small>Dernières mises à jour</small>
      <dl class="scroll verysmall">
        <dt v-for="(log, lo) in settings.logs" :key="lo"> {{ log }} </dt>
      </dl>
    </section>

  </section>
</template>

<script>
import settings from '@/settings.js'
export default {
  name: 'DashboardStatsTotal',
  data () {
    return {
      settings
    }
  },
  computed: {
    lasts: function () {
      return this.$store.getters.get_users_last_connected.slice(0, 5)
    },
    reservs_count: function () {
      // set timestamp of January the first of the Years to Count this years 'acted' reservation
      var januaryFirst = Date.parse(new Date(new Date().getFullYear(), 0, 1))
      var reservs = this.$store.getters.get_reservations().filter(r => r.created > januaryFirst)
      return [
        Object.keys(reservs.filter(r => r.status === 0)).length,
        Object.keys(reservs.filter(r => r.status === 1)).length,
        Object.keys(reservs.filter(r => r.status === 2)).length,
        Object.keys(reservs.filter(r => r.status === 3)).length,
        Object.keys(reservs.filter(r => r.status === 4).filter(r => r.notary.signdate > januaryFirst)).length,
        Object.keys(reservs.filter(r => r.status === 4).filter(r => r.notary.signdate === 0)).length
      ]
    },
    users_count: function () {
      var users = this.$store.getters.get_users()
      users = users.filter(u => u.role === 2)
      return Object.keys(users).length
    }
  }
}
</script>
