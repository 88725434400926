<template>
  <section class="interface" v-if="show">
    <Profile/>
    <section class="title">
      <h1><i class="icon-utilisateur"></i>SUPPRESSION RÉSERVATION</h1>
    </section>
    <form @submit.prevent="del()">
    <section class="grid">
      <section class="card">
        <h2>Descriptions</h2>
        <section>
          <p>
            <label class="badge">Num:</label> {{ reservation.numero }}
          </p>
          <p>
            <label class="badge">Programme:</label> {{ reservation.program.shortname }}
          </p>
          <p>
            <label class="badge">Lot:</label> {{ reservation.lot.numero }}
          </p>
          <p>
            <label class="badge">Utilisateur:</label> {{ reservation.user.firstname }} {{ reservation.user.lastname }}
          </p>
          <p>
            <label class="badge">Prospect:</label> {{ reservation.prospect.firstname }} {{ reservation.prospect.lastname }}
          </p>
          <p>
            <label class="badge">Notaire:</label> {{ reservation.notary.firstname }} {{ reservation.notary.lastname }}
          </p>
          <p>
            <label class="badge">Status:</label> <span class="badge" :class="status(reservation.status)">{{ reservation.status | reservation_status }}</span>
          </p>
        </section>
      </section>
      <section class="card">
        <p>
          <span class="badge red">Avertissement</span>
        </p>
        <p>
          <small>La suppression entraîne la destruction totale et définitive de la réservation</small>
        </p>
      </section>
      <section class="card">
        <p>
          <button type="submit">SUPPRIMER</button>
        </p>
      </section>
      <section class="card"><small>Data.json</small>
        <pre class="scroll">{{ reservation }}</pre>
      </section>
    </section>
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'ReservationDel',
  components: {
    Profile
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    reservation: function () {
      return this.$store.getters.get_reservation_by_numero(this.$route.params.id)
    }
  },
  methods: {
    del: function () {
      var reservation = this.reservation
      this.show = false
      this.$store.dispatch('removeReservation', reservation)
        .then(() => {
          this.$router.push('/reservations')
        })
        .catch()
    },
    status: function (value) {
      return 'st' + value
    }
  }
}
</script>
