<template>
  <main id="app" :class="{'login': login}">
    <loginmess v-if="login"/>
    <sidemenu v-else/>
    <router-view v-if="load" />
    <loading v-else :messages="messages" />
    <errors/>
  </main>
</template>

<script>
import Errors from '@/components/Errors.vue'
import Menu from '@/components/Menu.vue'
import LoginMessage from '@/components/LoginMessage.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'App',
  components: {
    sidemenu: Menu,
    errors: Errors,
    loginmess: LoginMessage,
    loading: Loading
  },
  data () {
    return {
      database: 0,
      databaseCount: 7,
      load: false,
      fetch: false,
      messages: {
        code: '200',
        message: ''
      }
    }
  },
  computed: {
    login: function () {
      return (this.$route.name === 'Login' ? 1 : 0)
    },
    isLogged: function () {
      return this.$store.getters.isLoggedIn
    },
    lvl: function () {
      return this.$store.getters.lvl
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin
    },
    meta: function () {
      return this.$store.getters.meta
    }
  },
  watch: {
    $route: function (to, from) {
      // check for route change from /login to /.
      if (from.name === 'Login' && !this.fetch) {
        // fetching with profile update
        this.fetchingData(true)
      }
      // synchronisation database
      switch (to.name) {
        case 'Logout':
          // reset state if logout
          this.$store.commit('reset_state')
          this.database = 0
          this.fetch = false
          break
        case 'Utilisateurs':
          if (this.fetch) {
            this.$store.dispatch('fetchUsers')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
        case 'Programmes':
          if (this.fetch) {
            this.$store.dispatch('fetchPrograms')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
        case 'ProgrammesLimited':
          if (this.fetch) {
            this.$store.dispatch('fetchProgramsLimited')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
        case 'Lots':
          if (this.fetch) {
            this.$store.dispatch('fetchLots')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
        case 'Prospects':
          if (this.fetch) {
            this.$store.dispatch('fetchProspects')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
        case 'Reservations':
          if (this.fetch) {
            this.$store.dispatch('fetchReservations')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
            this.$store.dispatch('fetchRequests')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
      }
      this.checkAccessLvl()
      // cant use isAdmin for 'some' reason (buffering data) but its the same checkout
      if (this.lvl < 5) this.checkClosed()
    },
    database: function (count) {
      // check if all database are this.fetch
      if (count === this.databaseCount) {
        this.checkAccessLvl()
        if (!this.isAdmin) this.checkClosed()
      }
    }
  },
  created () {
    // check for token in Web storage (DOM)
    if (!this.isLogged) {
      // no token
      this.load = true
    } else {
      // validate token
      this.$store.dispatch('validToken')
        .then(() => {
          // fecth data
          this.fetchingData()
        })
        .catch(() => {
          // token expired
          this.load = true
        })
    }
    window.addEventListener('scroll', this.sticky, { passive: true })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.sticky)
  },
  methods: {
    checkAccessLvl: function () {
      this.load = false
      const route = this.$router.currentRoute.name
      if (route === 'Login') {
        // access allow !exception rule for login
        this.load = true
        return
      }
      if (this.lvl) {
        if (this.$route.meta.lvl > this.lvl) {
          // access denied
          if (route !== 'Restricted') {
            this.$router.push('/restricted')
          }
        } else {
          // access granted
          this.load = true
        }
      }
    },
    checkClosed: function () {
      const route = this.$router.currentRoute.name
      // interface closed by admin
      var closed = this.$store.getters.get_closed()
      if (closed) {
        if (route !== 'Closed') {
          this.$router.push('/closed')
        }
      }
    },
    fetchingData: function (update = false) {
      // get User from DATABASE
      this.$store.dispatch('getUser')
        .then(() => {
          // set profile into STORE
          this.$store.dispatch('fetchMyProfile')
            .then(() => {
              // send update from login
              const payload = {
                login: true,
                meta: {}
              }
              if (update) this.$store.dispatch('updateMyProfile', payload)
              // set user preference
              this.menu()
              this.color()
              this.display()
            })
          // Fetch Others DATABASE FILE after USER
          this.$store.dispatch('fetchConfig')
            .then(() => { this.database++ })
            .catch((err) => { this.messages = err.status })
          // Fetch DATABASE admin
          if (this.isAdmin) {
            this.$store.dispatch('fetchUsers')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
            this.$store.dispatch('fetchPrograms')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
            this.$store.dispatch('fetchLots')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
            this.$store.dispatch('fetchReservations')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
            this.$store.dispatch('fetchRequests')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
            this.$store.dispatch('fetchProspects')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
          // Fetch DATABASE partner
          } else {
            this.$store.dispatch('fetchProgramsLimited')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
            this.$store.dispatch('fetchLotsLimited')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
            this.$store.dispatch('fetchReservationsLimited')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
            // Fake the DATABASE count (users & requests & Prospects)
            this.database++
            this.database++
            this.database++
          }
          this.fetch = true
        })
        .catch((err) => { this.messages = err.status })
    },
    // set size of side menu
    menu: function () {
      const main = document.querySelector('main')
      if (this.meta.menu.side) main.classList.add('menulock')
      else main.classList.remove('menulock')
    },
    // set table text density display
    display: function () {
      const body = document.body
      if (this.meta.menu.display) body.classList.add('compact')
      else body.classList.remove('compact')
    },
    // set original status color
    color: function () {
      const root = document.documentElement
      if (this.meta.menu.color) {
        // dar-blue blue dark-yellow
        root.style.setProperty('--reserved', '#d9b600')
        root.style.setProperty('--financed', '#0254a2')
        root.style.setProperty('--acted', '#01396f')
        root.style.setProperty('--reserved-rgb', '#e6cd5b')
        root.style.setProperty('--financed-rgb', '#b8d2f4')
        root.style.setProperty('--acted-rgb', '#81b2ef')
      } else {
        // red cyan green
        root.style.setProperty('--reserved', '#33d4e8')
        root.style.setProperty('--financed', '#6ddc76')
        root.style.setProperty('--acted', '#f34f7d')
        root.style.setProperty('--reserved-rgb', '#b1eef6')
        root.style.setProperty('--financed-rgb', '#c1f0c5')
        root.style.setProperty('--acted-rgb', '#fcd4df')
      }
    },
    // make list header title visible when scrolling
    sticky: function (event) {
      var value = {
        Lots: 300,
        Utilisateurs: 300,
        Prospects: 300,
        ProgrammeDetails: 850,
        Reservations: 300
      }
      var lb = document.getElementsByClassName('stickyLabel')[0]
      var int = document.getElementsByClassName('interface')[0]
      if (lb) {
        if (window.innerWidth > 1400) {
          var st = window.pageYOffset || document.documentElement.scrollTop
          if (st > value[this.$route.name]) {
            lb.classList.add('on')
            lb.style.maxWidth = int.offsetWidth - 60
          } else {
            lb.classList.remove('on')
            lb.style.maxWidth = 'none'
          }
        } else {
          lb.classList.remove('on')
          lb.style.maxWidth = 'none'
        }
      }
    }
  }
}
</script>
