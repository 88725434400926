<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-prospect"></i>DÉTAILS PROSPECT</h1>
    </section>
    <section class="grid">
      <section class="card">
          <h2>Identité</h2>
          <small class="corner right">
            <router-link tag="i" :to="{ path: '/prospect/edit/'+prospect.numero}" class="icon-edit"></router-link>&nbsp;
            <router-link tag="i" :to="{ path: '/prospect/del/'+prospect.numero}" class="icon-delete"></router-link>
          </small>
          <section>
            <p>
              <label class="badge">Genre:</label> {{ prospect.personal.gender | gender }}
            </p>
            <p v-if="prospect.list !== 2 && prospect.list !== 4">
              <label class="badge">Prénom:</label> {{ prospect.firstname }}
            </p>
            <p>
              <label class="badge">Nom:</label> {{ prospect.lastname }}
            </p>
            <p v-if="prospect.list === 2">
              <label class="badge">Gérant:</label> {{ prospect.firstname }}
            </p>
            <p>
              <label class="badge">Email:</label> {{ prospect.email }}
            </p>
            <p>
              <label class="badge">liste:</label> {{ prospect.list | prospect_list }}
            </p>
             <p>
              <label class="badge">Date de naissance:</label> {{ prospect.personal.birthday |longDate }} ({{ prospect.personal.age }})
            </p>
          </section>
      </section>
      <section class="card">
          <h2>Contact</h2>
          <section>
            <p>
              <label class="badge">Adresse:</label> {{ prospect.contact.address }}
            </p>
            <p>
              <label class="badge">Ville:</label> {{ prospect.contact.city }}
            </p>
            <p>
              <label class="badge">Code postal:</label> {{ prospect.contact.zipcode }}
            </p>
            <p>
              <label class="badge">Téléphone:</label> {{ prospect.contact.phone | phone }}
            </p>
            <p>
              <label class="badge">Mobile:</label> {{ prospect.contact.mobile | phone }}
            </p>
          </section>
      </section>
      <section class="card">
          <h2>Informations</h2>
          <section>
            <p>
              <label class="badge">Programme:</label> {{ prospect.program.shortname }}
            </p>
            <p>
              <label class="badge">Lot type:</label> {{ prospect.program.type | type }}
            </p>
            <p>
              <label class="badge">Budget:</label> {{ prospect.program.budget }} €
            </p>
          </section>
      </section>
      <section class="card"><small>Data.json</small>
        <pre class="scroll">{{ prospect }}</pre>
      </section>
    </section>
    <section class="title">
      <h1><i class="icon-reservation"></i>RÉSERVATIONS</h1>
    </section>
    <ReservationsList :reservations="reservations" />
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import ReservationsList from '@/components/ReservationsList.vue'

export default {
  name: 'ProspectDetails',
  components: {
    Profile,
    ReservationsList
  },
  computed: {
    prospect: function () {
      return this.$store.getters.get_prospect_by_numero(this.$route.params.id)
    },
    reservations: function () {
      return this.$store.getters.get_reservations_by_prospect_num(this.$route.params.id)
    }
  },
  data () {
    return {
    }
  }
}
</script>
