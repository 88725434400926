<template>
  <section class="prospects table">
    <ul>
      <li class="stickyLabel">
        <label class="sort reset" @click="reset()">NUM</label>
        <label title="Type de prospect">LIST</label>
        <label class="sort" :class="classActive('lastname')" @click="sortby('lastname')">NOM</label>
        <label>EMAIL</label>
        <label class="sort" :class="classActive('city')" @click="sortby('city', 'contact')">VILLE</label>
        <label>TEL</label>
        <label title="Programme privilégier">PROG</label>
        <label>ACT</label>
      </li>
    </ul>
    <ul>
      <li class="card td" v-for="(prospect, l) in sorted" :key="l" v-on:dblclick="router(prospect.numero)">
        <span>{{ prospect.numero }}</span>
        <span class="bold">{{ prospect.list | prospect_list }}</span>
        <span>{{ prospect.lastname.toUpperCase() }} {{ prospect.firstname }}</span>
        <span class="small" :title="prospect.email">{{ prospect.email || 'n/a'  | shortEmail }}</span>
        <span class="bold">{{ prospect.contact.city || 'n/a' }}</span>
        <span class="small">{{ prospect.contact.phone|| 'n/a' | phone }}</span>
        <span class="bold"><router-link v-if="prospect.program.numero" :to="{ path: '/programme/details/'+prospect.program.numero }">{{ prospect.program.shortname }}</router-link><em v-else>n/a</em></span>
        <span>
          <router-link tag="i" :to="{ path: '/prospect/details/'+prospect.numero }" class="icon-see"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/prospect/edit/'+prospect.numero }" class="icon-edit"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/prospect/del/'+prospect.numero }" class="icon-delete"></router-link>
        </span>
      </li>
    </ul>
    <p v-if="prospects.length === 0" class="card nodata">Aucune donnée n’est disponible</p>
    <p class="loadmore" v-if="limit < prospects.length">
      <button @click="more()">Afficher plus</button>
    </p>
  </section>
</template>

<script>
export default {
  name: 'prospectsList',
  props: {
    prospects: Array
  },
  data () {
    return {
      dir: true,
      nested: '',
      sort: 'numero',
      limit: 50
    }
  },
  computed: {
    sorted: function () {
      var dir = this.dir
      var sort = this.sort
      var nest = this.nested
      return this.prospects.slice().sort(function (a, b) {
        if (nest === '') {
          if (dir) return (a[sort] > b[sort]) ? 1 : -1
          return (a[sort] < b[sort]) ? 1 : -1
        } else {
          if (dir) return (a[nest][sort] > b[nest][sort]) ? 1 : -1
          return (a[nest][sort] < b[nest][sort]) ? 1 : -1
        }
      }).slice(0, this.limit)
    }
  },
  methods: {
    sortby: function (value, nest = '') {
      if (this.sort === value) {
        if (this.dir) this.dir = false
        else this.dir = true
      } else {
        this.sort = value
        this.nested = nest
      }
    },
    reset: function () {
      this.sort = 'numero'
      this.dir = true
    },
    classActive: function (value) {
      var classe = ''
      if (this.sort === value) classe = 'active'
      if (this.dir === false) classe += ' dir'
      return classe
    },
    router: function (num) {
      this.$router.push('/prospect/details/' + num)
    },
    more: function () {
      this.limit += 50
    }
  }
}
</script>
