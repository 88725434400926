<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-lot"></i>LOTS</h1>
    </section>
    <section class="lots filter">
      <ul>
        <li class="filterchoice" :class="{'active' : filteredA > 0}">
          <select v-model="filteredA" @change="handleNumero(filteredA)">
            <option value="-1">Programmes: Tous</option>
            <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice" v-if="Number(this.filteredA) < 0">
          <select v-model="filteredAA">
            <option value="-1">Commercialisation: Tous</option>
            <option v-for="(status, s) in settings.program.status" :value="s" :key="s">{{ status }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice" v-if="Number(this.filteredA) < 0">
          <select v-model="filteredAAA">
            <option value="-1">Régions: Toutes</option>
            <option v-for="(region, r) in settings.program.region" :value="r" :key="r">{{ region }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
      </ul>
      <ul>
        <li>&nbsp;</li>
      </ul>
    </section>
    <section class="lots filter">
      <ul>
        <li class="filterchoice">
          <select v-model="filteredB">
            <option value="-1">Type: Tous</option>
            <option v-for="(type, t) in settings.lot.type" :value="t" :key="t">{{ type }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredC">
            <option value="-1">Statut: Tous</option>
            <option v-for="(status, s) in settings.lot.status" :value="s" :key="s">{{ status }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredD">
            <option value="-1">Dispositifs: Tous</option>
            <option v-for="(law, l) in config.laws" :value="l" :key="l">{{ law }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredE">
            <option value="-1">Prix: Tous</option>
            <option v-for="(range, r) in settings.lot.ranges" :value="r" :key="r">{{ range.text }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredF">
            <option value="-1">Defisc: Toutes</option>
            <option v-for="(def, d) in settings.lot.defisc" :value="d" :key="d">{{ def.text }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
      </ul>
      <ul>
        <li class="filtersearch"><input v-model="search" type="text" placeholder="Recherche"/><i class="icon-search"></i></li>
        <router-link v-if="lvl > 3" tag="li" to="/lot/new" class="filteradd"><i class="icon-add"></i> Ajouter un lot</router-link>
        <router-link v-if="lvl > 3" tag="li" to="/lot/import" class="filteradd"><i class="icon-add"></i> Importer</router-link>
        <li class="filteradd" @click="exportXLS()"><i class="bold">🗋</i> Exporter</li>
      </ul>
    </section>

    <LotsStatusBar v-if="filteredA > 0" :numero="filteredA" />

    <LotsList :lots="lotSearch" />

  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import LotsStatusBar from '@/components/LotsStatusBar.vue'
import LotsList from '@/components/LotsList.vue'
import settings from '@/settings.js'

export default {
  name: 'Lots',
  components: {
    Profile,
    LotsStatusBar,
    LotsList
  },
  data () {
    return {
      settings,
      search: '',
      filteredA: -1,
      filteredAA: -1,
      filteredAAA: -1,
      filteredB: -1,
      filteredC: -1,
      filteredD: -1,
      filteredE: -1,
      filteredF: -1
    }
  },
  created () {
    this.filteredA = this.numero
  },
  computed: {
    numero: function () {
      return this.$store.getters.meta.menu.numero
    },
    programs: function () {
      return this.$store.getters.get_programs()
    },
    lvl: function () {
      return this.$store.getters.lvl
    },
    config: function () {
      return this.$store.getters.get_settings()
    },
    lots: function () {
      return this.$store.getters.get_lots()
    },
    lotProgram: function () {
      var fil = Number(this.filteredA)
      if (fil === -1) return this.lots
      return this.lots.filter(el => el.program.numero === fil)
    },
    lotProgramStatus: function () {
      if (Number(this.filteredA) > -1) return this.lotProgram
      var fil = Number(this.filteredAA)
      if (fil === -1) return this.lotProgram
      var programsFilter = this.programs.filter(el => el.status === fil)
      var lotsFilters = []
      programsFilter.forEach(p => {
        var num = p.numero
        lotsFilters = lotsFilters.concat(this.lots.filter(el => el.program.numero === num))
      })
      return lotsFilters
    },
    lotProgramRegion: function () {
      if (Number(this.filteredA) > -1) return this.lotProgram
      var fil = Number(this.filteredAAA)
      if (fil === -1) return this.lotProgramStatus
      var programsFilter = this.programs.filter(el => el.location.region === fil)
      var lotsFilters = []
      programsFilter.forEach(p => {
        var num = p.numero
        lotsFilters = lotsFilters.concat(this.lotProgramStatus.filter(el => el.program.numero === num))
      })
      return lotsFilters
    },
    lotType: function () {
      var fil = Number(this.filteredB)
      if (fil === -1) return this.lotProgramRegion
      return this.lotProgramRegion.filter(el => el.spec.type === fil)
    },
    lotStatus: function () {
      var fil = Number(this.filteredC)
      if (fil === -1) return this.lotType
      return this.lotType.filter(el => el.status === fil)
    },
    lotLaw: function () {
      var fil = Number(this.filteredD)
      if (fil === -1) return this.lotStatus
      return this.lotStatus.filter(el => el.law === fil)
    },
    lotRange: function () {
      var fil = Number(this.filteredE)
      var range = this.settings.lot.ranges
      if (fil === -1) return this.lotLaw
      return this.lotLaw.filter(
        el => el.spec.price <= range[fil].max && el.spec.price >= range[fil].min)
    },
    lotDefisc: function () {
      var fil = Number(this.filteredF)
      var range = this.settings.lot.defisc
      if (fil === -1) return this.lotRange
      return this.lotRange.filter(
        el => el.spec.defisc <= range[fil].max && el.spec.price >= range[fil].min)
    },
    lotSearch: function () {
      if (this.search) {
        return this.lotDefisc.filter((item) => {
          return this.search.toLowerCase().split(' ').every(v =>
            item.numero.toString().includes(v) ||
            item.uid.toString().toLowerCase().includes(v)
          )
        })
      }
      return this.lotDefisc
    }
  },
  methods: {
    handleNumero: function (value) {
      this.$store.commit('numero_program', value)
    },
    exportXLS: function () {
      var payload = this.createRow()
      this.createFile(payload)
    },
    createRow: function () {
      var data = []
      var lots = this.lotRange.slice().sort(function (a, b) {
        return (a.program.numero < b.program.numero) ? 1 : -1
      })
      lots.forEach(l => {
        var prog = this.$store.getters.get_program_by_numero(l.program.numero)
        // no program found in database
        if (!prog) {
          prog = {
            shortname: '[DEL] ' + l.program.shortname,
            numero: l.program.numero,
            launch: 0,
            delivery: 0,
            location: {
              city: 'N/A'
            }
          }
        }
        // init var
        var associate = false
        var row = {
          programme: prog.shortname + '(' + prog.numero + ')',
          date_com: this.$options.filters.shortDate(prog.launch),
          lot: l.spec.building + '' + l.numero,
          status: '',
          partenaire_nom: '',
          client_genre: '',
          client_nom: '',
          client_prenom: '',
          client_email: '',
          client_adresse: '',
          client_ville: '',
          client_cp: '',
          client_tel: '',
          client_mobile: '',
          prix: l.spec.price,
          prix_final: '',
          frais_not: l.spec.notaryfees,
          date_option: '',
          date_resa: '',
          date_finance: '',
          date_acte: '',
          date_annule: '',
          loi: this.config.laws[l.law],
          date_liv: this.$options.filters.shortDate(prog.delivery),
          type: this.$options.filters.type(l.spec.type),
          etage: l.spec.floor,
          surf_hab: l.spec.size,
          var: l.spec.varangue,
          surf_total: '',
          surf_defis: l.spec.total,
          ville: prog.location.city
        }
        var reserv = this.$store.getters.get_reservations_by_lot_uid(l.uid.toString())
        // .filter(r => r.status > 0)
        if (reserv.length > 0) {
          for (var i = 0; i < reserv.length; i++) {
            row.status = this.$options.filters.reservation_status(reserv[i].status)
            var prospect = this.$store.getters.get_prospect_by_numero(reserv[i].prospect.numero)
            var user = this.$store.getters.get_user_by_username(reserv[i].user.username)
            var partnerName = reserv[i].user.username
            if (user) partnerName = user.meta.lastname
            row.partenaire_nom = partnerName
            row.client_genre = this.$options.filters.gender(prospect.personal.gender)
            row.client_nom = prospect.lastname
            row.client_prenom = prospect.firstname
            row.client_email = prospect.email
            row.client_adresse = prospect.contact.address
            row.client_ville = prospect.contact.city
            row.client_cp = prospect.contact.zipcode
            row.client_tel = this.$options.filters.phone(prospect.contact.phone)
            row.client_mobile = this.$options.filters.phone(prospect.contact.mobile)
            row.date_option = this.$options.filters.shortDate(reserv[i].lot.dateoption)
            row.date_resa = this.$options.filters.shortDate(reserv[i].lot.datereserv)
            row.date_finance = this.$options.filters.shortDate(reserv[i].lot.datefinanced)
            row.date_acte = this.$options.filters.shortDate(reserv[i].lot.dateacted)
            row.date_annule = this.$options.filters.shortDate(reserv[i].lot.datecancel)
            row.prix_final = reserv[i].lot.actedprice
            // if associate
            if (reserv[i].prospect.numero2) {
              associate = true
              var prospect2 = this.$store.getters.get_prospect_by_numero(reserv[i].prospect.numero2)
              var row2 = {
                programme: '',
                date_com: '',
                lot: '',
                status: '',
                partenaire_nom: '',
                client_genre: this.$options.filters.gender(prospect2.personal.gender),
                client_nom: prospect2.lastname,
                client_prenom: prospect2.firstname,
                client_email: prospect2.email,
                client_adresse: prospect2.contact.address,
                client_ville: prospect2.contact.city,
                client_cp: prospect2.contact.zipcode,
                client_tel: this.$options.filters.phone(prospect2.contact.phone),
                client_mobile: this.$options.filters.phone(prospect2.contact.mobile),
                prix: l.spec.price,
                prix_final: '',
                frais_not: '',
                date_option: '',
                date_resa: '',
                date_finance: '',
                date_acte: '',
                date_annule: '',
                loi: '',
                date_liv: '',
                type: '',
                etage: '',
                surf_hab: '',
                var: '',
                surf_total: '',
                surf_defis: '',
                ville: ''
              }
            }
          }
        }
        data.push(row)
        if (associate) data.push(row2)
      })
      return (data)
    },
    createFile: function (data) {
      // If data is not an object then JSON.parse will parse the JSON string in an Object
      var arrData = typeof data !== 'object' ? JSON.parse(data) : data
      var CSV = 'sep=,' + '\r\n'
      var label = ''
      for (var indexLabel in arrData[0]) {
        label += indexLabel + ','
      }
      label = label.slice(0, -1)
      CSV += label + '\r\n'
      for (var i = 0; i < arrData.length; i++) {
        var row = ''
        for (var index in arrData[i]) {
          row += '"' + arrData[i][index] + '",'
        }
        row.slice(0, row.length - 1)
        CSV += row + '\r\n'
      }
      // Initialize file format you want csv or xls
      const a = document.createElement('a')
      const today = this.$options.filters.shortDate(Date.now())
      a.href = 'data:text/csvcharset=utf-8,' + escape(CSV)
      a.style = 'visibility:hidden'
      a.download = 'Export-Clients-Global-Lots-' + today + '.csv'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>
