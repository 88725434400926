import Vue from 'vue'
import VCalendar from 'v-calendar'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/filters.js'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// v-calendar setup
Vue.use(VCalendar)
Vue.component('date-picker', DatePicker)
