<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-programme"></i>PROGRAMMES</h1>
    </section>
    <section class="programs filter">
      <ul>
        <li class="filterchoice">
          <select v-model="filteredA" @change="updatelist($event)">
            <option value="-1">Commercialisation: Tous</option>
            <option v-for="(status, s) in settings.program.status" :value="s" :key="s">{{ status }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredB" @change="updatelist($event)">
            <option value="-1">Régions: Toutes</option>
            <option v-for="(region, r) in settings.program.region" :value="r" :key="r">{{ region }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredC" @change="updatelist($event)">
            <option value="-1">Dispositifs: Tous</option>
            <option v-for="(law, l) in config.laws" :value="l" :key="l">{{ law }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice" v-if="list < 2" @change="updatelist($event)">
          <select v-model="dir">
            <option value="0">Date: Les plus récents</option>
            <option value="1">Chronologie</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterlist" :class="{'active': list === 0}" @click="switchList(0)"><i class="icon-grid"></i></li>
        <li class="filterlist" :class="{'active': list === 1}" @click="switchList(1)"><i class="icon-table">☰</i></li>
        <li class="filterlist" :class="{'active': list === 2}" @click="switchList(2)"><i class="icon-list"></i></li>
      </ul>
      <ul>
        <li class="filtersearch"><input v-model="search" type="text" placeholder="Recherche"/><i class="icon-search"></i></li>
        <router-link v-if="lvl > 3" tag="li" to="/programme/new" class="filteradd"><i class="icon-add"></i> Ajouter un programme</router-link>
        <li class="filteradd" @click="menuExp = (menuExp ? 0 : 1)"><i class="bold">🗋</i> Exporter</li>
      </ul>
    </section>

    <section class="programs" v-if="menuExp">
      <ul class="exportList">
        <li class="list">
          <label v-for="(prog, pr) in programsExportlist" :key="pr">
            <input type="checkbox" v-model="prog.selected"> {{prog.numero}} - {{prog.name}}
          </label>
        </li>
        <li>
          <span class="small">Nombre de programme(s) dans la liste d'export: {{ programsExportNumber() }}</span>
        </li>
        <li>
          <button @click="exportXLS()" class="btn">TÉLÉCHARGER</button>
        </li>
      </ul>
    </section>

    <section v-if="list != 2" class="programs grid">

      <section :class="{'list': list === 1,
       'card': list === 0}" v-for="prog in programSearch" :key="prog.id">
        <ProgrammesCard v-if="list === 0" v-bind:prog="prog"/>
        <ProgrammesSmallCard v-if="list === 1" v-bind:prog="prog"/>
      </section>
    </section>

    <p v-if="programSearch.length === 0 && list !== 2" class="card nodata">
      Aucune donnée n’est disponible
    </p>

    <ProgrammesList v-if="list === 2" v-bind:progs="programSearch"/>

  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import ProgrammesCard from '@/components/ProgrammesCard.vue'
import ProgrammesSmallCard from '@/components/ProgrammesSmallCard.vue'
import ProgrammesList from '@/components/ProgrammesList.vue'
import settings from '@/settings.js'

export default {
  name: 'Programmes',
  components: {
    Profile,
    ProgrammesCard,
    ProgrammesSmallCard,
    ProgrammesList
  },
  data () {
    return {
      settings,
      sort: 'numero',
      dir: 0,
      search: '',
      filteredA: -1,
      filteredB: -1,
      filteredC: -1,
      menuExp: false,
      programsExportlist: []
    }
  },
  computed: {
    list: function () {
      return this.$store.getters.meta.menu.program
    },
    programs: function () {
      return this.$store.getters.get_programs()
    },
    lvl: function () {
      return this.$store.getters.lvl
    },
    config: function () {
      return this.$store.getters.get_settings()
    },
    sorted: function () {
      var sort = this.sort
      var dir = Number(this.dir)
      return this.programs.slice().sort(function (a, b) {
        if (dir) return (a[sort] > b[sort]) ? 1 : -1
        return (a[sort] < b[sort]) ? 1 : -1
      })
    },
    programStatus: function () {
      var fil = Number(this.filteredA)
      if (fil === -1) return this.sorted
      return this.sorted.filter(el => el.status === fil)
    },
    programRegion: function () {
      var fil = Number(this.filteredB)
      if (fil === -1) return this.programStatus
      return this.programStatus.filter(el => el.location.region === fil)
    },
    programLaw: function () {
      var fil = Number(this.filteredC)
      if (fil === -1) return this.programRegion
      return this.programRegion.filter(el => el.laws.includes(fil))
    },
    programSearch: function () {
      if (this.search) {
        return this.programLaw.filter((item) => {
          return this.search.toLowerCase().split(' ').every(v =>
            item.numero.toString().includes(v) ||
            item.name.toLowerCase().includes(v) ||
            item.location.city.toLowerCase().includes(v) ||
            item.location.district.toLowerCase().includes(v)
          )
        })
      }
      return this.programLaw
    },
    programExport: function () {
      var result = []
      this.programsExportlist.forEach(p => {
        if (p.selected) {
          result.push(this.programs.find(el => el.numero === p.numero))
        }
      })
      return result
    }
  },
  created () {
    this.updatelist()
  },
  methods: {
    switchList: function (value) {
      this.$store.commit('program_list', value)
    },
    exportXLS: function () {
      var payload = []
      this.programExport.forEach(p => {
        var row = this.createRow(p)
        payload = payload.concat(row)
      })
      this.createFile(payload)
    },
    createRow: function (prog) {
      var data = []
      var programlots = this.$store.getters.get_lots_by_program_num(prog.numero)
      programlots.forEach(l => {
        // init var
        var associate = false
        var row = {
          programme: prog.shortname + '(' + prog.numero + ')',
          date_com: this.$options.filters.shortDate(prog.launch),
          lot: l.spec.building + '' + l.numero,
          status: '',
          partenaire_nom: '',
          client_genre: '',
          client_nom: '',
          client_prenom: '',
          client_email: '',
          client_adresse: '',
          client_ville: '',
          client_cp: '',
          client_tel: '',
          client_mobile: '',
          prix: l.spec.price,
          prix_final: '',
          frais_not: l.spec.notaryfees,
          date_option: '',
          date_resa: '',
          date_finance: '',
          date_acte: '',
          date_annule: '',
          loi: this.config.laws[l.law],
          date_liv: this.$options.filters.shortDate(prog.delivery),
          type: this.$options.filters.type(l.spec.type),
          etage: l.spec.floor,
          surf_hab: l.spec.size,
          var: l.spec.varangue,
          surf_total: '',
          surf_defis: l.spec.total,
          ville: prog.location.city
        }
        var reserv = this.$store.getters.get_reservations_by_lot_uid(l.uid.toString())
        // .filter(r => r.status > 0)
        if (reserv.length > 0) {
          for (var i = 0; i < reserv.length; i++) {
            row.status = this.$options.filters.reservation_status(reserv[i].status)
            var prospect = this.$store.getters.get_prospect_by_numero(reserv[i].prospect.numero)
            var user = this.$store.getters.get_user_by_username(reserv[i].user.username)
            var partnerName = reserv[i].user.username
            if (user) partnerName = user.meta.lastname
            row.partenaire_nom = partnerName
            row.client_genre = this.$options.filters.gender(prospect.personal.gender)
            row.client_nom = prospect.lastname
            row.client_prenom = prospect.firstname
            row.client_email = prospect.email
            row.client_adresse = prospect.contact.address
            row.client_ville = prospect.contact.city
            row.client_cp = prospect.contact.zipcode
            row.client_tel = this.$options.filters.phone(prospect.contact.phone)
            row.client_mobile = this.$options.filters.phone(prospect.contact.mobile)
            row.date_option = this.$options.filters.shortDate(reserv[i].lot.dateoption)
            row.date_resa = this.$options.filters.shortDate(reserv[i].lot.datereserv)
            row.date_finance = this.$options.filters.shortDate(reserv[i].lot.datefinanced)
            row.date_acte = this.$options.filters.shortDate(reserv[i].lot.dateacted)
            row.date_annule = this.$options.filters.shortDate(reserv[i].lot.datecancel)
            row.prix_final = reserv[i].lot.actedprice
            // if associate
            if (reserv[i].prospect.numero2) {
              associate = true
              var prospect2 = this.$store.getters.get_prospect_by_numero(reserv[i].prospect.numero2)
              var row2 = {
                programme: '',
                date_com: '',
                lot: '',
                status: '',
                partenaire_nom: '',
                client_genre: this.$options.filters.gender(prospect2.personal.gender),
                client_nom: prospect2.lastname,
                client_prenom: prospect2.firstname,
                client_email: prospect2.email,
                client_adresse: prospect2.contact.address,
                client_ville: prospect2.contact.city,
                client_cp: prospect2.contact.zipcode,
                client_tel: this.$options.filters.phone(prospect2.contact.phone),
                client_mobile: this.$options.filters.phone(prospect2.contact.mobile),
                prix: l.spec.price,
                prix_final: '',
                frais_not: '',
                date_option: '',
                date_resa: '',
                date_finance: '',
                date_acte: '',
                date_annule: '',
                loi: '',
                date_liv: '',
                type: '',
                etage: '',
                surf_hab: '',
                var: '',
                surf_total: '',
                surf_defis: '',
                ville: ''
              }
            }
          }
        }
        data.push(row)
        if (associate) data.push(row2)
      })
      return (data)
    },
    createFile: function (data) {
      // If data is not an object then JSON.parse will parse the JSON string in an Object
      var arrData = typeof data !== 'object' ? JSON.parse(data) : data
      var CSV = 'sep=,' + '\r\n'
      var label = ''
      for (var indexLabel in arrData[0]) {
        label += indexLabel + ','
      }
      label = label.slice(0, -1)
      CSV += label + '\r\n'
      for (var i = 0; i < arrData.length; i++) {
        var row = ''
        for (var index in arrData[i]) {
          row += '"' + arrData[i][index] + '",'
        }
        row.slice(0, row.length - 1)
        CSV += row + '\r\n'
      }
      // Initialize file format you want csv or xls
      const a = document.createElement('a')
      const today = this.$options.filters.shortDate(Date.now())
      a.href = 'data:text/csvcharset=utf-8,' + escape(CSV)
      a.style = 'visibility:hidden'
      a.download = 'Export-Clients-Global-Programmes-' + today + '.csv'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    updatelist: function () {
      this.programsExportlist = []
      this.programLaw.forEach(p => {
        if (p.numero > 0) {
          this.programsExportlist.push(
            {
              numero: p.numero,
              name: p.name,
              selected: true
            }
          )
        }
      })
    },
    programsExportNumber: function () {
      return this.programsExportlist.filter(el => el.selected === true).length
    }
  }
}
</script>
