import Login from '@/components/Login.vue'
import Logout from '@/components/Logout.vue'

const auth = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      noRestrictions: true,
      lvl: 0
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: { lvl: 1 }
  }
]

export default auth
