import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/'

// Import routes files
import routeDefault from './routes/default.js'
import routeAuth from './routes/auth.js'
import routePrograms from './routes/programs.js'
import routeLots from './routes/lots.js'
import routeRequests from './routes/requests.js'
import routeReservations from './routes/reservations.js'
import routeUsers from './routes/users.js'
import routeProspects from './routes/prospects.js'

Vue.use(VueRouter)

// Merge all route together
const routes = routeDefault
  .concat(routeAuth)
  .concat(routePrograms)
  .concat(routeLots)
  .concat(routeRequests)
  .concat(routeReservations)
  .concat(routeUsers)
  .concat(routeProspects)

const router = new VueRouter({
  scrollBehavior () { return { x: 0, y: 0 } },
  mode: 'history',
  base: process.env.BASE_URL,
  // base: 'oceanic/2021/05/',
  routes,
  linkExactActiveClass: 'active',
  linkActiveClass: ''
})

router.beforeEach((to, from, next) => {
  // check for unrestricted page access
  if (to.matched.some(record => record.meta.noRestrictions)) {
    // check for token in Web storage (DOM)
    if (store.getters.isLoggedIn) {
      // go to root (already logged)
      next('/')
    } else {
      // access granted
      next()
    }
  } else if (store.getters.isLoggedIn) {
    // restricted page & validate token
    store.dispatch('validToken')
      .then(() => {
        next()
      })
      .catch(() => {
        // token expired or invalid
        next('/login')
      })
  } else {
    // no token found, go to login page
    next('/login')
  }
})

export default router
