<template>
  <section class="card">
    <span class="badge red small">Le fomulaire est invalide ou incomplet</span>
    <p>
      <small>Veuillez renseigner tous les champs obligatoires (indiquer par un <sup class="textred">*</sup> astérisque) pour valider le formulaire</small>
    </p>
  </section>
</template>

<script>

export default {
  name: 'InvalidForm',
  data () {
    return {
    }
  }
}
</script>
