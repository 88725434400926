<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-alert"></i>ÉDITION DEMANDE</h1>
    </section>
    <form @submit.prevent="edit()">
    <section class="grid add">
      <section class="card new">
        <h2>Programme <sup class="textred">*</sup></h2>
        <section>
          <figure><img alt="" :src="image(program.thumbnail || '')" /></figure>
          <p>
            <span class="badge">Nom:</span> {{ program.name }}
          </p>
          <p>
            <span class="badge">Ville:</span> {{ program.location.city }}
          </p>
          <p>
            <span class="badge">Quartier:</span> {{ program.location.district }}
          </p>
          <p>
            <span class="badge">Livraison:</span> {{ Number(program.dat) | yearDate }}
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Lot <sup class="textred">*</sup></h2>
        <section>
          <p>
            <span class="badge">Numéro lot:</span> {{ lot.numero }}
          </p>
          <p>
            <span class="badge">Infos:</span> {{ lot.spec.type | type }} ({{ lot.spec.floor | floor }}) {{ lot.spec.size || '0'  }} m²
          </p>
          <p>
            <span class="badge">Prix:</span> {{ lot.spec.price.toLocaleString()  || '0' }} €
          </p>
          <p>
            <span class="badge">Statut:</span> <span class="badge" :class="status(lot.status)">{{ lot.status | lot_status }}</span>
          </p>
          <p>
              <label for="stat">Statut</label>
              <select id="stat" v-model="request.status">
                <option v-for="(req, r) in settings.request.status" :value="r" :key="r">{{ req }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
            <p>
              <label for="law">Dispositif de loi</label>
              <select id="law" v-model="request.lot.law">
                <option v-for="(law, l) in settings.lot.law" :value="l" :key="l">{{ law }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
            <p>
              <label for="law">Année de défiscalisation</label>
              <select id="law" v-model="request.lot.lawyears">
                <option v-for="(years, y) in settings.lot.lawyears" :value="y" :key="y">{{ years }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
        </section>
      </section>
      <section class="card new">
        <h2>Paramètres</h2>
         <section>
          <p>
            <label for="num">Numéro (auto)</label>
            <input type="text" id="num" v-model="request.numero" disabled />
          </p>
        </section>
        <section>
          <p>
            <label for="created">Date de création</label>
            <input type="text" id="created" disabled :value="request.created | shortDate" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Partenaire <sup class="textred">*</sup></h2>

        <findUser :user="request.user.username" @send="setUser" />

      </section>
      <section class="card new">
        <h2>Client</h2>

        <findProspect :prospect="request.prospect.numero" :filter="0" :type="'prospect'" @send="setProspect" />

      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ request }}</pre>
      </section>
    </section>
    <section v-if="validate()" class="card">
      <p>
        <button type="submit">ÉDITER</button>
      </p>
    </section>
    <InvalidForm v-else />
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import FindUser from '@/components/FindUser.vue'
import FindProspect from '@/components/FindProspect.vue'
import { image } from '@/helpers.js'
import settings from '@/settings.js'

export default {
  name: 'DemandeEdit',
  components: {
    Profile,
    InvalidForm,
    FindUser,
    FindProspect
  },
  data () {
    return {
      settings,
      searchB: '',
      prospect: {
        numero: '',
        data: {}
      },
      request: {}
    }
  },
  computed: {
    programs: function () {
      return this.$store.getters.get_programs()
    },
    program: function () {
      return this.$store.getters.get_program_by_numero(this.request.program.numero)
    },
    lot: function () {
      return this.$store.getters.get_lot_by_uid(this.request.lot.uid)
    },
    req: function () {
      return this.$store.getters.get_request_by_numero(this.$route.params.id)
    },
    // utilisateurs
    users: function () {
      return this.$store.getters.get_users()
    },
    // prospects
    prospects: function () {
      return this.$store.getters.get_prospects()
    }
  },
  created () {
    this.request = this.req
  },
  methods: {
    edit: function () {
      const request = this.request
      this.$store.dispatch('editRequest', request)
        .then(() => {
          this.$router.push('/reservations')
        })
        .catch()
    },
    setUser: function (user) {
      if (user !== '') {
        var data = this.users.find(u => u.username === user)
        this.request.user.id = data.id
        this.request.user.username = user
        this.request.user.firstname = data.meta.firstname
        this.request.user.lastname = data.meta.lastname
      } else {
        this.request.user.id = ''
        this.request.user.username = ''
        this.request.user.firstname = ''
        this.request.user.lastname = ''
      }
    },
    setProspect: function (numero, type) {
      if (numero > 0) {
        var data = this.prospects.find(p => Number(p.numero) === numero)
        this.request[type].id = data.id
        this.request[type].numero = numero
        this.request[type].firstname = data.firstname
        this.request[type].lastname = data.lastname
        this.request[type].email = data.email
        this.request[type].phone = data.contact.phone
      } else {
        this.request[type].id = ''
        this.request[type].numero = 0
        this.request[type].firstname = ''
        this.request[type].lastname = ''
        this.request[type].email = ''
        this.request[type].phone = ''
      }
    },
    status: function (value) {
      return 'st' + value
    },
    validate: function () {
      if (!this.request.lot.numero) return false
      if (this.request.user.username === '') return false
      return true
    },
    image
  }
}
</script>
