<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1 v-if="copy"><i class="icon-lot"></i>DUPLICATION LOT</h1>
      <h1 v-else><i class="icon-lot"></i>NOUVEAU LOT</h1>
    </section>
    <form @submit.prevent="add()">
    <section class="grid add">
      <section class="card new">
        <h2>Programme <sup class="textred">*</sup></h2>
        <section>
          <figure><img alt="" :src="image(program.data.thumbnail || '')" /></figure>
          <p>
            <label for="num">Programme (Obligatoire)</label>
            <select id="program" v-model="program.numero" @click="setProgram()">
              <option value="0">Aucun</option>
              <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Identifiants <sup class="textred">*</sup></h2>
        <section>
          <p>
            <label for="num">ID du Lot (auto)</label>
            <input type="text" size="4" id="num" placeholder="0000" v-model="lot.uid" disabled />
          </p>
        </section>
        <section>
          <p>
            <label for="num">Numéro du lot (semi-auto)</label>
            <label for="auto">
              <input type="checkbox" id="auto" class="toggle" v-model="auto">
              <small>Désactiver la numérotation automatique</small>
            </label>
            <small v-if="auto" class="info red">Avertissement: Le numéro doit impérativement être unique!<br/>
            Veillez à ne pas faire de doublon avec un autre lot.</small>
            <input v-if="!auto" type="text" size="4" id="num" placeholder="00" v-model="lot.numero" disabled />
            <input v-if="auto" type="text" size="4" @keyup="setProgram()" placeholder="00" id="num" v-model="lot.numero" />
          </p>
        </section>
        <section>
          <p>
            <label for="name">Lettre du bâtiment</label>
            <input type="text" maxlength="1" size="1" id="name" @keyup="filterBuild(),setProgram()" v-model="lot.spec.building" />
          </p>
        </section>
      </section>

      <section class="card new">
        <h2>Paramètres</h2>
        <section>
          <p>
            <label for="stat">Statut</label>
            <select id="stat" v-model="lot.status" disabled>
              <option v-for="(status, s) in settings.lot.status" :value="s" :key="s">{{ status }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="law">Dispositif de loi</label>
            <select id="law" v-model="lot.law">
              <option v-for="(law, l) in settings.lot.law" :value="l" :key="l">{{ law }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="law">Durée de défiscalisation</label>
            <select id="law" v-model="lot.lawyears">
              <option v-for="(years, y) in settings.lot.lawyears" :value="y" :key="y">{{ years }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="created">Date de création</label>
            <input type="text" id="created" disabled :value="lot.created | shortDate" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Lot</h2>
        <section>
          <p>
            <label for="type">Type</label>
            <select id="type" v-model="lot.spec.type">
              <option v-for="(lot, lt) in settings.lot.type" :value="lt" :key="lt">{{ lot }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="story">Superposition</label>
            <select id="story" v-model="lot.spec.story">
              <option v-for="(lot, st) in settings.lot.story" :value="st" :key="st">{{ lot }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="floor">Étage</label>
            <input type="number" id="floor" v-model="lot.spec.floor" min="0" />
          </p>
        </section>
        <section>
          <p>
            <label for="size">Surface (m²)</label>
            <input type="number" id="size" step="0.01" v-model="lot.spec.size" v-on:keyup="lot.spec.total = lot.spec.size" min="0" />
          </p>
        </section>
        <section>
          <p>
            <label for="size">Surface défiscalisable (m²)</label>
            <small></small>
            <input type="number" id="size" step="0.01" v-model="lot.spec.total" min="0" />
          </p>
        </section>
        <section>
          <p>
            <label for="exp">Exposition</label>
            <select id="exp" v-model="lot.spec.exposure">
              <option v-for="(expo, e) in settings.lot.exposure" :value="e" :key="e">{{ expo }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Finance</h2>
        <section>
          <p>
            <label for="price">Prix</label>
            <input type="number" step="0.01" id="price" v-model="lot.spec.price" />
          </p>
        </section>
        <section>
          <p>
            <label for="defisc">Coût défisc</label>
            <input type="number" step="0.01" id="defisc" v-model="lot.spec.defisc" />
          </p>
        </section>
        <section>
          <p>
            <label for="loan">Loyer</label>
            <input type="number" step="0.01" id="loan" v-model="lot.spec.loan" />
          </p>
        </section>
        <section>
          <p>
            <label for="fees">Frais de notaire</label>
            <input type="number" step="0.01" id="fees" v-model="lot.spec.notaryfees" />
          </p>
        </section>
        <section>
          <p>
            <label for="parkingprice">Prix du parking</label>
            <input type="number" step="0.01" id="parkingprice" v-model="lot.spec.parkingprice" />
          </p>
        </section>
        <section>
          <p>
            <label for="parkingloan">Loyer du parking</label>
            <input type="number" step="0.01" id="parkingloan" v-model="lot.spec.parkingloan" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Options</h2>
        <small>La valeur par défaut (0) signifie que l'option est indisponible</small>
        <section>
          <p>
            <label for="balcony">Balcon (m²)</label>
            <input type="number" id="balcony" step="0.01" v-model="lot.spec.balcony" />
          </p>
        </section>
        <section>
          <p>
            <label for="varangue">Varangue (m²)</label>
            <input type="number" id="varangue" step="0.01" v-model="lot.spec.varangue" />
          </p>
        </section>
        <section>
          <p>
            <label for="terrace">Terrasse (m²)</label>
            <input type="number" id="terrace" step="0.01" v-model="lot.spec.terrace" />
          </p>
        </section>
        <section>
          <p>
            <label for="logia">Logia (m²)</label>
            <input type="number" id="logia" step="0.01" v-model="lot.spec.logia" />
          </p>
        </section>
        <section>
          <p>
            <label for="garden">Jardin (m²)</label>
            <input type="number" id="garden" step="0.01" v-model="lot.spec.garden" />
          </p>
        </section>
        <section>
          <p>
            <label for="cellar">Numéro cave</label>
            <small>Virgule séparateur si multiples</small>
            <input type="text" id="cellar"  v-model="lot.spec.cellar" />
          </p>
        </section>
        <section>
          <p>
            <label for="parking">Numéro parking</label>
            <small>Virgule séparateur si multiples</small>
            <input type="text" id="parking" v-model="lot.spec.parking" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Téléchargement</h2>
        <section>
          <p>
            <label for="logo">Fichier zip</label>
            <small>.zip .rar &lt; 50 Mo</small>
            <input type="file" id="zip" ref="zip" v-on:change="handle('zip','download','rar')"/>
          </p>
        </section>
      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ lot }}</pre>
      </section>
    </section>
    <section v-if="validate()" class="card">
      <p>
        <button v-if="copy" type="submit" :disabled="processing">DUPLIQUER</button>
        <button v-else type="submit" :disabled="processing">AJOUTER</button>
      </p>
    </section>
    <InvalidForm v-else />
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import { image } from '@/helpers.js'
import settings from '@/settings.js'

export default {
  name: 'lotNew',
  components: {
    Profile,
    InvalidForm
  },
  data () {
    return {
      settings,
      processing: false,
      copy: false,
      auto: false,
      upload: [],
      program: {
        numero: 0,
        data: {}
      },
      lot: {
        id: '',
        uid: '',
        numero: 0,
        program: {
          id: '',
          numero: 0,
          shortname: ''
        },
        spec: {
          building: '',
          type: 1,
          story: 0,
          exposure: 8,
          floor: 0,
          size: 0,
          cellar: '',
          balcony: 0,
          varangue: 0,
          logia: 0,
          terrace: 0,
          garden: 0,
          total: 0,
          parking: '',
          parkingprice: 0,
          parkingloan: 0,
          price: 0,
          defisc: 0,
          loan: 0,
          notaryfees: 0
        },
        law: 0,
        lawyears: 0,
        status: 0,
        reservation: {
          numero: '',
          actedprice: 0,
          date: 0
        },
        created: Date.now(),
        download: '',
        stats: {
          click: 0
        }
      }
    }
  },
  computed: {
    numero: function () {
      return this.$store.getters.meta.menu.numero
    },
    programs: function () {
      return this.$store.getters.get_programs()
    },
    lotsByProgram: function () {
      return this.$store.getters.get_lots_by_program_num(this.program.numero)
    },
    lastNum: function () {
      return this.$store.getters.get_last_numero_by_program_num(this.program.numero) + 1
    }
  },
  created () {
    if (this.numero > 0) {
      // program selected in preference
      this.program.numero = this.numero
      this.setProgram()
    }
    // params :id detection
    if (this.$route.params.id) {
      // find route
      if (this.$route.name === 'lotNewByProgram') {
        // new from program
        this.program.numero = Number(this.$route.params.id)
        this.setProgram()
      } else {
        // duplicate lot
        this.copy = true
        var copy = this.$store.getters.get_lot_by_uid(this.$route.params.id)
        this.lot = JSON.parse(JSON.stringify(copy))
        this.program.numero = this.lot.program.numero
        // reset some var
        this.lot.download = ''
        this.lot.status = 0
        this.lot.reservation = {
          numero: '', date: 0
        }
        this.lot.created = Date.now()
        this.setProgram()
      }
    }
  },
  methods: {
    setProgram: function () {
      if (Number(this.program.numero)) {
        this.program.data = this.programs.find(p => p.numero === this.program.numero)
        if (!this.auto) this.lot.numero = this.lastNum
        var build = this.lot.spec.building.toUpperCase()
        if (build === 'E') build = 'EE'
        this.lot.uid = this.program.data.numero + build + (this.lot.numero < 10 ? '0' : '') + this.lot.numero
        this.lot.program.id = this.program.data.id
        this.lot.program.numero = this.program.data.numero
        this.lot.program.shortname = this.program.data.shortname
      } else {
        this.program = {
          numero: 0,
          data: {
            thumbnail: ''
          }
        }
      }
    },
    filterBuild: function () {
      var build = this.lot.spec.building.replace(/[^A-Za-z]/g, '')
      this.lot.spec.building = build.toUpperCase()
    },
    add: function () {
      this.processing = true
      const payload = {
        lot: this.lot,
        upload: this.upload
      }
      this.$store.dispatch('addLotWithUpload', payload)
        .then(() => {
          this.$router.push('/lots')
        })
        .catch()
    },
    handle: function (input, name, type) {
      var file = this.$refs[input].files[0]
      if (file) {
        var newupload = []
        newupload[0] = file
        newupload[1] = name
        newupload[2] = type
        newupload[3] = input
        this.upload = newupload
      }
    },
    validate: function () {
      if (!Number(this.program.numero)) return false
      if (!this.lot.numero) return false
      return true
    },
    image
  }
}
</script>
