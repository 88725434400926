import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  login ({ commit }, login) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: '',
          token: '',
          function: 'authenticate',
          params: { username: login.username, password: login.password }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // login success
            var mess = 'User logged in!'
            commit('status_success', mess)
            commit('login', resp.data.data)
            localStorage.setItem('auth', JSON.stringify(resp.data.data))
            resolve()
          } else {
            // error
            var err = 'Login failed!'
            commit('status_error', err)
          }
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('logout')
      localStorage.removeItem('auth')
      resolve()
    })
  },
  validToken ({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'verifyToken',
          params: ''
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            resolve()
          } else {
            commit('logout')
            var err = 'Token expired!'
            commit('status_error', err)
            localStorage.removeItem('auth')
            reject(new Error(err))
          }
        })
    })
  },
  privacy ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: settings.upload + 'privacy.html'
        // params: {},
        // data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.status === 200) {
            // success
            resolve(resp.data)
          } else {
            // error
            var err = 'No privacy file!'
            reject(new Error(err))
          }
        })
    })
  }
}

export default Actions
