<template>
  <section class="interface" v-if="show">
    <Profile/>
    <section class="title">
      <h1><i class="icon-lot"></i>SUPPRESSION LOT</h1>
    </section>
    <form @submit.prevent="del()">
    <section class="grid">
      <section class="card">
        <h2>Descriptions</h2>
        <section>
          <p>
            <label class="badge">Programme:</label> {{ lot.program.shortname }}
          </p>
          <p>
            <label class="badge">Programme numéro:</label> {{ lot.program.numero }}
          </p>
          <p>
            <label class="badge">Lot numéro:</label> {{ lot.numero }}
          </p>
          <p>
            <label class="badge">Type:</label> {{ lot.type | type }}
          </p>
          <p>
            <label class="badge">Status:</label> <span class="badge" :class="status(lot.status)">{{ lot.status | lot_status }}</span>
          </p>
        </section>
      </section>
      <section class="card">
        <p>
          <span class="badge red">Avertissement</span>
        </p>
        <p>
          <small>La suppression entraîne la destruction totale et définitive du lot</small>
        </p>
      </section>
      <section class="card">
        <p>
          <button type="submit">SUPPRIMER</button>
        </p>
      </section>
      <section class="card"><small>Data.json</small>
        <pre class="scroll">{{ lot }}</pre>
      </section>
    </section>
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'lotDel',
  components: {
    Profile
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    lot: function () {
      return this.$store.getters.get_lot_by_uid(this.$route.params.id)
    }
  },
  methods: {
    del: function () {
      var lot = this.lot
      this.show = false
      this.$store.dispatch('removeLot', lot)
        .then(() => {
          this.$router.push('/lots')
        })
        .catch()
    },
    status (value) {
      return 'st' + value
    }
  }
}
</script>
