<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-alert"></i>SUPPRESSION DEMANDE</h1>
    </section>
    <form @submit.prevent="del()">
    <section class="grid">
      <section class="card">
        <h2>Descriptions</h2>
        <section>
          <p>
            <label class="badge">Num:</label> {{ request.numero }}
          </p>
          <p>
            <label class="badge">Programme:</label> {{ request.program.shortname }}
          </p>
          <p>
            <label class="badge">Lot numéro:</label> {{ request.lot.numero }}
          </p>
          <p>
            <label class="badge">Utilisateur:</label> {{ request.user.firstname }} {{ request.user.lastname }}
          </p>
          <p>
            <label class="badge">Prospect:</label> {{ request.prospect.firstname }} {{ request.prospect.lastname }}
          </p>
          <p>
            <label class="badge">Status:</label> <span class="badge" :class="status(request.status)">{{ request.status | request_status }}</span>
          </p>
        </section>
      </section>
      <section class="card">
        <p>
          <span class="badge red">Avertissement</span>
        </p>
        <p>
          <small>La suppression entraîne la destruction totale et définitive de la demande.</small>
        </p>
      </section>
      <section class="card">
        <p>
          <button type="submit">SUPPRIMER</button>
        </p>
      </section>
      <section class="card"><small>Data.json</small>
        <pre class="scroll">{{ request }}</pre>
      </section>
    </section>
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'DemandeDel',
  components: {
    Profile
  },
  data () {
    return {
    }
  },
  computed: {
    request: function () {
      return this.$store.getters.get_request_by_numero(this.$route.params.id)
    }
  },
  methods: {
    del: function () {
      const request = this.request
      this.$store.dispatch('removeRequest', request)
        .then(() => {
          this.$router.push('/reservations')
        })
        .catch()
    },
    status: function (value) {
      return 'rq' + value
    }
  }
}
</script>
