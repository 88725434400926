<template>
  <section class="interface" v-if="show">
    <Profile/>
    <section class="title">
      <h1><i class="icon-programme"></i>SUPPRESSION PROGRAMME</h1>
    </section>
    <form @submit.prevent="del()">
    <section class="grid">
      <section class="card">
        <h2>Descriptions</h2>
        <section class="new">
          <figure>
            <img alt="" :src="image(program.thumbnail)" />
          </figure>
        </section>
        <section>
          <p>
            <label class="badge">Programme:</label> {{ program.name }}
          </p>
          <p>
            <label class="badge">Status:</label> {{ program.status | prog_status }}
          </p>
          <p>
            <label class="badge">Ville:</label> {{ program.location.city }} - {{ program.location.district }}
          </p>
        </section>
      </section>
      <section class="card">
        <p>
          <span class="badge red">Avertissement</span>
        </p>
        <p>
          <small>La suppression entraîne la destruction totale et définitive du programme</small>
        </p>
      </section>
      <section class="card">
        <p>
          <button type="submit">SUPPRIMER</button>
        </p>
      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ program }}</pre>
      </section>
    </section>
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import { image } from '@/helpers.js'

export default {
  name: 'ProgrammeDel',
  components: {
    Profile
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    program: function () {
      return this.$store.getters.get_program_by_numero(this.$route.params.id)
    }
  },
  methods: {
    del: function () {
      var prog = this.program
      this.show = false
      this.$store.dispatch('removeProgram', prog)
        .then(() => {
          this.$router.push('/programmes')
        })
        .catch()
    },
    image
  }
}
</script>
